import VoucherDashboardComponentWrapper from '../../voucher-layouts/VoucherDashboardComponentWrapper'
import VoucherListComponent from '../../voucher-components/VoucherListComponent'
import FilterVouchersBtn from '../../voucher-components/FilterVouchersBtn'
import SearchVoucherInput from '../../voucher-components/SearchVoucherInput'
import VoucherTopComponents from '../../voucher-layouts/VoucherTopComponents'
import { useState } from 'react'
import FilterVouchersModal from '../../voucher-components/modals/FilterVouchersModal'
import { useAppSelector } from '../../../../app/hooks'
import { getAllVouchers, getFlteredVoucherByPeriod } from '../../../../actions/vouchersActions'
import { IVoucherList } from '../../voucherSlice'
import { useNavigate } from 'react-router-dom'
import useVerificationDone from '../../../../hooks/useVerificationDone'

export default function IndividualAllVouchersPage() {
  const [showFilterModal, setFilterModal] = useState(false)
  const { vouchers } = useAppSelector((state) => state.voucher)
  const navigate = useNavigate()
  const { kycActionWrapper } = useVerificationDone()

  const toggleModal = (open: boolean) => {
    kycActionWrapper(() => setFilterModal(open))
  }

  const handleFilter = (start, end) => {
    const reqBody = {
      start_date: start,
      end_date: end
    }
    getFlteredVoucherByPeriod(reqBody)
  }

  const resetFilterAction = () => {
    getAllVouchers({
      status: "approved",
      page: 1,
      isSilent: true,
    })
  }

  return (
    <VoucherDashboardComponentWrapper
      title="AzatMe Vouchers"
      subTitle="Here is an overview of all your vouchers"
    >
      <>
        <VoucherTopComponents
          leftComponents={[<SearchVoucherInput />]}
          rightComponents={
            [
              <FilterVouchersBtn onClick={() => toggleModal(true)} />,
            ]}
          filled={true}
        />
        <div className="w-[95%] flex flex-col gap-[20px] mt-">
          <VoucherListComponent
            vouchers={vouchers?.data}
            getAction={getAllVouchers}
            getActionPayload={{ status: "approved" }}
            pagination={{ ...vouchers, data: null } as IVoucherList}
            notFoundText1={"No voucher nt found!"}
            voucherClickAction={(voucherId) => { navigate(`/voucher/all-vouchers/${voucherId}`) }}
            voucherIdType="code"
          />
        </div>
        <FilterVouchersModal openModal={showFilterModal} handleClose={() => toggleModal(false)} resetAction={resetFilterAction} handleFilter={handleFilter} />
      </>
    </VoucherDashboardComponentWrapper>
  )
}
