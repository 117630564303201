import axios from "axios";
import getBaseURL from "../utils/getBaseURL";
import { silentHTTPCaller, toastWrapper } from "../utils/toastWrapper";
import { appDispatch } from "../app/store";
import { addSingleVouchers, addVouchers, getSponsorAccounts, getWalletBalance, setMerchantBusinesses, setVoucherMerchants } from "../pages/Voucher/voucherSlice";
import { paramBuilder } from "../utils/common";

const url = getBaseURL() as string;

export const createVoucher = (body, handleDone) => {
  const call = axios.post(`${url}vouchers`, body)

  toastWrapper(call, "Creating your Voucher", (resp) => {
    handleDone && handleDone(resp.data);
    return resp.data.message || 'Voucher created successfully!';
  },
    'Voucher Creation Failed!',
    (err) => {
      // @ts-ignore
      const msg = err?.response?.data?.message;
      return msg || 'Voucher Creation Failed!';
    })
}

export const updateVoucher = (voucherId, body, handleDone) => {
  const call = axios.post(`${url}update/${voucherId}/voucher`, body)

  toastWrapper(call, "updating your voucher", (resp) => {
    handleDone && handleDone(resp.data);
    return resp.data.message || 'Voucher updated successfully!';
  },
    'Failed to update voucher!',
    (err) => {
      // @ts-ignore
      const msg = err?.response?.data?.message;
      return msg || 'Failed to update voucher!';
    }
  )
}

// https://api.azatme.com/api/all/vouchers?status=approved&voucher_status=unused&per_page=10
export const getAllVouchers = (
  { page = 1, isSilent = false, handleDone, status, voucher_status }:
    { page?: number, isSilent?: boolean, handleDone?: (resp) => void, status?: "approved" | "rejected" | "pending", voucher_status?: "used" | "unused" }
) => {
  const callUrl = `${url}all/vouchers`;
  const callUrlWithParams = paramBuilder(callUrl, {
    status,
    page,
    voucher_status,
    per_page: 12,
  })
  const call = axios.get(callUrlWithParams)

  if (isSilent) {
    silentHTTPCaller(
      call,
      'Getting Vouchers...',
      (resp) => {
        appDispatch(addVouchers(resp?.data?.data))
        handleDone && handleDone(resp.data);
        return resp.data.message || 'Vouchers gotten successfully!';
      },
      'Error getting Vouchers.'
    );
  } else {
    toastWrapper(
      call,
      'Getting Vouchers...',
      (resp) => {
        appDispatch(addVouchers(resp?.data?.data))
        handleDone && handleDone(resp.data);
        return resp.data.message || 'Vouchers gotten successfully!';
      },
      'Error getting Vouchers.'
    );
  }
}

// https://api.azatme.com/api/filter/vouchers
export const getFlteredVoucherByPeriod = (reqBody, handleDone?) => {
  const call = axios.post(`${url}filter/vouchers`, reqBody);

  toastWrapper(
    call,
    "Filtering vouchers",
    (resp) => {
      appDispatch(addVouchers(resp?.data))
      handleDone && handleDone(resp.data);
      return resp.data.message || 'Filtering vouchers successfully';
    },
    "Error filtering vouchers",
    (err) => {
      // @ts-ignore
      const msg = err?.response?.data?.message;
      return msg || 'Error filtering vouchers!';
    }
  )
}

export const getAllVouchersBySponsor = ({ page = 1, isSilent = false, handleDone }: { page?: number, isSilent?: boolean, handleDone?: (resp) => void }) => {
  const call = axios.get(`${url}vouchers/by-sponsor?page=${page}&per_page=12`)
  if (isSilent) {
    silentHTTPCaller(
      call,
      'Getting your vouchers...',
      (resp) => {
        appDispatch(addVouchers(resp?.data?.data?.response))
        handleDone && handleDone(resp.data?.data?.response);
        return resp.data.message || 'Vouchers gotten successfully!';
      },
      'Error getting your vouchers.'
    );
  } else {
    toastWrapper(
      call,
      'Getting your vouchers...',
      (resp) => {
        appDispatch(addVouchers(resp?.data?.data?.response))
        handleDone && handleDone(resp.data?.data?.response);
        return resp.data.message || 'Vouchers gotten successfully!';
      },
      'Error getting your vouchers.'
    );
  }
}

export const getDateFilterVouchersBySponsor = ({ startDate, endDate, page = 1, isSilent = false, handleDone }: { startDate: string, endDate: string, page?: number, isSilent?: boolean, handleDone?: (resp) => void }) => {
  const call = axios.get(`${url}voucher-search/date-range?start_date=${startDate}&end_date=${endDate}&page=${page}&per_page=12`)
  if (isSilent) {
    silentHTTPCaller(
      call,
      'Applying filter to your vouchers...',
      (resp) => {
        appDispatch(addVouchers(resp?.data?.data?.response))
        handleDone && handleDone(resp.data?.data?.response);
        return resp.data.message || 'Filter applied!';
      },
      'Error getting your vouchers.'
    );
  } else {
    toastWrapper(
      call,
      'Applying filter to your vouchers...',
      (resp) => {
        appDispatch(addVouchers(resp?.data?.data?.response))
        handleDone && handleDone(resp.data?.data?.response);
        return resp.data.message || 'Filter applied!';
      },
      'Error getting your vouchers.'
    );
  }
}

export const getSingleVoucherBySponsor = ({ voucherId, isSilent = false, handleDone }: { voucherId: string, isSilent?: boolean, handleDone?: (resp) => void }) => {
  const call = axios.get(`${url}vouchers/by-sponsor?voucher_id=${voucherId}`)
  if (isSilent) {
    silentHTTPCaller(
      call,
      'Getting Voucher details...',
      (resp) => {
        appDispatch(addSingleVouchers(resp?.data?.data?.response))
        handleDone && handleDone(resp.data);
        return resp.data.message || 'Voucher details gotten successfully!';
      },
      'Error getting Voucher details.'
    );
  } else {
    toastWrapper(
      call,
      'Getting Voucher details...',
      (resp) => {
        appDispatch(addSingleVouchers(resp?.data?.data?.response))
        handleDone && handleDone(resp.data);
        return resp.data.message || 'Voucher details gotten successfully!';
      },
      'Error getting Voucher details.'
    );
  }
}

export const getVoucherBeneficiaries = ({ page = 1, isSilent = false, handleDone }: { page?: number, isSilent?: boolean, handleDone?: (resp) => void }) => {
  const call = axios.get(`${url}beneficiaries/redeemed?page=${page}`)

  if (isSilent) {
    silentHTTPCaller(
      call,
      'Getting Beneficiaries...',
      (resp) => {
        // appDispatch(addVouchers(resp?.data?.data))
        handleDone && handleDone(resp.data);
        return resp.data.message || 'Beneficiaries gotten successfully!';
      },
      'Error getting beneficiaries.'
    );
  } else {
    toastWrapper(
      call,
      'Getting beneficiaries...',
      (resp) => {
        // appDispatch(addVouchers(resp?.data?.data))
        handleDone && handleDone(resp.data);
        return resp.data.message || 'Beneficiaries gotten successfully!';
      },
      'Error getting beneficiaries.'
    );
  }
}

// not used
export const getUsedVouchersByUser = ({ page = 1, isSilent = false, handleDone }: { page?: number, isSilent?: boolean, handleDone?: (resp) => void }) => {
  const call = axios.get(`${url}vouchers/used?page=${page}&per_page=12`)
  if (isSilent) {
    silentHTTPCaller(
      call,
      'Getting Vouchers...',
      (resp) => {
        appDispatch(addVouchers(resp?.data?.data))
        handleDone && handleDone(resp.data);
        return resp.data.message || 'Used Vouchers gotten successfully!';
      },
      'Error getting Used Vouchers.'
    );
  } else {
    toastWrapper(
      call,
      'Getting Used Vouchers...',
      (resp) => {
        appDispatch(addVouchers(resp?.data?.data))
        handleDone && handleDone(resp.data);
        return resp.data.message || 'Used Vouchers gotten successfully!';
      },
      'Error getting Used Vouchers.'
    );
  }
}

export const SponsorFundWallet = (body, handleDone) => {
  const call = axios.post(`${url}sponsor/fund/wallet`, body)

  toastWrapper(
    call,
    "Loading...",
    (resp) => {
      handleDone && handleDone(resp.data);
      return resp.data.message || 'Proceed with wallet funding';
    },
    "Error!! Smoething happened please try again.",
    (err) => {
      // @ts-ignore
      const msg = err?.response?.data?.message;
      return msg || 'Error!! Smoething happened please try again.!';
    }
  )
}

export const getSponsorWaletBalance = ({ isSilent = true, handleDone }: { isSilent?: boolean, handleDone?: (resp: any) => void }) => {
  const call = axios.get(`${url}sponsor/wallet/balance`)

  if (isSilent) {
    silentHTTPCaller(
      call,
      'Getting wallet balance...',
      (resp) => {
        appDispatch(getWalletBalance(resp?.data?.data?.wallet_balance))
        handleDone && handleDone(resp.data.data);
        return resp.data.message || 'Wallet balance gotten successfully!';
      },
      'Error getting wallet balance.'
    );
  } else {
    toastWrapper(
      call,
      'Getting wallet balance...',
      (resp) => {
        appDispatch(getWalletBalance(resp?.data?.data?.wallet_balance))
        handleDone && handleDone(resp.data.data);
        return resp.data.message || 'Wallet balance gotten successfully!';
      },
      'Error getting wallet balance.'
    );
  }
}

export const createSponsorAccount = (body, handleDone) => {
  const call = axios.post(`${url}create/sponsors`, body)

  toastWrapper(
    call,
    "Adding Account...",
    (resp) => {
      handleDone && handleDone(resp)
      return resp.data.message || 'Profie accounts gotten successfully!';
    },
    "Error adding account",
    (err) => {
      // @ts-ignore
      const msg = err?.response?.data?.message;
      return msg || 'Error adding account!';
    }
  )
}

export const updateSponsorAccount = (companyId, body, handleDone) => {
  const call = axios.put(`${url}sponsors/${companyId}`, body)

  toastWrapper(
    call,
    "Updating Account...",
    (resp) => {
      handleDone && handleDone(resp)
      return resp.data.message || 'Account updated successfully!';
    },
    "Error updating account"
  )
}

export const getSponsorsUnderOwner = (handleDone?) => {
  const call = axios.get(`${url}sponsors`)

  silentHTTPCaller(
    call,
    "Fetching sponsor profile accounts",
    (resp) => {
      appDispatch(getSponsorAccounts(resp?.data?.data))
      handleDone && handleDone(resp)
      return resp.data.message || 'Profie accounts gotten successfully!';
    },
    "Error fetching sponsor profile accounts"
  )
}

export const getSponsorAccountById = (sponsorId, handleDone) => {
  const call = axios.get(`${url}sponsors/${sponsorId}`);

  toastWrapper(
    call,
    "Fetching sponsor profile details",
    (resp) => {
      handleDone && handleDone(resp)
      return resp.data.message || 'Wallet balance gotten successfully!';
    },
    "Error fetching sponsor profile details"
  )
}

export const getAllMerchantBusinesses = ({ isSilent = true, handleDone }: { isSilent?: boolean, handleDone?: (resp: any) => void }) => {
  const call = axios.get(`${url}get/all/business/merchants`)

  if (isSilent) {
    silentHTTPCaller(
      call,
      'Getting merchants...',
      (resp) => {
        appDispatch(setMerchantBusinesses(resp?.data))
        handleDone && handleDone(resp?.data);
        return resp.data.message || 'Merchants gotten successfully!';
      },
      'Error getting Merchants.'
    );
  } else {
    toastWrapper(
      call,
      'Getting merchants...',
      (resp) => {
        appDispatch(setMerchantBusinesses(resp?.data))
        handleDone && handleDone(resp?.data);
        return resp.data.message || 'Merchants gotten successfully!';
      },
      'Error getting merchants.'
    );
  }
}

// https://api.azatme.com/api/businesses/state  
export const getMerchantBusinessesByState = ({ state, isSilent = true, handleDone }: { state: string, isSilent?: boolean, handleDone?: (resp: any) => void }) => {
  const body = {
    state
  }
  const call = axios.post(`${url}businesses/state`, body)

  if (isSilent) {
    silentHTTPCaller(
      call,
      'Getting merchants in the state...',
      (resp) => {
        appDispatch(setMerchantBusinesses(resp?.data?.businesses))
        handleDone && handleDone(resp?.data);
        return resp.data.message || 'Merchants gotten successfully!';
      },
      'Error getting merchants.'
    );
  } else {
    toastWrapper(
      call,
      'Getting merchants in the state...',
      (resp) => {
        appDispatch(setMerchantBusinesses(resp?.data?.businesses))
        handleDone && handleDone(resp?.data);
        return resp.data.message || 'Merchants gotten successfully!';
      },
      'Error getting merchants.'
    );
  }
}

export const getRedeemableVouchersForMerchant = (
  { merchantId, page = 1, isSilent = false, handleDone }: { merchantId: string, page?: number, isSilent?: boolean, handleDone?: (resp) => void }
) => {
  const call = axios.get(`${url}business/vouchers/${merchantId}`);

  if (isSilent) {
    silentHTTPCaller(
      call,
      'Getting your vouchers',
      (resp) => {
        appDispatch(addVouchers(resp?.data))
        handleDone && handleDone(resp.data);
        return resp.data.message || 'Vouchers gotten successfully';
      },
      'Error getting your vouchers.'
    );
  } else {
    toastWrapper(
      call,
      'Getting your vouchers',
      (resp) => {
        appDispatch(addVouchers(resp?.data))
        handleDone && handleDone(resp.data);
        return resp.data.message || 'Vouchers gotten successfully';
      },
      'Error getting your vouchers.'
    );
  }
}

// http://127.0.0.1:8000/api/vouchers/redeem
// https://api.azatme.com/api/vouchers/code/{voucher_code}/{business_id}
// ttps://api.azatme.com/api/vouchers/{voucherCode}

export const redeemVoucher = (body, handleDone) => {
  const call = axios.post(`${url}vouchers/redeem`, body)
  toastWrapper(
    call,
    "Redeming Voucher...",
    (resp) => {
      handleDone && handleDone(resp)
    },
    "Error redeeming voucher!",
    (err) => {
      // @ts-ignore
      const msg = err?.response?.data?.message;
      return msg || 'Error adding account!';
    }
  );
}

export const getMerchantVoucherDetaildByCode = ({ merchantId, voucherCode, isSilent = false, handleDone }: { merchantId: string, voucherCode: string, page?: number, isSilent?: boolean, handleDone?: (resp) => void }) => {
  const call = axios.get(`${url}vouchers/code/${voucherCode}/${merchantId}`);

  if (isSilent) {
    silentHTTPCaller(
      call,
      'Getting voucher details',
      (resp) => {
        handleDone && handleDone(resp.data);
        return resp.data.message || 'Voucher details gotten successfully';
      },
      'Error getting voucher details.'
    );
  } else {
    toastWrapper(
      call,
      'Getting voucher details',
      (resp) => {
        handleDone && handleDone(resp.data);
        return resp.data.message || 'Voucher details gotten successfully';
      },
      'Error getting voucher details.'
    );
  }
}
export const getVoucherDetaildByCode = ({ voucherCode, isSilent = false, handleDone }: { voucherCode: string, page?: number, isSilent?: boolean, handleDone?: (resp) => void }) => {
  const call = axios.get(`${url}vouchers/${voucherCode}`);

  if (isSilent) {
    silentHTTPCaller(
      call,
      'Getting voucher details',
      (resp) => {
        appDispatch(addSingleVouchers(resp?.data?.data))
        handleDone && handleDone(resp.data);
        return resp.data.message || 'Voucher details gotten successfully';
      },
      'Error getting voucher details.'
    );
  } else {
    toastWrapper(
      call,
      'Getting voucher details',
      (resp) => {
        appDispatch(addSingleVouchers(resp?.data?.data))
        handleDone && handleDone(resp.data);
        return resp.data.message || 'Voucher details gotten successfully';
      },
      'Error getting voucher details.'
    );
  }
}

// https://api.azatme.com/api/vouchers/used/summary
export const getVoucherSummary = (handleDone) => {
  const call = axios.get(`${url}vouchers/used/summary`);

  silentHTTPCaller(
    call,
    "",
    (resp) => {
      handleDone && handleDone(resp?.data);
      return resp.data.message || 'Vouchers summary gotten successfully';
    },
    "Error getting vouchers summary."
  )
}


// https://api.azatme.com/api/voucher-with-businesses
export const getMerchantsForAVoucher = ({ isSilent, voucher_code, handleDone }:
  { isSilent: boolean, voucher_code: string, handleDone?: (resp: any) => void }) => {
  const call = axios.get(`${url}voucher-with-businesses?voucher_code=${voucher_code}`)

  if (isSilent) {
    silentHTTPCaller(
      call,
      'Getting voucher merchants',
      (resp) => {
        appDispatch(setVoucherMerchants(resp?.data?.businesses))
        handleDone && handleDone(resp.data);
        return resp.data.message || 'Voucher merchants gotten successfully';
      },
      'Error getting voucher merchants.'
    );
  } else {
    toastWrapper(
      call,
      'Getting voucher merchants',
      (resp) => {
        appDispatch(setVoucherMerchants(resp?.data?.businesses))
        handleDone && handleDone(resp.data);
        return resp.data.message || 'Voucher merchants gotten successfully';
      },
      'Error getting voucher merchants.'
    );
  }
}

export const updateVoucherStatus = (voucherId, body, handleDone) => {
  const call = axios.post(`${url}vouchers/${voucherId}/status`, body)

  toastWrapper(
    call,
    "Updating voucher status...",
    (resp) => {
      handleDone && handleDone(resp)
      return resp.data.message || 'Voucher status updated successfully!';
    },
    "Error updating voucher status!",
    (err) => {
      // @ts-ignore
      const msg = err?.response?.data?.message;
      return msg || 'Error updating voucher status!';
    }
  );
}

export const claimVoucher = (voucher_code, handleDone) => {
  const reqBody = {
    voucher_code
  }
  const call = axios.post(`${url}vouchers/claim`, reqBody)

  silentHTTPCaller(call, "Claiming voucher...",
    (resp) => {
      handleDone && handleDone(resp)
      return resp.data.message || 'Voucher claimed successfully!';
    },
    "Error claiming voucher!",
    (err) => {
      // @ts-ignore
      const msg = err?.response?.data?.message;
      return msg || 'Error claiming voucher!';
    })
}