import { useState } from "react"
import Table from "../../../../components/Table"
import { formatCurrency } from "../../../../utils/common"
import ExportBtn from "../../voucher-components/ExportBtn"
import FilterVouchersBtn from "../../voucher-components/FilterVouchersBtn"
import SearchVoucherInput from "../../voucher-components/SearchVoucherInput"
import VoucherStatusTag from "../../voucher-components/VoucherStatusTag"
import VoucherDashboardComponentWrapper from "../../voucher-layouts/VoucherDashboardComponentWrapper"
import VoucherTopComponents from "../../voucher-layouts/VoucherTopComponents"
import FilterTransactionsModal from "../../voucher-components/modals/FilterTransactionsModal"
import SidePane from "../../../../components/SidePane"
import CopyData from "../../../../components/CopyData"
import Button from "../../../../components/Button"

function IndividualVoucherTransactions() {
  const [showFilterModal, setFilterModal] = useState(false)
  const [transactionDetails, setTransactionDetails] = useState<Record<string, any> | null>(null)

  const toggleModal = (open: boolean) => {
    setFilterModal(open)
  }

  const data = [
    { transactionDate: '2022-01-01', category: 'Payment', amount: 100, beneficiary: 'John Doe', type: 'Credit', status: 'pending' },
    { transactionDate: '2022-01-02', category: 'Deposit', amount: 500, beneficiary: 'Jane Doe', type: 'Credit', status: 'successfull' },
    // Add more transaction data here...
  ]
  const header = [
    { field: 'transactionDate', headerName: 'TRANSACTION DATE' },
    { field: 'category', headerName: 'CATEGORY' },
    { field: 'amount', headerName: 'AMOUNT', cellRenderer: ({ data }: any) => (formatCurrency({ num: data?.amount || 0 })) },
    { field: 'beneficiary', headerName: 'BENEFICARY' },
    {
      field: 'type', headerName: 'TYPE',
      cellRenderer: ({ data }: any) => (
        <span className="h-full w-full flex items-center justify-start ">
          <VoucherStatusTag status={data.type.toLowerCase() === "credit" ? "active" : "revoked"} type="filled" withDot={false} statusText={data.type} />
        </span>
      )
    },
    {
      field: 'status', headerName: 'STATUS',
      cellRenderer: ({ data }: any) => (
        <span className="h-full w-full flex items-center justify-start ">
          <VoucherStatusTag status={data.status.toLowerCase() === "successfull" ? "active" : data.status.toLowerCase() === "failed" ? "revoked" : data.status.toLowerCase() === "pending" ? "pending" : data.status} type="filled" statusText={data.status} />
        </span>
      )
    },
  ]
  return (
    <VoucherDashboardComponentWrapper
      title="Transactions"
    // subTitle="Here are an overview of all your vouchers"
    >
      <>
        <VoucherTopComponents
          filled={false}
          leftComponents={[<SearchVoucherInput input={{ placeholder: "Search by transaction reference" }} />]}
          rightComponents={
            [
              <FilterVouchersBtn onClick={() => toggleModal(true)} />,
              <ExportBtn className="!hidden md:!flex" />
            ]
          }
        />
        <div className="w-full h-[70vh]">
          <Table
            data={data}
            header={header}
            fitStrategy="fitCellContents"
            rowClickHandler={(rowData) => {
              setTransactionDetails(rowData?.data)
              // fetch transaction details here and update state accordingly
            }}
          />
        </div>
        <SidePane title={""} open={!!transactionDetails} onClose={() => { setTransactionDetails(null) }}>
          <div className="w-full flex !flex-col">
            <p className="text-2xl border-b-2 border-gray-300 pb-6 mb-3">Transaction Details</p>
            <div className="w-full h-[70px] border-b border-[#2222220D] flex justify-between items-center">
              <p className="text-sm text-az-black-900">Status</p>
              <VoucherStatusTag status={transactionDetails?.status.toLowerCase() === "successfull" ? "active" : transactionDetails?.status.toLowerCase() === "failed" ? "revoked" : transactionDetails?.status.toLowerCase() === "pending" ? "pending" : transactionDetails?.status} type="filled" statusText={transactionDetails?.status} withDot={false} />
            </div>
            <div className="w-full h-[70px] border-b border-[#2222220D] flex justify-between items-center">
              <p className="text-sm text-az-black-900">Date & Time</p>
              <p className="text-sm text-az-black-900">{transactionDetails?.transactionDate}</p>
            </div>
            <div className="w-full h-[70px] border-b border-[#2222220D] flex justify-between items-center">
              <p className="text-sm text-az-black-900">Beneficiary</p>
              <p className="text-sm text-az-black-900">{transactionDetails?.beneficiary}</p>
            </div>
            <div className="w-full h-[70px] border-b border-[#2222220D] flex justify-between items-center">
              <p className="text-sm text-az-black-900">Category</p>
              <p className="text-sm text-az-black-900">{transactionDetails?.category}</p>
            </div>
            <div className="w-full h-[70px] border-b border-[#2222220D] flex justify-between items-center">
              <p className="text-sm text-az-black-900">Amount</p>
              <p className="text-sm text-az-black-900">{formatCurrency({ num: transactionDetails?.amount })}</p>
            </div>
            <div className="w-full h-[70px] border-b border-[#2222220D] flex justify-between items-center">
              <p className="text-sm text-az-black-900">Type</p>
              <VoucherStatusTag status={transactionDetails?.type.toLowerCase() === "credit" ? "active" : "revoked"} type="filled" withDot={false} statusText={transactionDetails?.type} />
            </div>
            <div className="w-full h-[70px] border-b border-[#2222220D] flex justify-between gap-3 items-center">
              <p className="text-sm text-az-black-900 whitespace-nowrap">Transaction Ref.</p>
              <p className="text-sm text-az-black-900 overflow-auto"> AHUSO23MSMKHEKN3I493HWwjGBY120  <CopyData data="AHUSO23MSMKHEKN3I493HWwjGBY120" /></p>
            </div>

            <Button label="Download receipt" type="flat" className="w-full !rounded-[25px] !py-[20px]" />
          </div>
        </SidePane>
        <FilterTransactionsModal openModal={showFilterModal} handleClose={toggleModal} />
      </>
    </VoucherDashboardComponentWrapper>
  )
}

export default IndividualVoucherTransactions