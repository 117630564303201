
import { useState } from 'react'
import copyIcon from "../../assets/new/copy-icon.png"
import checkMark from "../../assets/new/checkmark.svg"

function CopyData({ data }: { data: string }) {
  const [copied, setCopied] = useState(false)

  const handleCopy = () => {
    navigator.clipboard.writeText(data)
    setCopied(true)
    setTimeout(() => {
      setCopied(false)
    }, 3000)
  }
  return (
    <span className='w-fit'>
      {
        copied ? (
          <img alt="copy" src={checkMark} className='w-[15px] h-[15px] object-cover' />)
          :
          (<img alt="copy" src={copyIcon} className='w-[15px] h-[15px] cursor-pointer object-cover' onClick={() => handleCopy()} />)
      }
    </span>
  )
}

export default CopyData