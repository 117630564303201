import axios from "axios";
import { silentHTTPCaller, toastWrapper } from "../utils/toastWrapper";
import { appDispatch } from '../app/store';
import getBaseURL from "../utils/getBaseURL";
import { addIssues, addInquiry, addExpenses, addExpensesByUser, addUserGroups, addUserGroupsByEmail, addAllBusinesses, addAllBusinessesByEmail, addAllAjosByEmail, addUsers, addReferrals, addReferral, addAllBusinessTransactions, updateExpenseWithdrawalsById, getAdminWithdrawalHistory, addMerchants, addSponsors, addUsersWithKYC } from "../pages/AdminDashboard/adminSlice";

const url = getBaseURL() as string;

export const getAjoCount = (handleDone) => {
  const call = axios.get(`${url}countAllAjo`,)
  silentHTTPCaller(call, 'Fetching your ajo count...', (resp) => {
    handleDone(resp);
    return resp.data.message || 'Ajo count gotten successfully!';
  }, 'Error getting ajo count!');
}

export const getBusinessCount = (handleDone) => {
  const call = axios.get(`${url}countAllBusiness`,)
  silentHTTPCaller(call, 'Fetching your Business count...', (resp) => {
    handleDone(resp);
    return resp.data.message || 'Business count gotten successfully!';
  }, 'Error getting Business count!');
}

export const getRefundMeCount = (handleDone) => {
  const call = axios.get(`${url}countAllExpenses`,)
  silentHTTPCaller(call, 'Fetching your RefundMe count...', (resp) => {
    handleDone(resp);
    return resp.data.message || 'RefundMe count gotten successfully!';
  }, 'Error getting RefundMe count!');
}

export const getKontributeCount = (handleDone) => {
  const call = axios.get(`${url}countAllKontributes`,)
  silentHTTPCaller(call, 'Fetching your Kontribute count...', (resp) => {
    handleDone(resp);
    return resp.data.message || 'Kontribute count gotten successfully!';
  }, 'Error getting Kontribute count!');
}

export const getAllRefundMe = (page = 1) => {
  const call = axios.get(`${url}allExpenses?page=${page}`,)
  silentHTTPCaller(call, 'Fetching all RefundMe...', (resp) => {
    appDispatch(addExpenses(resp.data))
    return resp.data.message || 'Refundme gotten successfully!';
  }, 'Error getting Refundme!');
}

export const getAllKontribute = (page = 1) => {
  const call = axios.get(`${url}allKontributes?page=${page}`,)
  silentHTTPCaller(call, 'Fetching your Kontribute count...', (resp) => {
    appDispatch(addUserGroups(resp.data));
    return resp.data.message || 'Kontribute count gotten successfully!';
  }, 'Error getting Kontribute count!');
}

export const getAllBusiness = (page, hadleDone?) => {
  const call = axios.get(`${url}getAllBusiness${page ? `?page=${page}` : ""}`)
  silentHTTPCaller(call, 'Fetching your business...', (resp) => {
    hadleDone && hadleDone(resp.data)
    appDispatch(addAllBusinesses(resp.data));
    return resp.data.message || 'Businesses gotten successfully!';
  }, 'Error getting Businesses!');
}

export const getAllRefundMeByEmail = (page = 1, email, setState?) => {
  const call = axios.get(`${url}getAllExpensesByUserEmail/${email}?page=${page}`,)
  silentHTTPCaller(call, 'Fetching RefundMe...', (resp) => {
    appDispatch(addExpensesByUser(resp.data));
    setState && setState(prev => ({ ...prev, refundMes: resp.data }));
    return resp.data.message || 'RefundMe gotten successfully!';
  }, 'Error getting RefundMe!', (err) => {
    setState(null);
    // @ts-ignore
    const msg = err?.response?.data?.message;
    return msg || 'Error getting RefundMe!';
  });
}

export const getAllKontributeByEmail = (page = 1, email, setState?) => {
  const call = axios.get(`${url}getAllKontributeByUserEmail/${email}?page=${page}`,)
  silentHTTPCaller(call, 'Fetching your Kontribute...', (resp) => {
    appDispatch(addUserGroupsByEmail(resp.data));
    setState && setState(prev => ({ ...prev, kontributes: resp.data }));
    return resp.data.message || 'Kontribute gotten successfully!';
  }, 'Error getting RefundMe!', (err) => {
    setState(null);
    // @ts-ignore
    const msg = err?.response?.data?.message;
    return msg || 'Error getting RefundMe!';
  });
}

export const getAllBusinessByEmail = (page = 1, email) => {
  const call = axios.get(`${url}getAllBusinessByUserEmail/${email}?page=${page}`,)
  silentHTTPCaller(call, 'Fetching your business...', (resp) => {
    appDispatch(addAllBusinessesByEmail(resp.data));
    return resp.data.message || 'business gotten successfully!';
  }, 'Error getting Business!');
}

export const getAllAjoByEmail = (page = 1, email, setState?) => {
  const call = axios.get(`${url}getAllAjoByUserEmail/${email}?page=${page}`,)
  silentHTTPCaller(call, 'Fetching ajo...', (resp) => {
    appDispatch(addAllAjosByEmail(resp.data));
    setState && setState(prev => ({ ...prev, ajos: resp.data }));
    return resp.data.message || 'Ajo gotten successfully!';
  }, 'Error getting Ajo!', (err) => {
    setState(null);
    // @ts-ignore
    const msg = err?.response?.data?.message;
    return msg || 'Error getting Ajo!';
  });
}

export const getAllComplains = (page = 1) => {
  const call = axios.get(`${url}getAllComplains?page=${page}`,)
  silentHTTPCaller(call, 'Fetching feedback...', (resp) => {
    appDispatch(addIssues({ data: resp.data }));
    return resp.data.message || 'Feedback gotten successfully!';
  }, 'Error getting feedback!');
}

export const getAllInquiries = (page = 1) => {
  const call = axios.get(`${url}inquiry?page=${page}`,)
  silentHTTPCaller(call, 'Fetching inquiry...', (resp) => {
    appDispatch(addInquiry({ data: resp.data }));
    return resp.data.message || 'Inquiry gotten successfully!';
  }, 'Error getting inquiry!');
}

export const getAllUsers = (page = 1) => {
  const call = axios.get(`${url}all-users?page=${page}`,)
  silentHTTPCaller(call, 'Fetching users...', (resp) => {
    appDispatch(addUsers({ data: resp.data }));
    return resp.data.message || 'Users gotten successfully!';
  }, 'Error getting users!');
}

export const getAllReferralSettings = (page = 1) => {
  const call = axios.get(`${url}get-ref-settings/perAdmin?page=${page}`,)
  silentHTTPCaller(call, 'Fetching referrals...', (resp) => {
    appDispatch(addReferrals({ data: resp.data }));
    return resp.data.message || '';
  }, '');
}

export const createReferral = (data, handleDone) => {
  const call = axios.post(`${url}set-ref`, data)
  toastWrapper(call, 'Creating referral...', (resp) => {
    appDispatch(addReferral({ data: resp.data }));
    handleDone(resp);
    return resp.data.message || 'Successfully!';
  }, 'Failed!');
}

export const updateReferral = (id, data, handleDone) => {
  const call = axios.put(`${url}update-ref/${id}`, data);
  toastWrapper(call, 'Updating...', (resp) => {
    handleDone(resp);
    return resp.data.message || 'Successfully!';
  }, 'Failed!');
}

export const createCharges = (data, handleDone) => {
  const call = axios.post(`${url}create-charges`, data);
  toastWrapper(call, 'Creating charge...', (resp) => {
    handleDone(resp);
    return resp.data.message || 'Successfully!';
  }, 'Failed!');
}

export const updateCharges = (id, data, handleDone) => {
  const call = axios.put(`${url}charges/${id}`, data);
  toastWrapper(call, 'Updating...', (resp) => {
    handleDone(resp);
    return resp.data.message || 'Successfully!';
  }, 'Failed!');
}

export const getCharges = (handleDone) => {
  const call = axios.get(`${url}charges`);
  toastWrapper(call, 'Fetching charges...', (resp) => {
    handleDone(resp);
    return resp.data.message || 'Successfully!';
  }, 'Failed!');
}

export const deleteCharge = (id, handleDone) => {
  const call = axios.delete(`${url}charges/${id}`);
  toastWrapper(call, 'Deleting charges...', (resp) => {
    handleDone(resp);
    return resp.data.message || 'Successfully!';
  }, 'Failed!');
}

export const getAllBusinessTransactions = (page = 1) => {
  const call = axios.get(`${url}getAllBusiness?page=${page}`)
  silentHTTPCaller(call, 'Fetching Business Transactions...', (resp) => {
    appDispatch(addAllBusinessTransactions(resp.data));
    return resp.data.message || 'Businesses transactions gotten successfully!';
  }, 'Error getting Business transactions!');
}

export const getAllBusinessTransactionsByEmail = (page = 1, email) => {
  const call = axios.get(`${url}getAllBusinessByUserEmail/${email}?page=${page}`)
  silentHTTPCaller(call, 'Fetching Business Transactions...', (resp) => {
    appDispatch(addAllBusinessTransactions(resp.data));
    return resp.data.message || 'Businesses transactions gotten successfully!';
  }, 'Error getting Business transactions!');
}

export const registerNewAdmin = (data, handleDone) => {
  const call = axios.post(`${url}admin/register`, data)
  toastWrapper(call, "Registering new admin...", (resp) => {
    handleDone(resp);
    return resp.data.message || 'Successfully!';
  }, "Failed to register!")
}

export const resolveFeedbackAdmin = (id: string, handleDone) => {
  const call = axios.put(`${url}user/update/status/${id}`, { status: 'resolved' });
  toastWrapper(
    call,
    'Marking feedback as resolved...',
    (resp) => {
      handleDone(resp);
      return resp?.data?.message || 'Feedback resolved successfully';
    },
    '',
    (err) => {
      // @ts-ignore
      const msg = err?.response?.data?.message;
      return msg || 'Error updating feedback';
    }
  );
}

// withdrawal calls----------------------------------------------------------------
// expense
export const getAllExpenseWithdrawals = (page = 1) => {
  const call = axios.get(`${url}admin/expense-withdrawals?page=${page}`);
  silentHTTPCaller(call, 'Fetching all expense withdrawals...', (resp) => {
    // store in slice
    appDispatch(getAdminWithdrawalHistory({ data: resp?.data?.data, entity: "refundme" }))
    return resp.data.message || 'Expense withdrawals gotten successfully!';
  }, "Error getting eexpense withdrawals")
}
export const getExpenseWithdrawalsById = (id) => {
  const call = axios.get(`${url}admin/expense-withdrawals/${id}`);
  silentHTTPCaller(call, "fetching expense withdrawals...", (resp) => {
    // store in slice
    appDispatch(updateExpenseWithdrawalsById({ data: resp?.data?.data, entity: "refundme" }))
    return resp.data.message || 'Expense withdrawals gotten successfully!';
  }, "Error getting eexpense withdrawals")
}

// kontribute(group) withdrawals
export const getAllGroupWithdrawals = (page = 1) => {
  const call = axios.get(`${url}admin/group-withdrawals?page=${page}`);
  silentHTTPCaller(call, 'Fetching all kontribute withdrawals...', (resp) => {
    // store in slice
    appDispatch(getAdminWithdrawalHistory({ data: resp?.data?.data, entity: "kontribute" }))
    return resp.data.message || 'Kontribute withdrawals gotten successfully!';
  }, "Error getting kontribute withdrawals")
}
export const getGroupWithdrawalsById = (id,) => {
  const call = axios.get(`${url}admin/group-withdrawals/${id}`);
  silentHTTPCaller(call, "fetching kontribute withdrawals...", (resp) => {
    // store in slice
    appDispatch(updateExpenseWithdrawalsById({ data: resp?.data?.data, entity: "kontribute" }))
    return resp.data.message || 'kontribute withdrawals gotten successfully!';
  }, "Error getting kontribute withdrawals")
}

// Ajo widthdrawals
export const getAllAjoWithdrawals = (page = 1) => {
  const call = axios.get(`${url}admin/ajo-withdrawals?page=${page}`);
  silentHTTPCaller(call, 'Fetching all ajo withdrawals...', (resp) => {
    // store in slice
    appDispatch(getAdminWithdrawalHistory({ data: resp?.data?.data, entity: "ajo" }))
    return resp.data.message || 'Ajo withdrawals gotten successfully!';
  }, "Error getting ajo withdrawals")
}
export const getAjoWithdrawalsById = (id) => {
  const call = axios.get(`${url}admin/ajo-withdrawals/${id}`);
  silentHTTPCaller(call, "fetching ajo withdrawals...", (resp) => {
    // store in slice
    appDispatch(updateExpenseWithdrawalsById({ data: resp?.data?.data, entity: "ajo" }))
    return resp.data.message || 'ajo withdrawals gotten successfully!';
  }, "Error getting ajo withdrawals")
}

// Ajo widthdrawals
export const getAllBusinessWithdrawals = (page = 1) => {
  const call = axios.get(`${url}admin/business-withdrawals?page=${page}`);
  silentHTTPCaller(call, 'Fetching all business withdrawals...', (resp) => {
    // store in slice
    appDispatch(getAdminWithdrawalHistory({ data: resp?.data?.data, entity: "business" }))
    return resp.data.message || 'Business withdrawals gotten successfully!';
  }, "Error getting business withdrawals")
}
export const getBusinessWithdrawalsById = (id) => {
  const call = axios.get(`${url}admin/business-withdrawals/${id}`);
  silentHTTPCaller(call, "fetching business withdrawals...", (resp) => {
    // store in slice
    appDispatch(updateExpenseWithdrawalsById({ data: resp?.data?.data, entity: "business" }))
    return resp.data.message || 'business withdrawals gotten successfully!';
  }, "Error getting business withdrawals")
}

export const getAllMerchants = (page = 1, handleDone?) => {
  const call = axios.get(`${url}getAllMerchants?page=${page}`,)
  silentHTTPCaller(call, 'Fetching Merchants...', (resp) => {
    appDispatch(addMerchants({ data: resp.data.data }));
    handleDone(resp.data.data)
    return resp.data.message || 'Merchants gotten successfully!';
  }, 'Error getting merchants!');
}

export const getAllSponsors = (page = 1) => {
  const call = axios.get(`${url}getAllSponsors?page=${page}`,)
  silentHTTPCaller(call, 'Fetching Sponsors...', (resp) => {
    appDispatch(addSponsors({ data: resp.data.data }));
    return resp.data.message || 'Sponsors gotten successfully!';
  }, 'Error getting Sponsors!');
}

export const getAllUsersWithKYC = (page = 1) => {
  const call = axios.get(`${url}users/with/kyc?page=${page}`);
  silentHTTPCaller(
    call,
    '',
    (resp) => {
      appDispatch(addUsersWithKYC({ data: resp.data.data }));
      return resp.data.message || 'Sponsors gotten successfully!';
    },
    'Error getting KYC data.',
  );
}
export const getUserKYC = (userId, handleDone) => {
  const call = axios.get(`${url}users/kyc/${userId}`);
  silentHTTPCaller(
    call,
    '',
    (resp) => {
      handleDone(resp.data);
      return '';
    },
    'Error getting KYC data.',
    (err) => {
      // @ts-ignore
      const msg = err?.response?.data?.message;
      return msg || 'Error getting KYC data.';
    }
  );
}

export const UpdateKYCStatus = (userId, body, handleDone) => {
  const call = axios.put(`${url}kyc/update-status/${userId}`, body);
  toastWrapper(
    call,
    'Updating user\'s KYC status...',
    (resp) => {
      handleDone(resp);
      return resp?.data?.message || 'KYC status updated successfully!';
    },
    '',
    (err) => {
      // @ts-ignore
      const msg = err?.response?.data?.message;
      return msg || 'Error updating KYC status!';
    }
  );
}

// Voucher module actions

// set sponsor Limit
export const setSponsorLimits = (data, handleDone) => {
  const call = axios.post(`${url}set-limits`, data)
  toastWrapper(call, "Setting Sponsor Limits...", (resp) => {
    handleDone(resp);
    return resp.data.message || 'Successfully set!';
  }, "Failed to set!")
}

export const getAllSponsorPaymentWithWallet = (handleDone) => {
  const call = axios.get(`${url}payments-and-wallets`)
  toastWrapper(call, "Getting sponsor payments...", (resp) => {
    handleDone(resp);
    return resp.data.message || 'Successfully fetched sponsor payments!';
  }, "Failed to fetch sponsor payments!")
}

// get set limits
export const getSponsorLimits = (handleDone) => {
  const call = axios.get(`${url}sponsor-limits`);

  silentHTTPCaller(
    call,
    '',
    (resp) => {
      handleDone && handleDone(resp.data);
      return '';
    },
    'Error getting Sponsor limits.',
    (err) => {
      // @ts-ignore
      const msg = err?.response?.data?.message;
      return msg || 'Error getting sponsor limits.';
    }
  );
}