import { Link, useNavigate } from "react-router-dom";
import VoucherDashboardComponentWrapper from "../../voucher-layouts/VoucherDashboardComponentWrapper";
import VoucherListComponent from "../../voucher-components/VoucherListComponent";
import { getAllVouchers } from "../../../../actions/vouchersActions";
import { useAppSelector } from "../../../../app/hooks";

export default function VoucherIndividualDashboardPage() {
  const { vouchers } = useAppSelector((state) => state.voucher)
  const navigate = useNavigate()

  return (
    <VoucherDashboardComponentWrapper
      title="Welcome to AzatMe Voucher 🥳"
      subTitle="Here is an overview of your account"
    >
      <div className="w-[95%] ">
        <div className="w-[100%] flex justify-between items-center my-5">
          <h4 className="text-[15px] font-[500]">Recent Vouchers Created</h4>
          <Link to={"/voucher/all-vouchers"} className="underline text-[15px] text-[#0898A0]">View all</Link>
        </div>
        <VoucherListComponent
          vouchers={vouchers?.data}
          getAction={getAllVouchers}
          getActionPayload={{ status: "approved" }}
          // pagination={{ ...vouchers, data: null } as IVoucherList}
          notFoundText1={"No voucher found!"}
          voucherClickAction={(voucherId) => { navigate(`/voucher/all-vouchers/${voucherId}`) }}
          voucherIdType="code"
        />
      </div>
    </VoucherDashboardComponentWrapper>
  )
}