import { type Dispatch, type SetStateAction } from 'react'
import Modal from '.'
import checkMark from "../../assets/new/checkmark.svg"
import pendingImg from "../../assets/new/Hourglass.svg"
import failedImg from "../../assets/new/failed.png"
import Button from '../Button'

function GeneralStatusModal(
  { open, setOpen, status, title, description }:
    {
      open: boolean,
      setOpen: Dispatch<SetStateAction<boolean>>,
      status: "success" | "pending" | "failed",
      title?: string | null
      description?: string | null
    }) {

  const handleClose = () => {
    setOpen(false)
  }
  return (
    <Modal title={status} open={open} onClose={handleClose} overrideStyle={{ height: "auto", borderRadius: "20px" }} >
      <div className="w-full h-full flex flex-col items-center justify-center gap-7 mt-5">
        <img src={status === "success" ? checkMark : status === "pending" ? pendingImg : status === "failed" ? failedImg : ""} className="w-[100px] h-[100px]" alt="status" />
        <div className="text-center flex flex-col gap-5 items-center">
          <h4 className="font-bold text-xl capitalize">
            {title ? title : status}
          </h4>
          {description && (<p>{description}</p>)}
        </div>
        <Button
          label="Close"
          type="flat"
          onClick={handleClose}
        />
      </div>
    </Modal>
  )
}

export default GeneralStatusModal