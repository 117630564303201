import { type Dispatch, FormEvent, type SetStateAction, useEffect, useState } from 'react';
import Modal from '../../../../components/Modal'
import qrcodeImg from "../../../../assets/new/qrcode.png"
import Input, { InputLabel } from '../../../../components/Input';
import OTPInput from 'react-otp-input';
import Button from '../../../../components/Button';
import arrowRight from "../../../../assets/new/ArrowRightWhite.png"
import arrowLeft from "../../../../assets/new/rightArrow.png"
import bg_texture from "../../../../assets/new/texture.png"
import { formatCurrency } from '../../../../utils/common';
import { getMerchantVoucherDetaildByCode, redeemVoucher } from '../../../../actions/vouchersActions';
import useUserData from '../../../../hooks/useUserData';
import dayjs from 'dayjs';
import CopyData from '../../../../components/CopyData';
import GeneralStatusModal from '../../../../components/Modal/GeneralStatusModal';

function RedeemVoucherModal({ currentCode, openModal, handleClose }: {
  openModal: boolean, handleClose: (open: boolean) => void, currentCode?: string
}) {
  const [step, setStep] = useState<'redeem' | 'summary'>('redeem');
  const [voucherDetails, setVoucherDetails] = useState<Record<string, any> | null>(null)
  const [endModalOpen, setEndModalOpen] = useState(false)

  const [data, setData] = useState({
    email: "",
  })
  const [code, setCode] = useState(currentCode ? currentCode : '')


  const handleMove = (step: 'redeem' | 'summary') => {
    setStep(step)
  }

  const handleRedeem = () => {
    const reqBody = {
      email: data.email,
      voucher_code: code
    }

    redeemVoucher(reqBody, (resp) => {
      setStep("redeem")
      setCode("")
      setData({
        email: ""
      })
      handleClose(false)
      setEndModalOpen(true)
    })
  }

  return (
    <>
      <Modal
        onClose={() => handleClose(false)}
        open={openModal}
        title={''}
        overrideStyle={{
          height: 'auto',
          width: "95%",
          maxWidth: '700px',
          borderRadius: '30px',
          padding: '0',
          top: "50%",
        }}
        overrideChildrenStyle={{
          padding: '0',
          height: '100% !important'
        }}
      >
        {
          step === "redeem" ?
            (
              <RedeemVoucher setVoucherDetails={setVoucherDetails} data={data} code={code} setData={setData} setCode={setCode} handleMove={handleMove} />) :
            (
              <VoucherSummary voucherDetails={voucherDetails} handleMove={handleMove} handleRedeem={handleRedeem} />
            )
        }
      </Modal>
      <GeneralStatusModal
        open={endModalOpen}
        setOpen={setEndModalOpen}
        status='success'
        title={"Voucher redeemed successfully!"} />
    </>
  )
}

export default RedeemVoucherModal

const RedeemVoucher = (
  { handleMove, data, setData, code, setCode, setVoucherDetails }:
    {
      handleMove: (step: 'redeem' | 'summary') => void,
      data: { email: string },
      code: string,
      setVoucherDetails: Dispatch<SetStateAction<Record<string, any> | null>>
      setData: Dispatch<SetStateAction<{ email: string }>>
      setCode: Dispatch<SetStateAction<string>>
    }
) => {
  const { userDetails } = useUserData()

  const [focused, setFocused] = useState(false)
  const onFocus = () => setFocused(true)
  const onBlur = () => setFocused(false)

  const handleChange = (name, value) => {
    setData(prev => ({
      ...prev,
      [name]: value
    }))
  }

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()

    if (userDetails && userDetails.id) {
      getMerchantVoucherDetaildByCode({
        isSilent: false,
        merchantId: userDetails.id as string,
        voucherCode: code,
        handleDone: (resp) => {
          setVoucherDetails(resp?.data)
          handleMove("summary")
        }
      })
    }
  }

  return (
    <div className="w-full py-5 px-7 flex flex-col items-center">
      <div className="flex flex-col items-center gap-5">
        <img src={qrcodeImg} alt="qr code" className='w-[150px] h-[150px]' />
        <h1 className='text-3xl font-[500]'>Redeem Voucher</h1>
        <p className="p-5 w-full rounded-[10px] bg-[#0898A00D] text-sm font-[400] text-center">
          To redeem voucher, kindly the scan the QR code from the device
          of the beneficiary requesting for the voucher to be redeemed or
          request and enter the code provided for the individual
        </p>
      </div>
      <form onSubmit={handleSubmit}>
        <Input
          type="email"
          name="email"
          label="Beneficiary email address"
          placeholder='Enter beneficiary email address'
          value={data.email}
          onChange={handleChange}
          required={true}
        />

        <div className="w-full">
          <InputLabel
            props={{
              label: "Enter Voucher Code"
            }}
          />
          <OTPInput
            value={code}
            onChange={setCode}
            numInputs={10}
            renderInput={(props) => <input {...props} onFocus={onFocus} onBlur={onBlur} required />}
            inputStyle={{
              border: focused ? "1px solid #0898A0" : '1px solid #22222247',
              width: '50px',
              height: '50px',
              marginRight: '10px',
              borderRadius: '5px',
              margin: "0"
            }}
            containerStyle={{
              width: "100%",
              display: "flex",
              flexWrap: "wrap",
              gap: "10px",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          />
        </div>

        <Button
          type='flat'
          label='Proceed'
          rightIcon={<img src={arrowRight} />}
          className='!rounded-full !mt-5'
          btnActionType='submit'
        />
      </form>
    </div>
  )
}

const VoucherSummary = ({ handleMove, voucherDetails, handleRedeem }:
  { handleMove: (step: 'redeem' | 'summary') => void, voucherDetails: Record<string, any> | null, handleRedeem: () => void }
) => {
  const [summaryDetails, setSummaryDetails] = useState<Array<{ title: string, data: any }>>([])

  useEffect(() => {
    setSummaryDetails([
      {
        title: "Voucher amount",
        data: formatCurrency({ num: voucherDetails?.voucher_amount })
      },
      {
        title: "amount per code",
        data: formatCurrency({ num: voucherDetails?.amount_per_code })
      },
      {
        title: "PURPOSE",
        data: voucherDetails?.purpose
      },
      {
        title: "VOUCHER CODE",
        data: <div className="relative flex gap-2">{voucherDetails?.voucher_code} <CopyData data={voucherDetails?.voucher_code as string} /></div>
      },
      {
        title: "LIMIT",
        data: voucherDetails?.limit
      },
      {
        title: "type",
        data: voucherDetails?.type
      },
      {
        title: "CREATED DATE",
        data: dayjs(voucherDetails?.created_at).format("DD MMMM, YYYY")
      },
      {
        title: "EXPIRY DATE",
        data: dayjs(voucherDetails?.expiry_date).format("DD MMMM, YYYY")
      },

      {
        title: "SPONSOR ID",
        data: voucherDetails?.user_id
      },
      {
        title: "location",
        data: voucherDetails?.location
      },
      {
        title: "Code generation method",
        data: voucherDetails?.code_generation_method
      },
    ])
  }, [voucherDetails])
  return (
    <div className="w-full max-h-[100vh] overflow-y-auto p-5 flex flex-col  gap-5 items-center">
      <h1 className='text-3xl font-[500]'>Voucher Summary</h1>
      <div
        className="w-full flex flex-col gap-2 justify-center items-center p-5 rounded-[10px] bg-center !bg-cover bg-[#0898A01A] text=center"
        style={{
          background: "#0898A01A",
          backgroundImage: `url(${bg_texture})`,
        }}>
        <h4 className='text-[14px] font-[400]'>You are about to redeem a voucher worth</h4>
        <p className='text-[22px] font-[600] text-[#0898A0]'>{formatCurrency({ num: voucherDetails?.amount_per_code || 0 })}</p>
      </div>
      <div className="p-5 flex flex-wrap gap-y-5 gap-x-7 bg-[#0898A00D] rounded-[10px] w-full">
        {
          summaryDetails.map((detail, index) => {
            if (detail?.data) {
              return (
                <div key={index} className='flex flex-col justify-center items-start gap-2 min-w-[40%] sm:min-w-[115px] w-fit'>
                  <h5 className='text-[12px] font-[400] uppercase'>{detail.title}</h5>
                  <div className='text-[13px] font-[500]'>{detail.data}</div>
                </div>
              )
            }

            return null
          })
        }
      </div>
      <div className="w-full flex gap-5">
        <Button
          type='flat'
          label='Back'
          leftIcon={<img src={arrowLeft} className='rotate-180' />}
          className='!rounded-full !m-0 !bg-[#2222220D] !text-black'
          btnActionType='button'
          onClick={() => handleMove('redeem')}
        />
        <Button
          type='flat'
          label='Redeem'
          rightIcon={<img src={arrowRight} />}
          className='!rounded-full !m-0'
          btnActionType='button'
          onClick={() => handleRedeem()}
        />
      </div>
    </div>
  )
}