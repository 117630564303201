import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { toggleSideNav } from "../../Dashboard/dashboardSlice";
import arrowDown from "../voucher-assets/icons/arrow-down.svg"
import useUserType from "../../../hooks/useUserType";
import { shortenText } from "../../../utils/common";
import { useLocation } from "react-router-dom";
import useUserData from "../../../hooks/useUserData";

interface IVoucherDashboardComponentWrapper {
  children: JSX.Element;
  title?: string;
  subTitle?: string,
  overrideName?: () => JSX.Element;
  renderNavItems?: () => JSX.Element;
}
export default function VoucherDashboardComponentWrapper({ children, title, subTitle }: IVoucherDashboardComponentWrapper) {
  const { abbr, name, userDetails } = useUserData()
  const dispatch = useAppDispatch();

  return (
    <div className="voucher-dash-comp-wrapper w-[100%]">
      <div className="!sticky top-0 z-[5] lg:!relative w-[100%] h-[80px] bg-white px-[20px] py-[10px] flex justify-between items-center shadow-sm">
        {/* desktop */}
        <div className="text-left hidden lg:block">
          <h4 className='text-[18px] font-[500] flex flex-row items-center '>
            {title}
          </h4>
          <p className='w-max text-left text-[12px] font-[400] text-[#222222CC]'>{subTitle}</p>
        </div>

        <div className="top-profile hidden lg:flex justify-center items-center p-[10px] rounded-full gap-[10px] lg:bg-[#0898A00D]">
          <div className="profile-pic w-[40px] h-[40px] rounded-full flex justify-center items-center bg-[#0898A0] text-[16px] font-[500] text-[white]">
            {abbr}
          </div>
          <div className="flex flex-col gap-1 text-left h-auto">
            <p className="text-[14px] font-semibold leading-[100%]">{shortenText(name as string, 20)}</p>
            <p className="text-[12px] font-light leading-[100%] text-[#222222CC]">{shortenText(userDetails?.email as string, 20)}</p>
          </div>
          {/* <img src={arrowDown} alt="" /> */}
        </div>

        {/* mobile */}
        <div className="flex lg:hidden justify-center items-center gap-[10px]">
          <div className="profile-pic w-[40px] h-[40px] rounded-full flex justify-center items-center bg-[#0898A0] text-[16px] font-[500] text-[white]">
            {abbr}
          </div>
          <div className="flex flex-col gap-1 text-left h-auto">
            <p className="text-[14px] font-light leading-[100%]">{title}</p>
            <h4 className="text-[16px] font-semibold leading-[100%] text-[#222222CC]">{shortenText(name as string, 20)}</h4>
          </div>
        </div>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="14"
          viewBox="0 0 20 14"
          fill="none"
          className='cursor-pointer flex lg:hidden'
          onClick={() => {
            dispatch(toggleSideNav(true));
          }}
        >
          <path d="M19 2H1C0.734784 2 0.48043 1.89464 0.292893 1.70711C0.105357 1.51957 0 1.26522 0 1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0H19C19.2652 0 19.5196 0.105357 19.7071 0.292893C19.8946 0.48043 20 0.734784 20 1C20 1.26522 19.8946 1.51957 19.7071 1.70711C19.5196 1.89464 19.2652 2 19 2ZM20 7C20 6.73478 19.8946 6.48043 19.7071 6.29289C19.5196 6.10536 19.2652 6 19 6H1C0.734784 6 0.48043 6.10536 0.292893 6.29289C0.105357 6.48043 0 6.73478 0 7C0 7.26522 0.105357 7.51957 0.292893 7.70711C0.48043 7.89464 0.734784 8 1 8H19C19.2652 8 19.5196 7.89464 19.7071 7.70711C19.8946 7.51957 20 7.26522 20 7ZM20 13C20 12.7348 19.8946 12.4804 19.7071 12.2929C19.5196 12.1054 19.2652 12 19 12H1C0.734784 12 0.48043 12.1054 0.292893 12.2929C0.105357 12.4804 0 12.7348 0 13C0 13.2652 0.105357 13.5196 0.292893 13.7071C0.48043 13.8946 0.734784 14 1 14H19C19.2652 14 19.5196 13.8946 19.7071 13.7071C19.8946 13.5196 20 13.2652 20 13Z" fill="black" />
        </svg>
      </div>

      <div className="w-[100%] min-h-[100vh] h-auto flex flex-col items-center gap-[20px]">{children}</div>
    </div>
  )
}
