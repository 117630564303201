import Button from "../../components/Button";
import TabComponent from "../../components/TabComponent";
import GoBackBtn from "../Voucher/voucher-components/GoBackBtn";
import VoucherDashboardComponentWrapper from "../Voucher/voucher-layouts/VoucherDashboardComponentWrapper";
import VoucherTopComponents from "../Voucher/voucher-layouts/VoucherTopComponents";
import SponsorVoucherDetails from "./Components/SponsorVoucherDetails";
import VoucherPerformance from "./Components/VoucherPerformance";

export default function SponsorVoucherDetailsPage() {
  return (
    <VoucherDashboardComponentWrapper
      title="AzatMe Vouchers"
    // subTitle="Here are an overview of all your vouchers"
    >
      <>
        <VoucherTopComponents
          filled={true}
          leftComponents={[<GoBackBtn />]}
          middleComponents={
            [
              <h4 className='flex sm:hidden'>Voucher Details</h4>
            ]
          }
          rightComponents={
            [
              <span className="invisible"></span>
              // <Button
              //   label="Revoke Voucher"
              //   type="flat"
              //   className="!w-fit !px-6 !rounded-full !bg-[#D72020] !m-0"
              // />
            ]
          }
        />
        <TabComponent
          components={
            {
              "Voucher Information": () => <SponsorVoucherDetails />,
              // "Voucher Performance": () => <VoucherPerformance />,
            }
          }
          isVoucherTab={true}
        />
      </>
    </VoucherDashboardComponentWrapper>
  )
}