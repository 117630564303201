import VoucherDashboardComponentWrapper from '../Voucher/voucher-layouts/VoucherDashboardComponentWrapper'
import TabComponent from '../../components/TabComponent'
import SponsorProfile from './Components/SponsorProfile'
import { ProfileDetails } from '../Profile'

function SponsorSettings() {

  return (
    <VoucherDashboardComponentWrapper
      title="Settings"
    >
      <div className="w-full my-5">
        <TabComponent
          isVoucherTab={true}
          components={{
            "Profile": ProfileDetails,
            "Sponsor Profile": () => <SponsorProfile />
          }}
        />
      </div>
    </VoucherDashboardComponentWrapper>
  )
}

export default SponsorSettings
