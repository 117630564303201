import { createSlice } from "@reduxjs/toolkit";

export interface IVoucher {
  id: number;
  user_id: number;
  voucher_code: string;
  sponsor_id: number;
  purpose: string;
  expiry_date: string;
  limit: number;
  voucher_amount: string;
  amount_per_code: string;
  location: string;
  type: string;
  voucher_status: string;
  status: string;
  code_generation_method: string;
  deleted_at: string | null;
  created_at: string | null;
  updated_at: string | null;
}

export interface IVoucherList {
  data: Array<IVoucher> | null
  current_page: number;
  first_page_url: string;
  from: number;
  last_page: number;
  last_page_url: string;
  next_page_url: string;
  path: string;
  per_page: number;
  prev_page_url: string | null;
  to: number;
  total: number;
}

export interface ISponsorAccount {
  id: number;
  user_id: number;
  sponsor_name: string;
  sponsor_registration_number: string;
  sponsor_description: string;
  sponsor_registration_certificate: any;
  tin_number: string;
  type: string;
  isSponsorVerified: number;
  deleted_at: string | null;
  created_at: string | null;
  updated_at: string | null;
}

export interface IMerchantBusiness {
  id: number;
  business_name: string;
  business_code: string;
  business_logo: string;
  owner_id: number;
  business_address: string;
  business_email: string;
  vat_id: string;
  registration_number: string;
  type: string;
  description: string;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  vat_option: string | null;
  uuid_code: string | null;
  voucher_code: string | null;
}

interface VoucherState {
  vouchers: IVoucherList | null;
  singleVoucher: IVoucher | null;
  walletBalance: string | null;
  spoonsorAccounts: Array<ISponsorAccount> | null
  merchantBusinesses: Array<IMerchantBusiness> | null
  voucherMerchants: Array<IMerchantBusiness> | null
}

const initialState: VoucherState = {
  vouchers: null,
  singleVoucher: null,
  walletBalance: null,
  spoonsorAccounts: null,
  merchantBusinesses: null,
  voucherMerchants: null,
}

export const voucherSlice = createSlice({
  name: 'voucher',
  initialState,
  reducers: {
    addVouchers: (state, action) => {
      state.vouchers = action.payload;
    },
    addSingleVouchers: (state, action) => {
      state.singleVoucher = action.payload;
    },
    getWalletBalance: (state, action) => {
      state.walletBalance = action.payload
    },
    getSponsorAccounts: (state, action) => {
      state.spoonsorAccounts = action.payload;
    },
    setMerchantBusinesses: (state, action) => {
      state.merchantBusinesses = action.payload;
    },
    setVoucherMerchants: (state, action) => {
      state.voucherMerchants = action.payload;
    },
  }
})

export const {
  addVouchers,
  addSingleVouchers,
  getWalletBalance,
  getSponsorAccounts,
  setMerchantBusinesses,
  setVoucherMerchants
} = voucherSlice.actions

export default voucherSlice.reducer