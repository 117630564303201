import { useEffect, useState } from 'react'
import { formatCurrency } from '../../../utils/common'
import MerchantDetailsListComponent from '../../Voucher/voucher-components/MerchantDetailsListComponent'
import VoucherDetailsComponsnt from '../../Voucher/voucher-components/VoucherDetailsComponsnt'
import { getSingleVoucherBySponsor, getVoucherDetaildByCode } from '../../../actions/vouchersActions'
import SidePane from '../../../components/SidePane'
import CreateVoucher from './CreateVoucher'
import { useParams } from 'react-router-dom'
import { useAppSelector } from '../../../app/hooks'
import dayjs from 'dayjs'
import CopyData from '../../../components/CopyData'

const VoucherDetails = [
  {
    title: "Voucher amount",
    data: formatCurrency({ num: 50000000 })
  },
  {
    title: "amount per code",
    data: formatCurrency({ num: 5000 })
  },
  {
    title: "PURPOSE",
    data: "Special Event"
  },
  {
    title: "LIMIT",
    data: formatCurrency({ num: 500000 })
  },
  {
    title: "type",
    data: "Multiple Times"
  },
  {
    title: "CREATED DATE",
    data: "24th Sept, 2024"
  },
  {
    title: "EXPIRY DATE",
    data: "24th Sept, 2024"
  },
  {
    title: "VOUCHER CODE",
    data: "19AGVCBQOA"
  },
  {
    title: "SPONSOR ID",
    data: "#S-920"
  },
  {
    title: "location",
    data: "Oyo State"
  },
  {
    title: "Code generation method",
    data: "QR_Code"
  },
]

function SponsorVoucherDetails() {
  // const [voucherDetails, setVoucherDetails] = useState(null)
  const [formattedVoucherDetails, setFormattedVoucherDetails] = useState<Array<Record<string, any>> | null>(null)
  const [editVoucherOpen, setEditVoucherOpen] = useState(false)
  const [editStep, setEditStep] = useState<1 | 2>(1)

  const { singleVoucher, voucherMerchants } = useAppSelector(state => state.voucher)
  const params = useParams()
  const voucherCode = params?.voucherCode

  useEffect(() => {
    if (!voucherCode) return;
    getVoucherDetaildByCode({
      voucherCode, isSilent: false
    })
  }, [voucherCode])

  useEffect(() => {
    setFormattedVoucherDetails(
      [
        {
          title: "Voucher amount",
          data: formatCurrency({ num: singleVoucher?.voucher_amount })
        },
        {
          title: "amount per code",
          data: formatCurrency({ num: singleVoucher?.amount_per_code })
        },
        {
          title: "PURPOSE",
          data: singleVoucher?.purpose
        },
        {
          title: "LIMIT",
          data: singleVoucher?.limit
        },
        {
          title: "type",
          data: singleVoucher?.type
        },
        {
          title: "CREATED DATE",
          data: dayjs(singleVoucher?.created_at).format("DD MMMM, YYYY")
        },
        {
          title: "EXPIRY DATE",
          data: dayjs(singleVoucher?.expiry_date).format("DD MMMM, YYYY")
        },
        {
          title: "VOUCHER CODE",
          data: <div className="relative flex gap-2">{singleVoucher?.voucher_code} <CopyData data={singleVoucher?.voucher_code as string} /></div>
        },
        {
          title: "SPONSOR ID",
          data: singleVoucher?.user_id
        },
        {
          title: "location",
          data: singleVoucher?.location
        },
        {
          title: "Code generation method",
          data: singleVoucher?.code_generation_method
        },
      ])
  }, [singleVoucher])

  return (
    <div className="w-full flex flex-col gap-[20px]">
      <VoucherDetailsComponsnt voucherDetails={singleVoucher} voucher={formattedVoucherDetails} editable={true}
        onEditClick={() => {
          setEditStep(1);
          setEditVoucherOpen(true)
        }} />
      <MerchantDetailsListComponent voucherCode={singleVoucher?.voucher_code as string} editable={true}
        onEditClick={() => {
          setEditStep(2);
          setEditVoucherOpen(true)
        }}
      />
      <SidePane open={editVoucherOpen} title="" onClose={() => setEditVoucherOpen(false)}>
        <CreateVoucher handleClose={() => setEditVoucherOpen(false)} editMode={true}
          editData={{ ...singleVoucher, selectedBusinesses: voucherMerchants ? voucherMerchants : [] }} editStep={editStep} successEffectAction={() => {
            voucherCode && getVoucherDetaildByCode({
              voucherCode, isSilent: false
            })
          }} />
      </SidePane>
    </div>
  )
}

export default SponsorVoucherDetails