import { createSlice } from '@reduxjs/toolkit'
import { logout } from '../Auth/authSlice';

export interface Participants {
  id: number;
  email: string;
}

export interface IBusiness {
  business_logo: string;
  business_code: string;
  business_email: string;
  business_name: string;
  business_phone: string;
  business_address: string;
  type: string;
  description: string;
  vat_id: string;
  registration_number: string;
  id: string;
}

export interface ITransactOption {
  id: number,
  option: string,
}

export interface ICustomer {
  customer_name: string;
  customer_email: string;
  customer_phone: string;
  customer_code: string;
  owner_id: number;
  id: number;
}

export interface IProduct {
  name: string;
  description: string;
  amount: number;
  category: number;
  subcategory: number;
  id: string;
}

type ITransaction = Record<string, any>;

export interface IInvoiceData {
  id: string;
  owner_id: string;
  unique_code: string;
  name: string;
  bankName: string;
  account_number: string;
  user_id: string;
  dueDate: string;
  dueDays: string;
  status: string;
  amount: number;
}


interface BusinessState {
  businesses: Array<IBusiness> | null;
  customers: Record<string, Array<ICustomer | null>>
  products: Record<string, Array<IProduct | null>>
  transactionOptions: Array<ITransactOption> | null
  transactions: Record<string, Array<ITransaction | null>>
  transactionsMPOS: Record<string, Array<ITransaction | null>>
  createdInvoices: Record<string, Array<IInvoiceData | null>>
  createdLinks: Record<string, Array<IInvoiceData | null>>
  createdInvoicesMetaData: Record<string, any>;
  nqrs: Array<Record<string, any>> | null;
}

const initialState: BusinessState = {
  businesses: null,
  customers: {},
  products: {},
  transactionOptions: null,
  transactions: {},
  transactionsMPOS: {},
  createdInvoices: {},
  createdLinks: {},
  createdInvoicesMetaData: {},
  nqrs: null,
}

export const businessSlice = createSlice({
  name: 'business',
  initialState,
  reducers: {
    addBusiness: (state, action) => {
      state.businesses?.push(action.payload);
    },
    updateBusiness: (state, action) => {
      let newBusinesses = state.businesses;
      newBusinesses = newBusinesses?.map((business) => {
        if (business.id === action.payload.businessId) {
          return action.payload.data
        }
        return business;
      }) as unknown as Array<IBusiness>;
      state.businesses = newBusinesses;
    },
    deleteBusiness: (state, action) => {
      let newBusinesses = state.businesses;
      newBusinesses = newBusinesses?.filter((business) => business.id !== action.payload.businesId) as unknown as Array<IBusiness>;
      state.businesses = newBusinesses;
    },
    addBusinessess: (state, action) => {
      state.businesses = action.payload;
    },
    addCustomers: (state, action) => {
      state.customers[action.payload.businessCode] = action.payload.data;
    },
    addCustomer: (state, action) => {
      if (state.customers[action.payload.businessCode]) {
        state.customers[action.payload.businessCode].push(action.payload.data);
      } else {
        state.customers[action.payload.businessCode] = [action.payload.data];
      }
    },
    deleteCustomer: (state, action) => {
      let customers = state.customers[action.payload.businessCode];
      customers = customers.filter((customer) => customer?.id !== action.payload.customerId);
      state.customers[action.payload.businessCode] = customers;
    },
    updateCutomer: (state, action) => {
      let newCustomers = state.customers[action.payload.businessCode];
      newCustomers = newCustomers?.map((customer) => {
        if (customer?.id === action.payload.customerId) {
          return action.payload.data;
        }
        return customer;
      }) as unknown as Array<ICustomer>;
      state.customers[action.payload.businessCode] = newCustomers;
    },
    addProduct: (state, action) => {
      if (state.products[action.payload.businessCode]) {
        // @ts-ignore
        state.products[action.payload.businessCode].data.push(action.payload.product);
      } else {
        state.products[action.payload.businessCode] = [action.payload.product]
      }
    },
    addProducts: (state, action) => {
      state.products[action.payload.businessCode] = action.payload.products;
    },
    addTransactions: (state, action) => {
      state.transactions[action.payload.businessCode] = action.payload.transactions;
    },
    addTransactionsMPOS: (state, action) => {
      state.transactionsMPOS[action.payload.businessCode] = action.payload.transactions;
    },
    addTransaction: (state, action) => {
      if (state.transactions[action.payload.businessCode]) {
        // @ts-ignore
        state.transactions[action.payload.businessCode].data?.push(action.payload.transaction);
      } else {
        state.transactions[action.payload.businessCode] = [action.payload.transaction]
      }
    },
    addOptions: (state, action) => {
      state.transactionOptions = action.payload;
    },
    addCreatedInvoices: (state, action) => {
      state.createdInvoices[action.payload.businessId] = action.payload.data.data;
    },
    addCreatedLinks: (state, action) => {
      state.createdLinks[action.payload.businessId] = action.payload.data.data;
    },
    addCreatedInvoicesMetaData: (state, action) => {
      state.createdInvoicesMetaData[action.payload.businessCode] = action.payload.data;
    },
    setAllNQRs: (state, action) => {
      state.nqrs = action.payload
    },
    addNQR: (state, action) => {
      state.nqrs?.push(action.payload)
    },
    removeNQR: (state, action) => {
      if (!state.nqrs) return
      state.nqrs = state.nqrs.filter((item) => item.id !== action.payload.id)
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logout, (state) => {
      state = { ...initialState };
      return state;
    })
  }
})

export const {
  addBusinessess,
  addBusiness,
  addCustomers,
  addCustomer,
  updateBusiness,
  deleteCustomer,
  updateCutomer,
  deleteBusiness,
  addProduct,
  addTransactionsMPOS,
  addProducts,
  addOptions,
  addTransaction,
  addTransactions,
  addCreatedInvoicesMetaData,
  addCreatedInvoices,
  addCreatedLinks,
  setAllNQRs,
  addNQR,
  removeNQR,
} = businessSlice.actions;

export default businessSlice.reducer;
