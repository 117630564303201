// "user_id": 12,
//         "sponsor_name": "Tech Solutions Ltd\tText",
//         "sponsor_registration_number": "TSL-202401",
//         "sponsor_description": "A leading tech company",
//         "sponsor_registration_certificate": null,
//         "tin_number": "1234567890",
//         "type": "private",
//         "isSponsorVerified": 1,

import { FormEvent, useEffect, useState } from "react"
import useUserData from "../../../hooks/useUserData"
import useVerificationDone from "../../../hooks/useVerificationDone"
import { createSponsorAccount, getSponsorAccountById, getSponsorsUnderOwner, updateSponsorAccount } from "../../../actions/vouchersActions"
import VoucherStatusTag from "../../Voucher/voucher-components/VoucherStatusTag"
import Button from "../../../components/Button"
import editIcon from "../../../assets/new/Edit.svg"
import NotFound from "../../../components/NotFound"
import SidePane from "../../../components/SidePane"
import Input, { Select } from "../../../components/Input"
import { useAppSelector } from "../../../app/hooks"
import { getAbbr, shortenText } from "../../../utils/common"
import { ISponsorAccount } from "../../Voucher/voucherSlice"

const profileDetails = [
  {
    title: "Organization Name",
    data: "Tech Solutions Ltd"
  },
  {
    title: "Organization Type",
    data: "private"
  },
  {
    title: "Registration Number",
    data: "TSL-202401"
  },
  {
    title: "TIN",
    data: "1234567890"
  },
  {
    title: "Description",
    data: `We’re a world class Government Institution that ensures a healthy Nigeria through
the provision of effective healthcare facilities and humanitarian.`
  },

]
export default function SponsorProfile() {
  const [openCompanyForm, setOpenCompanyForm] = useState(false)
  const [editCompanyMode, setEditCompanyMode] = useState(false)
  const [companyToEditData, setCompanyToEditData] = useState<ISponsorAccount | null>(null)

  const { spoonsorAccounts } = useAppSelector((state) => (state.voucher))
  const profile = useAppSelector((state) => state.auth.user);
  const { abbr, userDetails, name } = useUserData()
  const { kycActionWrapper, isVerificationDone } = useVerificationDone()

  console.log(profile);
  
  useEffect(() => {
    if (isVerificationDone) {
      getSponsorsUnderOwner()
    }
    if (userDetails && userDetails.id) {
      // getSponsorAccountById(userDetails.id as string, (resp) => {
      //   setProfileData(resp?.data)
      // })
    }
  }, [userDetails, isVerificationDone])

  return (
    <>
      <div className='w-full flex flex-col gap-5'>
        <div className="w-full bg-white flex justify-between items-center rounded-[10px] p-5">
          <div className="flex gap-2 items-center">
            <div className="profile-pic w-[60px] h-[60px] rounded-full flex justify-center items-center bg-[#0898A0] text-[22px] font-[500] text-[white]">
              {abbr}
            </div>
            <div className="flex flex-col gap-2">
              <h4 className='text-[15px] font-[500]'>{name ? name : userDetails?.email}</h4>
              <VoucherStatusTag status={"active"} type="text" statusText='Verification Done' />
            </div>
          </div>

          {/* <Button
            label='Edit'
            type='contained'
            className='!rounded-full !m-0 items-center !py-0'
            rightIcon={<img src={editIcon} />}
            onClick={() => { kycActionWrapper(() => { }) }}
          /> */}
        </div>

        {/* <div className='w-[100%] rounded-md bg-[#FFFFFF]'>
          <div className="w-full flex justify-between items-center p-[20px] bg-[#0898A00D] rounded-t-[inherit] rounded-b-none">
            <div className="w-fit flex items-center gap-2">
              <div className="flex flex-col gap-1 w-[full] text-left">
                <h4 className="text-[14px] font-[500] leading-[100%]">Profile Information</h4>
              </div>
            </div>

            <Button
              label='Edit'
              type='contained'
              className='!rounded-full !m-0 items-center !py-0'
              rightIcon={<img src={editIcon} />}
              onClick={() => { kycActionWrapper(() => { }) }}
            />
          </div>
          <div className="w-full p-[20px] flex flex-wrap gap-x-5 gap-y-10">
            {
              profileDetails.map((detail, index) => {
                return (
                  <div key={index} className='flex flex-col justify-center items-start gap-2 min w-[100%]-md:w-[48%] sm:min-w-[200px] w-fit max-w-[450px] text-left'>
                    <h5 className='text-[12px] font-[400] uppercase'>{detail.title}</h5>
                    <div className='text-[13px] font-[500]'>{detail.data}</div>
                  </div>
                )
              })
            }
          </div>
        </div> */}

        <div className='w-[100%] rounded-md bg-[#FFFFFF]'>
          <div className="w-full flex justify-between items-center p-[20px] bg-[#0898A00D] rounded-t-[inherit] rounded-b-none">
            <div className="w-fit flex items-center gap-2">
              <div className="flex flex-col gap-1 w-[full] text-left">
                <h4 className="text-[14px] font-[500] leading-[100%]">Companies under sponsor</h4>
              </div>
            </div>

            <Button
              label='+ Add'
              type='contained'
              className='!rounded-full !m-0 items-center !py-0'
              // rightIcon={<img src={editIcon} />}
              onClick={() => { kycActionWrapper(() => { setOpenCompanyForm(true) }) }}
            />
          </div>

          {
            spoonsorAccounts && spoonsorAccounts?.length > 0 ?
              (<div className="flex flex-wrap justify-between gap-5 p-5">
                {
                  spoonsorAccounts.map((company, index) => {
                    return (
                      <div key={company.id} className="flex w-[100%] md:w-[48%] items-center justify-between gap-5 border border-[#2222221A] p-5 rouded-[10px]">
                        <div className="flex gap-2 justify-center items-center">
                          <div className="profile-pic w-[40px] h-[40px] rounded-full flex justify-center items-center bg-[#0898A0] text-[14px] font-[500] text-[white]">
                            {getAbbr(company?.sponsor_name)}
                          </div>
                          <div className="flex flex-col gap-2 text-left">
                            <h4 className='text-[15px] font-[500] leading-[100%]'>{company?.sponsor_name}</h4>
                            <p className='text-[12px] font-[400] leading-[100%]'>
                              {shortenText(company?.sponsor_description, 30)}
                            </p>
                          </div>
                        </div>
                        <Button
                          label='Edit'
                          type='contained'
                          className='!rounded-full !m-0 items-center !py-0'
                          rightIcon={<img src={editIcon} />}
                          onClick={() => {
                            kycActionWrapper(() => {
                              setEditCompanyMode(true)
                              setCompanyToEditData(company)
                              setOpenCompanyForm(true)
                            })
                          }}
                        />
                      </div>
                    )
                  }
                  )}
              </div>) :
              (
                <NotFound
                  text1='No company added yet'
                  text2=''
                  btnLabel='+ Add new'
                  onClick={() => { kycActionWrapper(() => { setOpenCompanyForm(true) }) }}
                />
              )
          }
        </div>
      </div>
      <SidePane title="" onClose={() => {
        setOpenCompanyForm(false)
        setEditCompanyMode(false)
        setCompanyToEditData(null)
      }} open={openCompanyForm}>
        <CompanyForm editMode={editCompanyMode} companyData={companyToEditData} closeForm={() => {
          setOpenCompanyForm(false)
          setEditCompanyMode(false)
          setCompanyToEditData(null)
        }} />
      </SidePane>
    </>
  )
}

const CompanyForm = ({ editMode = false, closeForm, companyData }: { editMode?: boolean, closeForm: () => void, companyData?: ISponsorAccount | null }) => {
  const [data, setData] = useState(() => {
    if (editMode && companyData) {
      return {
        sponsor_name: companyData.sponsor_name,
        sponsor_registration_number: companyData.sponsor_registration_number,
        sponsor_description: companyData.sponsor_description,
        tin_number: companyData.tin_number,
        type: companyData.type,
      }
    }
    return {
      sponsor_name: "",
      sponsor_registration_number: "",
      sponsor_description: "",
      tin_number: "",
      type: "",
    }
  })

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (editMode) {
      if (!companyData) {
        return;
      }
      updateSponsorAccount(
        companyData?.id,
        data,
        (resp) => {
          closeForm()
          getSponsorsUnderOwner()
        })
      return;
    }

    createSponsorAccount(data, (resp) => {
      closeForm()
      getSponsorsUnderOwner()
    })
  }

  const handleChange = (name, value) => {
    setData((prev) => ({ ...prev, [name]: value }))
  }

  return (
    <div className="w-full flex !flex-col">
      <h1 className="text-2xl mb-3">{editMode ? "Edit" : "Add"} Company</h1>
      <p className="text-[14px] font-[400]">
        Enter the following information about the company you’re
        about to add.
      </p>

      <form className="w-full my-5" onSubmit={handleSubmit}>
        <Input
          label="Company Name"
          name="sponsor_name"
          value={data.sponsor_name}
          type="text"
          onChange={handleChange}
          required
        />
        <Input
          label="Company Reg. Number"
          name="sponsor_registration_number"
          value={data.sponsor_registration_number}
          type="text"
          onChange={handleChange}
          required
        />
        <Input
          label="Company Description"
          name="sponsor_description"
          value={data.sponsor_description}
          type="text"
          onChange={handleChange}
          required
        />
        <Input
          label="Company TIN"
          name="tin_number"
          value={data.tin_number}
          type="text"
          onChange={handleChange}
          required
        />
        <Select
          data={["private"]}
          label="Company Type"
          name="type"
          value={data.type}
          onChange={handleChange}
          required
        />
        <Button type="flat" btnActionType="submit" label={editMode ? "Update Company" : "Add Company"} className="!rounded-full !mt-5" />
      </form>
    </div>
  )
}