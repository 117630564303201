import React, { FormEvent, useEffect, useState } from 'react'
import DashboardComponentWrapper from '../../../components/DashboardComponentWrapper'
import { getAllVouchers, updateVoucherStatus } from '../../../actions/vouchersActions'
import SidePane from '../../../components/SidePane'
import { useAppSelector } from '../../../app/hooks'
import Table from '../../../components/Table'
import { formatCurrency } from '../../../utils/common'
import CopyData from '../../../components/CopyData'
import { TableAction } from '../../../components/ExpenseList'
import NotFound from '../../../components/NotFound'
import { IVoucher } from '../../Voucher/voucherSlice'
import TablePaginationFooter from '../../../components/TablePaginationFooter/TablePaginationFooter'
import TabComponent from '../../../components/TabComponent'
import Modal from '../../../components/Modal'
import { Select } from '../../../components/Input'
import Button from '../../../components/Button'
import VoucherStatusTag from '../../Voucher/voucher-components/VoucherStatusTag'

function ScreenVouchers() {
  const [singleVoucher, setSingleVoucher] = useState<IVoucher | null>(null)
  const { vouchers } = useAppSelector(store => store.voucher)

  useEffect(() => {
    getAllVouchers({ isSilent: false })
  }, [])

  const header = [
    { field: 'purpose', headerName: 'Voucher Purpose' },
    { field: 'voucher_amount', headerName: 'Voucher Amount', cellRenderer: ({ data }: any) => formatCurrency({ num: data?.voucher_amount }) },
    { field: 'amount_per_code', headerName: 'Amount Per Code', cellRenderer: ({ data }: any) => formatCurrency({ num: data?.amount_per_code }) },
    { field: 'limit', headerName: 'Limit' },
    { field: 'expiry_date', headerName: 'Exp. Date' },
    { field: 'voucher_code', headerName: 'Voucher Code', cellRenderer: ({ data }: any) => (<div>{data.voucher_code} <CopyData data={data.voucher_code} /></div>) },
    { field: 'sponsor_id', headerName: 'Sponsor ID' },
    { field: 'location', headerName: 'Location' },
    {
      field: 'action',
      headerName: 'Action',
      cellRenderer: ({ data }: any) => <TableAction handleMore={() => {
        setSingleVoucher(data)
      }} />
    }
  ]

  const handlePagination = (payload) => {
    const page = payload.split('=')[1];
    getAllVouchers({ page, isSilent: true });
  }

  return (
    <DashboardComponentWrapper
      subTitle="Approve / reject vouchers"
      title='Voouchers'
      pageDetails="Here's an overview of created vouchers on AzatMe Voucher."
    // renderNavItems={renderNavItems}
    >
      <>
        <TabComponent
          components={{
            "Vouchers": () => (<div className="w-full h-[50vh]">
              {
                // @ts-ignore
                vouchers?.data && vouchers?.data.length > 0 ? (
                  <>
                    <Table
                      // @ts-ignore
                      data={vouchers?.data}
                      header={header}
                      fitStrategy="fitCellContents"
                      rowClickHandler={(data) => { setSingleVoucher(data?.data) }}
                    />
                    <TablePaginationFooter
                      from={vouchers?.from}
                      nextUrl={vouchers?.next_page_url}
                      prevUrl={vouchers?.next_page_url}
                      total={vouchers?.total}
                      to={vouchers?.to}
                      handlePagination={handlePagination}
                    />
                  </>
                ) :
                  (
                    <NotFound text1='No Voucher Found' text2='' />
                  )
              }
            </div>)
          }}
        />

        <SidePane title='' open={!!singleVoucher} onClose={() => { setSingleVoucher(null) }}>
          <VoucherDetailsSide voucher={singleVoucher as IVoucher} />
        </SidePane>
      </>
    </DashboardComponentWrapper>
  )
}

export default ScreenVouchers

const VoucherDetailsSide = ({ voucher }: { voucher: IVoucher }) => {
  const [open, setOpen] = useState(false)
  const [updateData, setUpdateData] = useState({
    status: voucher?.status || ""
  })

  const handleChange = (name, value) => {
    setUpdateData(prev => ({
      ...prev,
      [name]: value
    }))
  }
  const handleClose = () => setOpen(false)

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()

    updateVoucherStatus(voucher.id, updateData, (resp) => {
      setOpen(false)
      getAllVouchers({ isSilent: true })
    })
  }

  return (
    <>
      <div className="c-user-details w-full flex !flex-col">
        <div className="flex flex-row flex-wrap w-full justify-between items-center border-b-2 border-gray-300 pb-6">
          <p className="text-2xl">Voucher Details</p>
          <Button label='Update Status' type='flat' btnActionType='button' onClick={() => setOpen(true)} className='!w-fit !p-3' />
          {/* <img className="w-20 h-20" src={user?.image} alt="" /> */}
        </div>
        <div className="my-2 flex flex-row w-full justify-between items-center">
          Voucher status:
          <VoucherStatusTag status={voucher.status === "approved" ? "active" : voucher.status === "rejected" ? "revoked" : "pending"} statusText={voucher.status} type='filled' />
        </div>
        <VoucherStatusTag status={voucher.voucher_status === "unused" ? "active" : voucher.status === "used" ? "revoked" : "pending"} statusText={voucher.voucher_status} type='filled' withDot={false} />
        
        <div className="flex flex-col w-full">
          <div className="flex w-full flex-row justify-between items-start py-4 border-b-2 border-gray-300">
            <p className="text-sm text-az-black-900">Purpose</p>
            <p className="text-sm text-az-black-900 !text-right pl-1 overflow-auto">{voucher.purpose}</p>
          </div>
        </div>
        <div className="flex flex-col w-full">
          <div className="flex w-full flex-row justify-between items-start py-4 border-b-2 border-gray-300">
            <p className="text-sm text-az-black-900">Total Amount</p>
            <p className="text-sm text-az-black-900 !text-right pl-1 overflow-auto">{formatCurrency({ num: voucher.voucher_amount })}</p>
          </div>
        </div>
        <div className="flex flex-col w-full">
          <div className="flex w-full flex-row justify-between items-start py-4 border-b-2 border-gray-300">
            <p className="text-sm text-az-black-900">Per Code</p>
            <p className="text-sm text-az-black-900 !text-right pl-1 overflow-auto">{formatCurrency({ num: voucher.amount_per_code })}</p>
          </div>
        </div>
        <div className="flex flex-col w-full">
          <div className="flex w-full flex-row justify-between items-start py-4 border-b-2 border-gray-300">
            <p className="text-sm text-az-black-900">Type</p>
            <p className="text-sm text-az-black-900 !text-right pl-1 overflow-auto">{voucher.type}</p>
          </div>
        </div>
        <div className="flex flex-col w-full">
          <div className="flex w-full flex-row justify-between items-start py-4 border-b-2 border-gray-300">
            <p className="text-sm text-az-black-900">Code</p>
            <p className="text-sm text-az-black-900 !text-right pl-1 overflow-auto flex ">{voucher.voucher_code} <CopyData data={voucher.voucher_code} /></p>
          </div>
        </div>
        <div className="flex flex-col w-full">
          <div className="flex w-full flex-row justify-between items-start py-4 border-b-2 border-gray-300">
            <p className="text-sm text-az-black-900">Exp. Date</p>
            <p className="text-sm text-az-black-900 !text-right pl-1 overflow-auto">{voucher.expiry_date}</p>
          </div>
        </div>
        <div className="flex flex-col w-full">
          <div className="flex w-full flex-row justify-between items-start py-4 border-b-2 border-gray-300">
            <p className="text-sm text-az-black-900">Location</p>
            <p className="text-sm text-az-black-900 !text-right pl-1 overflow-auto">{voucher.location}</p>
          </div>
        </div>
      </div>
      {/* //   id: number;
  //   user_id: number;
  //   voucher_code: string;
  //   sponsor_id: number;
  //   purpose: string;
  //   expiry_date: string;
  //   limit: number;
  //   voucher_amount: string;
  //   amount_per_code: string;
  //   location: string;
  //   type: string;
  //   voucher_status: string;
  //   code_generation_method: string;
  //   deleted_at: string | null;
  //   created_at: string | null;
  //   updated_at: string | null;
  // } */}
      <Modal open={open} onClose={handleClose} title="Voucher Status" overrideStyle={{ height: "auto" }}>
        <>
          <form className="w-full" onSubmit={handleSubmit}>
            <Select
              name="status"
              label="Status"
              data={
                [
                  "",
                  "pending",
                  'approved',
                  'rejected'
                ]
              }
              onChange={handleChange}
              value={updateData.status}
              required
            />
            <Button label="Update Status" type="flat" btnActionType="submit" />
          </form>
        </>
      </Modal>
    </>
  )
}