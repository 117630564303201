import React, { useEffect, useState } from 'react';
import CryptoJS from 'crypto-js';
import {
  useLocation,
  useNavigate,
} from "react-router-dom";
import axios from 'axios';
import ReactGA from 'react-ga4';
import { Routes as Router, Route } from 'react-router-dom';
import './App.scss';
import Login from './pages/Login';
import SignUp from './pages/SignUp';
import ForgotPassword from './pages/ForgotPassword';
import VerifyEmail from './pages/VerifyEmail';
import ResetPassword from './pages/ResetPassword';
import Dashboard from './pages/Dashboard';
import PageLoader from './components/PageLoader';
import Overview from './pages/Overview';
import Bank from './pages/Bank';
import Expenses from './pages/Expenses';
import Profile from './pages/Profile';
import NotFound from './pages/404';
import AzatsListTab from './pages/Expenses/AzatsListTab';
import VerifyOTP from './pages/VerifyOTP';
import FAQs from './pages/FAQs';
import { useAppSelector, useAppDispatch } from './app/hooks';
import { login, User } from './pages/Auth/authSlice';
import Users from './pages/Users';
import Issues from './pages/Issues';
import UserGroups from './pages/UserGroups';
import CategoriesPage from './pages/CategoriesPage';
import Business from './pages/Business';
import BusinessDashboard from './pages/BusinessDashboard';
import Invoice from './pages/BusinessTransactions';
import BusinessOverview from './pages/BusinessOverview';
import Customers from './pages/Customers';
import EmailTemplate from './pages/EmailTemplate';
import BusinessProfile from './pages/BusinessProfile';
import BusinessesProfile from './pages/BusinessesProfile';
import VerificationPendingModal from './components/VerificationPendingModal';
import Ajo from './pages/Ajo';
import toast from 'react-hot-toast';
import Developer from './pages/Developer';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import UserAgreement from './pages/UserAgreement';
import getBaseURL from './utils/getBaseURL';
import { trackPageView } from './utils/tracker';
import NQROptIn from './pages/NQR/OptIn';
import NewLanding from './pages/Landing/NewLanding';
import NewBusinessLanding from './pages/Landing/NewBusinessLanding';
import Catalogues from './pages/Catalogues';
import DashboardHome from './pages/DashboardHome';
import BVNVerificationRedirect from './pages/BVNVerificationRedirect';
import AdminDashboard from './pages/AdminDashboard';
import AdminUsers from './pages/Admin/Users';
import AdminOverview from './pages/Admin/Overview';
import AdminSubscriptions from './pages/Admin/Subscriptions';
import MPOS from './pages/MPOS';
import AdminUserEntities from './pages/Admin/UserEntities';
import AdminCategories from './pages/Admin/Categories';
import AdminInquiries from './pages/Admin/Inquiries';
import AdminRefundMe from './pages/Admin/RefundMe';
import AdminKontribute from './pages/Admin/Kontiribute';
import AdminAjo from './pages/Admin/Ajo';
import AdminFeedback from './pages/Admin/Feedback';
import AdminProfile from './pages/Admin/Profile';
import AdminBusiness from './pages/Admin/Business';
import AdminTransactions from './pages/Admin/Transactions';
import AdminWithdrawals from './pages/Admin/Withdrawals';
import AdminReferrals from './pages/Admin/Referrals';
import Charges from './pages/Admin/Charges';
import ContactUs from './pages/ContactUs';
import Blogs from './pages/Blogs';
import Blog from './pages/Blog';
import RefundMePage from './pages/Landing/RefundMe';
import KontributePage from './pages/Landing/Kontribute';
import Mpos from './pages/Landing/Mpos';
import Invoicing from './pages/Landing/Invoicing';
import SMOTO from './pages/Landing/SMOTO';
import useShowNewFeature from './hooks/useShowNewFeature';
import VoucherIndividual from './pages/Landing/VoucherIndividual';
import VoucherMerchant from './pages/Landing/VoucherMerchant';
import VoucherSponsor from './pages/Landing/VoucherSponsor';
import VoucherIndividualDashboardPage from './pages/Voucher/voucher-pages/Individual/VoucherIndividualDashboardPage';
import IndividualDashboard from './pages/Voucher/voucher-layouts/individual/individualDashboard/IndividualDashboard';
import IndividualAllVouchersPage from './pages/Voucher/voucher-pages/Individual/IndividualAllVouchersPage';
import VoucherDetailsPage from './pages/Voucher/voucher-pages/Individual/VoucherDetailsPage';
import SponsorDashboard from './pages/SponsorDashboard';
import SponsorOverview from './pages/Sponsor/Overview';
import SponsorVouchers from './pages/Sponsor/Vouchers';
import BusinessKYC from './pages/BusinessKYC';
import SponsorKYC from './pages/Sponsor/SponsorKYC';
import KYCManagement from './pages/Admin/KYCs';
import useUserType from './hooks/useUserType';
import { getMerchantKYCData, getSponsorKYCData } from './actions/authActions';
import IndividualVoucherTransactions from './pages/Voucher/voucher-pages/Individual/IndividualVoucherTransactions';
import MerchantDashboard from './pages/Voucher/voucher-layouts/Merchant/MerchantDashboard/MerchantDashboard';
import MerchantHomePage from './pages/Voucher/voucher-pages/Merchant/MerchantHomePage';
import MerchantRedeemedVouchersPage from './pages/Voucher/voucher-pages/Merchant/MerchantRedeemedVouchersPage';
import MerchantReportsPage from './pages/Voucher/voucher-pages/Merchant/MerchantReportsPage';
import SponsorVoucherDetailsPage from './pages/Sponsor/SponsorVoucherDetailsPage';
import SponsorSettings from './pages/Sponsor/SponsorSettings';
import useVerificationDone from './hooks/useVerificationDone';
import SponsorManagement from './pages/Admin/Voucher/SponsorManagement';
import ScreenVouchers from './pages/Admin/Voucher/ScreenVouchers';


function Routes() {
  const { isAuthenticated, user } = useAppSelector((state) => state.auth);
  const [loading, setLoading] = useState(true);
  const dispatch = useAppDispatch();
  const { showVerificationPending } = useAppSelector(state => state.auth);
  const location = useLocation() as any;
  const showNewFeature = useShowNewFeature()

  const routes = [
    {
      path: '/',
      Component: NewLanding,
      protected: false,
    },
    {
      path: '/business_app',
      Component: NewBusinessLanding,
      protected: false,
    },
    {
      path: '/business_app/smoto',
      Component: SMOTO,
      protected: false,
    },
    {
      path: '/business_app/invoicing',
      Component: Invoicing,
      protected: false,
    },
    {
      path: '/business_app/mpos',
      Component: Mpos,
      protected: false,
    },
    {
      path: '/business_app/sponsor_voucher',
      Component: VoucherSponsor,
      protected: false,
    },
    {
      path: '/business_app/merchant_voucher',
      Component: VoucherMerchant,
      protected: false,
    },
    {
      path: '/login',
      Component: Login,
      protected: false,
    },
    {
      path: '/faqs',
      Component: FAQs,
      protected: false,
    },
    {
      path: '/contact',
      Component: ContactUs,
      protected: false,
    },
    {
      path: '/blogs',
      Component: Blogs,
      protected: false,
    },
    {
      path: '/refundme',
      Component: RefundMePage,
      protected: false,
    },
    // {
    //   path: '/ajo',
    //   Component: AjoPage,
    //   protected: false,
    // },
    {
      path: '/open_kontribute',
      Component: KontributePage,
      protected: false,
    },
    {
      path: '/voucher',
      Component: VoucherIndividual,
      protected: false,
    },
    {
      path: '/close_kontribute',
      Component: KontributePage,
      protected: false,
    },
    {
      path: '/blog/:id',
      Component: Blog,
      protected: false,
    },
    {
      path: '/register/:referrerId',
      Component: SignUp,
      protected: false,
    },
    {
      path: '/register',
      Component: SignUp,
      protected: false,
    },
    {
      path: '/forgot-password',
      Component: ForgotPassword,
      protected: false,
    },
    {
      path: '/verify-email',
      Component: VerifyEmail,
      protected: false,
    },
    {
      path: '/developers',
      Component: Developer,
      protected: false,
    },
    {
      path: '/verify',
      Component: VerifyOTP,
      protected: false,
    },
    {
      path: '/reset-password',
      Component: ResetPassword,
      protected: false,
    },
    {
      path: '/terms',
      Component: Terms,
      protected: false,
    },
    {
      path: '/privacy',
      Component: Privacy,
      protected: false,
    },
    {
      path: '/user-agreement',
      Component: UserAgreement,
      protected: false,
    },
    {
      path: '/dashboard/',
      Component: Dashboard,
      protected: true,
      children: [
        {
          path: '/',
          Component: DashboardHome
        },
        {
          path: '/overview',
          Component: Overview
        },
        {
          path: '/bank',
          Component: Bank
        },
        {
          path: '/users',
          Component: Users
        },
        {
          path: '/categories',
          Component: CategoriesPage
        },
        {
          path: '/refundme',
          Component: Expenses,
          children: [
            {
              path: '/',
              Component: AzatsListTab
            },
          ]
        },
        {
          path: '/kontribute',
          Component: UserGroups,
          children: [
            {
              path: '/',
              Component: AzatsListTab
            },
          ]
        },
        {
          path: '/ajo',
          Component: Ajo,
          children: [
            {
              path: '/',
              Component: AzatsListTab
            },
          ],
          isNewFeature: true
        },
        {
          path: '/feedback',
          Component: Issues,
        },
        {
          path: '/profile',
          Component: Profile,
        },
        {
          path: '/profile/bvnverification/redirect',
          Component: BVNVerificationRedirect,
          protected: true,
        },
      ]
    },
    {
      path: '/admin',
      Component: AdminDashboard,
      protected: true,
      children: [
        {
          path: '/',
          Component: DashboardHome
        },
        {
          path: '/charges',
          Component: Charges
        },
        {
          path: '/overview',
          Component: AdminOverview
        },
        {
          path: '/users',
          Component: AdminUsers
        },
        {
          path: '/subscriptions',
          Component: AdminSubscriptions
        },
        {
          path: '/user_entities',
          Component: AdminUserEntities
        },
        {
          path: '/categories',
          Component: AdminCategories
        },
        {
          path: '/inquiries',
          Component: AdminInquiries
        },
        {
          path: '/refundme',
          Component: AdminRefundMe,
        },
        {
          path: '/kontribute',
          Component: AdminKontribute,
        },
        {
          path: '/ajo',
          Component: AdminAjo,
        },
        {
          path: '/feedback',
          Component: AdminFeedback,
        },
        {
          path: '/profile',
          Component: AdminProfile,
        },
        {
          path: '/business',
          Component: AdminBusiness,
        },
        {
          path: '/transactions',
          Component: AdminTransactions,
        },
        {
          path: '/withdrawals',
          Component: AdminWithdrawals,
        },
        {
          path: '/referrals',
          Component: AdminReferrals
        },
        {
          path: '/subscriptions',
          Component: AdminSubscriptions,
          protected: true,
        },
        {
          path: '/kyc_management',
          Component: KYCManagement,
          protected: true,
        },
        {
          path: '/sponsor_management',
          Component: SponsorManagement,
          protected: true,
        },
        {
          path: '/screen_vouchers',
          Component: ScreenVouchers,
          protected: true,
        },
      ]
    },
    {
      path: '/business',
      Component: Business,
      protected: true,
      children: [
        {
          path: '/',
          Component: BusinessesProfile
        },
        {
          path: '/bank',
          Component: Bank
        },
        {
          path: '/kyc',
          Component: BusinessKYC
        },
        {
          path: '/profile',
          Component: Profile,
        },
      ]
    },
    {
      path: '/business/dashboard/:id/',
      Component: BusinessDashboard,
      protected: true,
      children: [
        {
          path: '/',
          Component: BusinessOverview
        },
        {
          path: '/transactions',
          Component: Invoice
        },
        {
          path: '/invoices',
          Component: Invoice
        },
        {
          path: '/directlinks',
          Component: Invoice
        },
        {
          path: '/inventory',
          Component: Catalogues
        },
        {
          path: '/nqr',
          Component: NQROptIn
        },
        {
          path: '/customers',
          Component: Customers
        },
        {
          path: '/email_template',
          Component: EmailTemplate
        },
        {
          path: 'profile',
          Component: BusinessProfile,
        },
        {
          path: 'mpos',
          Component: MPOS,
        },
      ]
    },
    {
      path: '/merchant/:id/',
      Component: MerchantDashboard,
      protected: true,
      children: [
        {
          path: "/",
          Component: MerchantHomePage
        },
        {
          path: "/vouchers",
          Component: MerchantRedeemedVouchersPage
        },
        {
          path: '/vouchers/:voucherCode',
          Component: VoucherDetailsPage,
        },
        {
          path: "/reports",
          Component: MerchantReportsPage
        },
      ],
    },
    {
      path: '/sponsor/',
      Component: SponsorDashboard,
      protected: true,
      children: [
        {
          path: '/dashboard',
          Component: SponsorOverview
        },
        {
          path: '/vouchers',
          Component: SponsorVouchers
        },
        {
          path: '/vouchers/:voucherCode',
          Component: SponsorVoucherDetailsPage
        },
        {
          path: '/kyc',
          Component: SponsorKYC
        },
        {
          path: '/settings',
          Component: SponsorSettings
        },
      ],
    },
    {
      path: '/voucher',
      protected: true,
      Component: IndividualDashboard,
      children: [
        {
          path: '/',
          Component: VoucherIndividualDashboardPage,
        },
        {
          path: '/all-vouchers',
          Component: IndividualAllVouchersPage,
        },
        {
          path: '/all-vouchers/:voucherCode',
          Component: VoucherDetailsPage,
        },
        {
          path: '/transactions',
          Component: IndividualVoucherTransactions,
        },
      ],
    },
  ];

  useEffect(() => {
    if (process.env.NODE_ENV !== 'development') {
      if (isAuthenticated && user) {
        ReactGA.initialize(
          process.env.REACT_APP_GA_ID as string,
          {
            gaOptions: {
              userId: user.id?.toString() || user.email,
            }
          }
        );
      } else {
        ReactGA.initialize(process.env.REACT_APP_GA_ID as string);
      }
    }
  }, [isAuthenticated, user]);

  useEffect(() => {
    let token = localStorage.getItem('tazat');
    const getProfile = async () => {
      const url = getBaseURL() as string;
      const secretKey = process.env.REACT_APP_TAZAT_SECRET;
      token = CryptoJS.AES.decrypt(token, secretKey).toString(CryptoJS.enc.Utf8);
      if (token) {
        try {
          axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
          const profile = await axios.get(
            `${url}getProfile`,
            {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
              }
            }
          );
          if (profile && profile.data) {
            dispatch(login(profile.data[0] as unknown as User));
            setLoading(false)
          }
        } catch (error: unknown) {
          if (error instanceof Error) {
            if (error?.message === 'Network Error') {
              toast.error('Network Error, check your internet and refresh the page.')
            }
          }
        }
      }
    };
    if (token) {
      getProfile();
    } else {
      setLoading(false)
    }
  }, [dispatch]);

  useEffect(() => {
    trackPageView(location.pathname);
  }, [location.pathname]);

  if (loading) {
    return <PageLoader />
  }

  return (
    <div className="App">
      <Router>
        {
          routes.map((route) => {
            // @ts-ignore
            if ((route.isNewFeature && showNewFeature) || !route.isNewFeature) {
              return (
                <Route
                  key={route.path}
                  path={route.path}
                  element={
                    <AuthGuard
                      isProtected={route.protected}
                      route={route.path}
                    >
                      <route.Component />
                    </AuthGuard>
                  }
                >
                  {
                    route.children && route.children.map((childRoute, index) => {
                      // @ts-ignore
                      if ((childRoute.isNewFeature && showNewFeature) || !childRoute.isNewFeature) {
                        if (index === 0) {
                          return (
                            <Route
                              index
                              key={childRoute.path}
                              element={<childRoute.Component />}
                            />
                          )
                        }
                        return (
                          <Route
                            key={childRoute.path}
                            path={`${route.path}/${childRoute.path}`}
                            element={<childRoute.Component />}
                          />
                        )
                      }
                    })
                  }
                </Route>
              )
            }
          })
        }
        <Route path='*' element={<NotFound />} />
      </Router>
      <VerificationPendingModal
        isOpen={showVerificationPending}
      />
    </div>
  );
}

export default Routes;

const AuthGuard = ({ isProtected, children, route }: { isProtected: boolean, children: React.ReactElement, route: string }) => {
  const { isAuthenticated, user, merchantSponsorKYC } = useAppSelector((state) => state.auth);
  const { isMerchant, isSponsor } = useUserType()
  const navigate = useNavigate();
  const userType = user?.usertype;
  const isBusinessRoute = route.split('/')[1] === 'business' || route.split('/')[1] === 'merchant';
  const isSponsorRoute = route.split('/')[1] === 'sponsor';
  const isPersonalRoute = route.split('/')[1] === 'dashboard' || route.split('/')[1] === 'voucher';
  const isAdminRoute = route.split('/')[1] === 'admin';
  const { isVerificationDone } = useVerificationDone()

  useEffect(() => {
    if (isProtected && !isAuthenticated) {
      return navigate("/login");
    }
    if (userType === 'admin' && !isAdminRoute) {
      return navigate("/admin");
    }
    if (userType !== 'admin') {
      if (
        (isPersonalRoute && userType === 'merchant') ||
        (isSponsorRoute && userType === 'merchant') ||
        (isAdminRoute && userType === 'merchant')) {
        return navigate("/business");
      }
      if ((isPersonalRoute && userType === 'sponsor') ||
        (isBusinessRoute && userType === 'sponsor') ||
        (isAdminRoute && userType === 'sponsor')) {
        return navigate("/sponsor");
      }
      if ((isSponsorRoute && userType === 'user') ||
        (isBusinessRoute && userType === 'user') ||
        (isAdminRoute && userType === 'user') ||
        (!isProtected && isAuthenticated)) {
        return navigate("/dashboard/");
      }
    }
  }, [isProtected, isAuthenticated, navigate, userType, isBusinessRoute, isPersonalRoute, isSponsorRoute, isAdminRoute]);

  useEffect(() => {
    if (!merchantSponsorKYC) {
      if (isMerchant && isBusinessRoute) {
        getMerchantKYCData()
      } else if (isSponsor && isSponsorRoute) {
        getSponsorKYCData()
      }
    }
  }, [user, navigate])

  useEffect(() => {
    if (isBusinessRoute || isSponsorRoute) {
      const fetchKycStatus = () => {
        return (isMerchant && isBusinessRoute) ?
          getMerchantKYCData() :
          (isSponsor && isSponsorRoute) ?
            getSponsorKYCData() :
            null
      }
      const interval = setInterval(fetchKycStatus, 30000);
      if (isVerificationDone) {
        clearInterval(interval);
      }
      // Cleanup on unmount
      return () => clearInterval(interval);
    }
  }, [isVerificationDone])

  return children
}