import { Outlet } from "react-router-dom";
import "./IndividualDashboard.scss"
import DashboardSideBar from "../../../../../components/DashboardSideBar";
import DashboardSVG from "../../../../../components/SVG/Dashboard";
import VoucherSVG from "../../../../../components/SVG/Voucher";
import HomeSVG from "../../../../../components/SVG/Home";

export default function IndividualDashboard() {
  const navItems = [
    {
      name: "home",
      path: `/dashboard/`,
      title: "Home",
      Icon: HomeSVG,
    },
    {
      name: "dashboard",
      path: `/voucher/`,
      title: "Voucher Dashboard",
      Icon: DashboardSVG,
      isFirst: true
    },
    {
      name: "all-vouchers",
      path: `/voucher/all-vouchers`,
      title: "All Vouchers",
      Icon: VoucherSVG,
    },
    // {
    //   name: "transactions",
    //   path: `/voucher/transactions`,
    //   title: "Transactions",
    //   Icon: TransactionsSVG,
    // },
    // {
    //   name: "reports",
    //   path: `#`,
    //   title: "Reports",
    //   Icon: ReportsSVG,
    // },
  ]
  // const downNavItems = [
  //   {
  //     name: "settings",
  //     path: `#`,
  //     title: "Settings",
  //     Icon: SettingsSVG,
  //   },
  // ]

  return (
    <div className="voucherDashboard flex flex-row justify-between items-start !relative">
      <DashboardSideBar
        userType="individual"
        items={navItems}
        firstItem={"Dashboard"}
        voucherSideBar={true}
        // downItemes={downNavItems}
        showBusinessSwitch={false}
        showVerificationStatus={false}
      />
      <div className="outlet flex flex-auto flex-grow">
        <Outlet />
      </div>
    </div>
  )
}