import { useEffect, useState } from "react";
import { getBVNLink, updateUserCall, updateUserImage } from "../../actions/authActions";
import EditIcon from '../../assets/edit.png';
import SaveIcon from '../../assets/saveWhite.png';
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import Button from "../../components/Button";
import DashboardComponentWrapper from "../../components/DashboardComponentWrapper";
import Input, { Select } from "../../components/Input";
import { country, states, lgas } from "../../utils/data";

import './profile.scss';
import TabComponent from "../../components/TabComponent";
import toast from "react-hot-toast";
import useVerificationDone from "../../hooks/useVerificationDone";
import Modal from "../../components/Modal";
import { ReferrerComponent } from "../Referrer";

export const ProfileDetails = () => {
  const {isVerificationDone} = useVerificationDone();
  const dispatch = useAppDispatch();
  const [ninError, setNINError] = useState(false);
  const [bvnError, setBVNError] = useState(false);
  const profile = useAppSelector((state) => state.auth.user);
  const [isEditMode, setEditMode] = useState(true);
  const [confirmSubmit, setConfirmSubmit] = useState(false);
  const [data, setData] = useState({
    email: '',
    name: '',
    phone: '',
    first_name: '',
    last_name: '',
    middle_name: '',
    country: '',
    state: '',
    address: '',
    image: '',
    nimc: '',
    bvn: '',
    usertype: 'developer',
    imageData: '',
    lga_of_origin: '',
    dob: '',
    age: '',
    gender: '',
    maiden: '',
  });
  const handeleGetConsentUrl = async () => {
    getBVNLink((link) => {
      const bvnLink = link?.data.responseData?.data?.url;
      bvnLink && window.open(bvnLink, '_self');
      if (!bvnLink) {
        toast.error('Verification link generation failed!');
      }
    });
  }

  useEffect(() => {
    if (profile) {
       // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setData({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        email: profile.email,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        phone: profile.phone,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        address: profile.address,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        first_name: profile.first_name,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        last_name: profile.last_name,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        country: profile.country,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        bvn: profile.bvn,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        nimc: profile.nimc,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        state: profile.state,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        image: profile.image,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        name: profile.name,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        middle_name: profile.middle_name,
        // @ts-ignore
        gender: profile.gender,
        // @ts-ignore
        lga_of_origin: profile.lga_of_origin,
        // @ts-ignore
        dob: profile.dob || profile.DOB,
        // @ts-ignore
        maiden: profile.maiden,
      });
    }
  }, [profile]);

  const handleChange = (name: string, value: string) => {
    setData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  }

  const profileUpdated = () => {
    return profile?.email && profile?.first_name && profile?.last_name && 
      // @ts-ignore
      profile?.country && profile?.state && profile?.bvn && profile?.middle_name && profile?.address;
  }

  const toggleConfirmSubmit = (event = null) => {
    // @ts-ignore
    event && event.preventDefault();
    setConfirmSubmit(!confirmSubmit);
  }

  const handleSubmit = () => {
    if (!data.country) {
      toast.error('You must choose your country');
      return;
    }
    if (!data.state) {
      toast.error('You must choose your state');
      return;
    }
    updateUserCall({...data, dob: data?.dob, maiden_name: data.maiden}, dispatch, () => {
      // setEditMode(false);
      setConfirmSubmit(false);
    })
  }

  const handleImageChange = ({target}) => {
    if (target.files[0].size > 500000) {
      toast.error('Logo must be less than 500kb');
      return;
    }
    handleChange('image', URL.createObjectURL(target.files[0]));
    handleChange('imageData', target.files[0]);
  }

  const handleSaveImg = () => {
    const profileData = new FormData();
    profileData.append('image', data.imageData);
    updateUserImage(profileData, dispatch, {
      email: data.email,
      phone: data.phone,
      address: data.address,
      first_name: data.first_name,
      last_name: data.last_name,
      country: data.country,
      bvn: data.bvn,
      nimc: data.nimc,
      state: data.state,
      name: data.name,
      middle_name: data.middle_name,
      maiden_name: data.maiden,
      dob: data.dob,
    },() => {
      setData(prevState => ({
        ...prevState,
        imageData: '',
      }));
    })
  }

  return (
    
      <div className="profile-wrapper relative">
        {
          // isVerificationDone ? (
            <div className="profile-container mt-8">
              {
                isEditMode ? (
                  <div className=" !w-full  mt-8 flex flex-row flex-wrap justify-start items-start">
                    <div className="flex flex-col w-full lg:w-[200px] justify-center items-center">
                      <div className="img-wrapper !relative">
                        {
                          data.image ? <img className="profile-img" src={data.image} alt="" /> : (
                            <span className="img-placeholder">
                              {data.name && data.name[0]}
                            </span>
                          )
                        }
                        {
                          data.imageData ? (
                            <div className="" onClick={handleSaveImg}>
                              <button className="bg-az-teal text-white h-10">Save Profile</button>
                            </div>
                          ) : (
                            <label htmlFor="image" className="flex flex-col">
                              <span className="text-sm font-medium">Upload your Profile</span>
                              <p className="bg-az-teal text-white h-10 px-4 py-4 flex my-2 flex-row justify-center items-center">Edit Profile</p>
                              <input className="hidden" type="file" name="image" accept="image/*" id="image" onChange={handleImageChange} />
                            </label>
                          )
                        }
                      </div>
                    </div>
                    {/* @ts-ignore */}
                    <form className="profile-form w-full max-w-[700px] !mt-0 !pt-0 !px-10 flex flex-col flex-auto flex-grow !bg-transparent" onSubmit={toggleConfirmSubmit}>
                      {
                        !profileUpdated() && !isVerificationDone ? (
                          <>
                            <p className="w-full text-start">Update your profile to begin verification.</p>
                            <span className="w-full text-start text-xs">Please enter your details as used officially.</span>
                          </>
                        ) : isVerificationDone ? null : <Button
                              label="Verify BVN"
                              onClick={() => handeleGetConsentUrl()}
                              type="flat"
                              btnActionType="button"
                              overrideStyle={{
                                width: '200px',
                              }}
                            />
                      }
                      <div className="input-grp-wrapper">
                        <Input
                          label="Firstname"
                          placeholder="Firstname"
                          name="first_name"
                          required
                          value={data.first_name}
                          onChange={handleChange}
                        />
                        <Input
                          required
                          label="Lastname"
                          placeholder="Lastname"
                          name="last_name"
                          value={data.last_name}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="input-grp-wrapper">
                        <Input
                          label="Middlename"
                          placeholder="Middlename"
                          name="middle_name"
                          value={data.middle_name}
                          onChange={handleChange}
                        />
                        <Input
                          label="Maiden name"
                          placeholder="Adeagbo"
                          name="maiden"
                          value={data.maiden}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="w-full">
                        <Input
                          label="Address"
                          placeholder="6 Sibiate Cement, Agege"
                          name="address"
                          value={data.address}
                          required
                          onChange={handleChange}
                        />
                      </div>
                      <div className="input-grp-wrapper">
                        <Input
                          label="Email"
                          placeholder="Middlename"
                          name="email"
                          value={data.email}
                          readonly
                          onChange={handleChange}
                        />
                        <Select
                          data={['Male', 'Female']}
                          label="Gender"
                          placeholder="Gender"
                          name="gender"
                          value={data.gender}
                          required
                          onChange={handleChange}
                        />
                      </div>
                      <div className="input-grp-wrapper">
                        <Input
                          label="Bank verification Number (BVN)"
                          placeholder=""
                          name="bvn"
                          value={data.bvn}
                          pattern={'([0-9]){11}'}
                          required
                          onChange={(name:string, value:string) => {
                            handleChange(name, value);
                            setBVNError(!(/^([0-9]){11}$/.test(value)))
                            setBVNError(!(/^([0-9]){11}$/.test(value)));
                          }}
                          onBlur={() => {
                            setBVNError(!(/^([0-9]){11}$/.test(data.bvn)));
                          }}
                          hasError={bvnError}
                          errorText='Please enter your 11 digit BVN!'
                          
                        />
                        <Input
                          label="National Identity Number (NIN)"
                          placeholder=""
                          name="nimc"
                          value={data.nimc}
                          required
                          pattern={'([0-9]){11}'}
                          onChange={(name:string, value:string) => {
                            handleChange(name, value);
                            setNINError(!(/^([0-9]){11}$/.test(value)));
                          }}
                          onBlur={() => {
                            setNINError(!(/^([0-9]){11}$/.test(data.nimc)));
                          }}
                          hasError={ninError}
                          errorText='Please enter your 11 digit NIN!'
                        />
                      </div>
                      <div className="input-grp-wrapper">
                        <Input
                          type="date"
                          label="Date of birth"
                          placeholder=""
                          name="dob"
                          value={data.dob}
                          required
                          pattern={'([0-9]){11}'}
                          onChange={(name:string, value:string) => {
                            handleChange(name, value);
                          }}
                          hasError={ninError}
                          errorText='Please enter your date of birth'
                        />
                        <Select
                          data={country}
                          label="Country"
                          placeholder="Country"
                          name="country"
                          value={data.country}
                          required
                          onChange={handleChange}
                        />
                      </div>
                      <div className="input-grp-wrapper">
                        <Select
                          data={states}
                          label="State"
                          placeholder="State"
                          name="state"
                          value={data.state}
                          required
                          onChange={handleChange}
                        />
                        <Select
                          data={(lgas[((data.state || '').toLowerCase())])?.lgas || []}
                          label="LGA"
                          placeholder="LGA"
                          name="lga_of_origin"
                          value={data.lga_of_origin}
                          required
                          onChange={handleChange}
                        />
                      </div>
                      <div className="input-grp-wrapper profile-btn-wrapper">
                        {/* <Button
                          overrideStyle={{
                            width: '45%',
                          }}
                          label="Cancel"
                          onClick={() => setEditMode(false)}
                          type="contained"
                          btnActionType="button"
                        /> */}
                        <Button
                          label="Save"
                          onClick={() => null}
                          type="flat"
                          btnActionType="submit"
                          overrideStyle={{
                            width: '45%',
                          }}
                        />
                      </div>
                    </form>
                  </div>
                ) : (
                  <div className="profile-card">
                    <div className="details-left">
                      <div className="img-wrapper">
                        {
                          data.image ? <img className="profile-img" src={data.image} alt="" /> : (
                            <span className="img-placeholder">
                              {data.name && data.name[0]}
                            </span>
                          )
                        }
                        {
                          data.imageData ? (
                            <div className="file-upload" onClick={handleSaveImg}>
                              <img src={SaveIcon} alt="" />
                            </div>
                          ) : (
                            <label htmlFor="image" className="file-upload">
                              <img src={EditIcon} alt="" />
                              <input type="file" name="image" id="image" onChange={handleImageChange} />
                            </label>
                          )
                        }
                      </div>
                      <div>
                        <b className="detail-username">{data.name}</b>
                        {/* <span className="detail-email">{data.phone}</span> */}
                        <span className="detail-email">{data.email}</span>
                      </div>
                    </div>
                    <div className="details-right">
                      <div className="detail">
                        <span className="detail-title">Fullname</span>
                        <span className="detail-value">{data.first_name} {data.middle_name} {data.last_name}</span>
                      </div>
                      {
                        data.maiden && (
                          <div className="detail">
                            <span className="detail-title">Maiden Name</span>
                            <span className="detail-value">{data.maiden}</span>
                          </div>
                        )
                      }
                      <div className="detail">
                        <span className="detail-title">Address</span>
                        <span className="detail-value">{data.address}</span>
                      </div>
                      <div className="detail">
                        <span className="detail-title">State</span>
                        <span className="detail-value">{data.state}</span>
                      </div>
                      <div className="detail">
                        <span className="detail-title">Country</span>
                        <span className="detail-value">{data.country}</span>
                      </div>
                      <div className="detail">
                        <span className="detail-title">Bank verification Number (BVN)</span>
                        <span className="detail-value">{data.bvn}</span>
                      </div>
                      <div className="detail">
                        <span className="detail-title">National Identity Number (NIN)</span>
                        <span className="detail-value">{data.nimc}</span>
                      </div>
                      <Button
                        overrideStyle={{width: '100px'}}
                        type="flat"
                        onClick={() => setEditMode(true)}
                        label="Edit"
                      />
                    </div>
                  </div>
                )
              }
            </div>
          // ) : (
          //   <BVNVerification />
          // )
        }
        <Modal
          open={confirmSubmit}
          onClose={toggleConfirmSubmit}
          overrideStyle={{
            maxWidth: '400px',
            width: '100%',
            height: 'max-content',
            maxHeight: '70%',
            border: '1px solid red',
          }}
          title="Confirm data."
        >
          <div className="flex flex-col">
            <div className="my-4">Please confirm, the provided information is as contained in your official documents. According to CBN policies a deviation would prevent your usage of this application.</div>
            { !data.bvn && <div className="pb-8" />}
            {
              data.bvn && (
                <div className="flex flex-row w-full justify-between items-center">
                  <Button
                    overrideStyle={{width: '100px'}}
                    type="contained"
                    onClick={toggleConfirmSubmit}
                    label="Cancel"
                  />
                  <Button
                    overrideStyle={{width: '100px'}}
                    type="flat"
                    onClick={handleSubmit}
                    label="Confirm"
                  />
                </div>
              )
            }
          </div>
        </Modal>
      </div>
  );
};

const Profile = () => {
  return (
    <DashboardComponentWrapper title="Profile" pageDetails="You can update your profile information here">
      <TabComponent
        components={{
          Profile: ProfileDetails,
          Referrer: ReferrerComponent,
        }}
      />
    </DashboardComponentWrapper>
  );
};

export default Profile;
