import { useParams } from 'react-router-dom'
import { useAppSelector } from '../../../app/hooks'
import { formatCurrency } from '../../../utils/common'
import MerchantDetailsListComponent from './MerchantDetailsListComponent'
import VoucherDetailsComponsnt from './VoucherDetailsComponsnt'
import { useEffect, useState } from 'react'
import { getVoucherDetaildByCode } from '../../../actions/vouchersActions'
import dayjs from 'dayjs'
import CopyData from '../../../components/CopyData'

export default function VoucherDetails() {
  const [formattedVoucherDetails, setFormattedVoucherDetails] = useState<Array<Record<string, any>> | null>(null)

  const { singleVoucher } = useAppSelector(state => state.voucher)
  const params = useParams()

  const voucherCode = params?.voucherCode

  useEffect(() => {
    if (!voucherCode) return;
    getVoucherDetaildByCode({
      voucherCode, isSilent: false
    })
  }, [voucherCode])

  useEffect(() => {
    setFormattedVoucherDetails(
      [
        {
          title: "Voucher amount",
          data: formatCurrency({ num: singleVoucher?.voucher_amount })
        },
        {
          title: "amount per code",
          data: formatCurrency({ num: singleVoucher?.amount_per_code })
        },
        {
          title: "PURPOSE",
          data: singleVoucher?.purpose
        },
        {
          title: "LIMIT",
          data: singleVoucher?.limit
        },
        {
          title: "type",
          data: singleVoucher?.type
        },
        {
          title: "CREATED DATE",
          data: dayjs(singleVoucher?.created_at).format("DD MMMM, YYYY")
        },
        {
          title: "EXPIRY DATE",
          data: dayjs(singleVoucher?.expiry_date).format("DD MMMM, YYYY")
        },
        {
          title: "VOUCHER CODE",
          data: <div className="relative flex gap-2">{singleVoucher?.voucher_code} <CopyData data={singleVoucher?.voucher_code as string} /></div>
        },
        {
          title: "SPONSOR ID",
          data: singleVoucher?.user_id
        },
        {
          title: "location",
          data: singleVoucher?.location
        },
        {
          title: "Code generation method",
          data: singleVoucher?.code_generation_method
        },
      ])
  }, [singleVoucher])

  return (
    <div className="w-full flex flex-col gap-[20px]">
      <VoucherDetailsComponsnt voucherDetails={singleVoucher} voucher={formattedVoucherDetails} />
      <MerchantDetailsListComponent voucherCode={singleVoucher?.voucher_code as string} />
    </div>
  )
}
