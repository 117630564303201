import { useNavigate, useLocation } from 'react-router-dom';
import Logo from '../../assets/Logo.svg';
import SettingsSVG from '../SVG/Settings';
import { handleLogout, handleSideNavToggle } from '../../actions/dashboardActions';
import './dashboardSideBar.scss';
import { useAppSelector } from '../../app/hooks';
import { useEffect, useState } from 'react';
import { appDispatch } from '../../app/store';
import { toggleSideNav } from '../../pages/Dashboard/dashboardSlice';
import { SwitchMode } from '../DashboardComponentWrapper';
import useVerificationDone from '../../hooks/useVerificationDone';
import useIsAdmin from '../../hooks/useIsAdmin';
import useShowNewFeature from '../../hooks/useShowNewFeature';
import LogoutSVG from '../../components/SVG/LogoutSVG';
import useUserType from '../../hooks/useUserType';
import useUserData from '../../hooks/useUserData';

interface IItems {
  name: string,
  path: string,
  title: string,
  Icon?: ({ color }: { color?: string }) => JSX.Element,
  img?: any,
  isNewFeature?: boolean,
  isFirst?: boolean
}

interface IDashboardSidebar {
  userType?: string,
  items: Array<IItems>,
  downItemes?: Array<IItems>,
  firstItem: string,
  showVerificationStatus?: boolean,
  showKYCVerificationStatus?: boolean,
  showBusinessSwitch?: boolean
  voucherSideBar?: boolean
}

const DashboardSideBar = (
  { userType, items, firstItem, showVerificationStatus = true, showKYCVerificationStatus = false, showBusinessSwitch = true, downItemes, voucherSideBar = false }: IDashboardSidebar
) => {
  const showFeature = useShowNewFeature();
  const isAdmin = useIsAdmin();
  const { showNav } = useAppSelector((state) => state.dashboard);
  const { abbr, name, userDetails } = useUserData()
  const { isVerificationDone } = useVerificationDone();
  const navigate = useNavigate();
  const { pathname } = useLocation()
  const location = pathname.split("/");
  const [showToggleInfo, setShowToggleInfo] = useState<boolean>(false);
  const { isMerchant, isSponsor, isIndividual } = useUserType()

  const currentPath = location.at(-1)
  const currentBasePath = location.at(-2)

  useEffect(() => {
    const showInfo = localStorage.getItem('toggle-info');
    if (!showInfo) {
      setShowToggleInfo(true);
      localStorage.setItem('toggle-info', 'true');
    }
  }, []);


  const handleVerify = () => {
    if (isIndividual) {
      navigate('/dashboard/profile');
    } else if (isMerchant) {
      navigate('/business/kyc');
    } else if (isSponsor) {
      navigate('/sponsor/kyc');
    }
  }

  return (
    <>
      <div className={`side-nav w-[85vw] lg:w-[250px] xl:w-[300px] shrink-0 fixed lg:sticky top-0 left-0 bg-white overflow-auto !p-0 ${showNav ? 'show-nav' : 'hide-nav'}`}>
        <div>
          <div className="w-[100%] bg-white min-h-[80px] flex items-start lg:items-center justify-between lg:justify-center gap-2 px-[20px]  shadow-sm sticky top-0 py-5 lg:py-0 mb-3">
            <div className={`${voucherSideBar ? "hidden lg:flex" : "flex"} w-full justify-center items-center gap-[20px]`}>
              <img src={Logo} alt="" className='h-14 object-contain' />
              {
                userType && (
                  <span
                    className="px-[10px] py-[5px] rounded-md bg-[#0898A00D] text-[12px] font-Onest text-[#0898A0]"
                  >{userType}</span>
                )
              }
            </div>

            {
              voucherSideBar &&
              (
                <div className="flex lg:hidden flex-col justify-start items-start gap-[10px]">
                  <div className="profile-pic w-[50px] h-[50px] rounded-full flex justify-center items-center bg-[#0898A0] text-[16px] font-[500] text-[white]">
                    {abbr}
                  </div>
                  <div className="flex flex-col gap-1 text-left h-auto">
                    <h4 className="text-[16px] font-semibold leading-[100%]">{name}</h4>
                    <p className="text-[14px] font-light leading-[100%] text-[#0898A0]">{userDetails?.email}</p>
                  </div>
                </div>
              )
            }

            <svg
              onClick={() => {
                handleSideNavToggle(!showNav);
                !localStorage.getItem('toggle-info') && localStorage.setItem('toggle-info', 'true');
              }}
              xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"
              className='flex lg:hidden cursor-pointer'>
              <path d="M15.787 14.7579L9.02831 7.99554L15.787 1.23316C16.0593 0.951224 16.0593 0.504153 15.787 0.222124C15.508 -0.0669215 15.0476 -0.074916 14.7587 0.20427L7.99997 6.96665L1.24132 0.204359C0.959535 -0.0679875 0.512705 -0.0679875 0.230828 0.204359C-0.0580623 0.483545 -0.0660523 0.944206 0.212983 1.23325L6.97163 7.99554L0.212983 14.7578C0.0766172 14.8943 1.81872e-08 15.0793 1.81872e-08 15.2722C-8.88562e-05 15.6741 0.325557 15.9999 0.727198 16C0.920117 16.0002 1.10513 15.9234 1.24132 15.7867L7.99997 9.02443L14.7587 15.7868C14.8949 15.9235 15.08 16.0003 15.2729 16C15.4657 15.9999 15.6505 15.9233 15.7869 15.787C16.071 15.5028 16.0711 15.0421 15.787 14.7579Z" fill="#222222B2" />
            </svg>
          </div>

          <ul className='[&>*:nth-child(2)]:mb-4 [&>*:nth-child(2)]:border-b-2 [&>*:nth-child(2)]:border-[#22222226]'>
            {
              items.map((navItem) => {
                const Icon = navItem.Icon;
                if (navItem.isNewFeature && showFeature || !navItem.isNewFeature) {
                  return (
                    <li
                      key={navItem.name}
                      onClick={() => {
                        navigate(navItem.path);
                        if (window.innerWidth < 780) {
                          appDispatch(toggleSideNav(false));
                        }
                      }}
                      // ${(navItem.title === 'Home' && location[2] === '') ||
                      //   location[2] === navItem.name ||
                      //   (navItem.title === firstItem && location.length === 2) ||
                      //   (navItem.title === firstItem && location.length === 4) ||
                      //   (location[4] && location[4] === navItem.name)
                      //   ? "selected"
                      //   : ""
                      // }
                      className={`
                    flex flex-row px-4 py-4 justify-start items-center my-3 cursor-pointer
                    ${
                      currentPath === navItem.name ||
                      currentBasePath === navItem.name ||
                          (currentPath === "" && navItem.isFirst) ?
                          "selected" : ""}
                        `}
                    >
                      {/* @ts-ignore */}
                      {navItem.Icon ? <Icon /> : <img src={navItem.img} alt="" className='mr-4 fill-black' />}
                      <span className='text-sm text-[#ffffffcf]'>{navItem.title}</span>
                    </li>
                  )
                }
                return null;
              })
            }
          </ul>
        </div>

        <div className="s-openner border-[#22222226] border-t w-[80%] self-center mb-5 mt-3 py-3 flex flex-col gap-2">
          {downItemes && downItemes.length > 0 &&
            (
              <ul className=''>
                {(downItemes.map((downItem) => {
                  const Icon = downItem.Icon;
                  if (downItem.isNewFeature && showFeature || !downItem.isNewFeature) {
                    return (
                      <li
                        key={downItem.name}
                        onClick={() => {
                          navigate(downItem.path);
                          if (window.innerWidth < 780) {
                            appDispatch(toggleSideNav(false));
                          }
                        }}
                        className={`
                    !w-[100%] flex flex-row px-4 py-4 justify-start items-center cursor-pointer
                    ${location[2] === downItem.name ||
                            (location[4] && location[4] === downItem.name)
                            ? "selected"
                            : ""
                          }`}
                      >
                        {/* @ts-ignore */}
                        {downItem.Icon ? <Icon /> : <img src={downItem.img} alt="" className='mr-4 fill-black' />}
                        <span className='text-sm text-[#ffffffcf]'>{downItem.title}</span>
                      </li>
                    )
                  }
                  return null;
                }))}
              </ul>
            )
          }
          {
            (!isAdmin && showVerificationStatus)
            && (
              <div className='w-full h-10 py-4 flex flex-row justify-center items-center text-md'>
                {
                  isVerificationDone ?
                    <p className='py-2 w-full rounded-md text-white bg-green-700 text-center'>Verified</p> :
                    <p onClick={handleVerify} className='py-3 cursor-pointer rounded-md w-full text-white bg-orange-700 text-center'>
                      Pending verification
                    </p>
                }
              </div>
            )
          }
          {
            (!isAdmin && showKYCVerificationStatus)
            && (
              <div className='w-full h-10 py-4 flex flex-row justify-center items-center text-md'>
                {
                  isVerificationDone ?
                    <p className='py-2 w-full rounded-md text-white bg-green-700 text-center'> {userType} KYC Verified</p> :
                    <p onClick={handleVerify} className='py-3 cursor-pointer rounded-md w-full text-white bg-orange-700 text-center'>
                      {userType} KYC Unverified
                    </p>
                }
              </div>
            )
          }
          {
            (showFeature && !isAdmin && showBusinessSwitch)
            && (
              <div className='w-full h-10 px-4 !text-white flex justify-start items-center'>
                <SwitchMode />
              </div>
            )
          }

          <div className={`!w-[100%] flex flex-row gap-3 px-4 py-4 justify-start items-center m-0 cursor-pointer hover:!bg-[#F9F9F9]`}
            onClick={() => handleLogout(navigate)}
          >
            <LogoutSVG />
            <span className="text-sm !text-[red]">Log out</span>
          </div>
        </div>

      </div >
      <div
        className={`${showNav ? "block" : "hidden"} lg:hidden w-full h-[100vh] fixed top-0 right-0 bg-[#0000008a] z-[8] `}
        onClick={() => {
          handleSideNavToggle(false);
        }} />
    </>
  );
}

export default DashboardSideBar;
