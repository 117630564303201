
import filterIcon from "../voucher-assets/icons/filter-icon.svg"
import filterFilledIcon from "../voucher-assets/icons/filter-filled-icon.svg"
import Button from "../../../components/Button"

export default function FilterVouchersBtn({ onClick }: { onClick?: () => void }) {
  return (
    <>
      <Button
        type='flat'
        label='Filter by'
        className='!hidden md:!flex !text-[#0898A0] !text-[13px] !bg-[#0898A01A] !rounded-full !m-0 !w-auto !py-5 !px-[25px]'
        leftIcon={<img src={filterIcon} />}
        onClick={onClick}
      />
      <div
        onClick={onClick}
        className="flex md:hidden justify-center items-center !text-[#0898A0] !text-[13px] !bg-[#0898A01A] !rounded-lg !m-0 !w-auto !p-[15px]">
        <img src={filterFilledIcon} />
      </div>
    </>
  )
}
