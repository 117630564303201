import VoucherStatusTag from './VoucherStatusTag'
import editIcon from "../../../assets/new/Edit.svg"
import Button from '../../../components/Button'
import { isVoucherExpired } from '../../../utils/voucherUtilFuncs'

function VoucherDetailsComponsnt({ voucherDetails, voucher, editable = false, onEditClick }:
  { voucher: any, editable?: boolean, onEditClick?: () => void, voucherDetails: any }) {
  return (
    <div className='w-[100%] rounded-md bg-[#FFFFFF]'>
      <div className="w-full flex justify-between items-center p-[20px] bg-[#0898A00D] rounded-t-[inherit] rounded-b-none">
        {/* <h4 className='text-[14px] font-[500]'>Voucher Details</h4> */}
        <div className="w-fit flex items-center gap-2">
          {/* <img src={dummyVoucherImg} alt="voucher" className="w-[40px] h-[40px] rounded-full object-cover object-center" /> */}
          <div className="flex flex-col gap-1 w-[full] text-left">
            <h4 className="text-[14px] font-[500] leading-[100%]">{voucherDetails?.purpose}</h4>
            <div className="flex gap-2">
              {/* <VoucherStatusTag status={"active"} type="text" /> */}
              {
                (voucherDetails && voucherDetails.voucher_status) && (
                  <>
                    <VoucherStatusTag status={isVoucherExpired(voucherDetails) ? "revoked" : "active"} type="filled" statusText={isVoucherExpired(voucherDetails) ? "voucher expired" : "voucher active"} />
                    <VoucherStatusTag status={voucherDetails.voucher_status === "used" ? "revoked" : voucherDetails.voucher_status === "unused" ? "active" : "pending"} type="text" statusText={voucherDetails.voucher_status} withDot={false} />
                  </>
                )
              }
            </div>


          </div>
        </div>
        {
          editable &&
          (
            <Button
              label='Edit'
              type='contained'
              className='!rounded-full !m-0 items-center !py-0'
              rightIcon={<img src={editIcon} />}
              onClick={() => { onEditClick && onEditClick() }}
            />
          )
        }

      </div>
      <div className="w-full p-[20px] flex flex-wrap gap-x-5 gap-y-10">
        {
          voucher && voucher.map((detail, index) => {
            if (detail?.data) {
              return (
                <div key={index} className='flex flex-col justify-start items-start gap-2 min-w-[45%] sm:min-w-[200px] max-w-[400px] w-fit text-start'>
                  <h5 className='text-[12px] font-[400] uppercase'>{detail.title}</h5>
                  <div className='text-[13px] font-[500]'>{detail.data}</div>
                </div>
              )
            }
            return null
          })
        }
      </div>
    </div>
  )
}

export default VoucherDetailsComponsnt