import Modal from '../../../components/Modal'
import Button from '../../../components/Button'
import rightArrow from "../../../assets/new/ArrowRightWhite.png"
import Input, { Select } from '../../../components/Input'
import { FormEvent, useState } from 'react'
import { SponsorFundWallet } from '../../../actions/vouchersActions'

const initialData = {
  amount: "",
  paymentGateway: ""
}
function PaymentOptions({ showDetails, handleClose }) {
  const [data, setData] = useState(initialData)

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()

    SponsorFundWallet(data, (resp) => {
      window.open(resp?.data?.pay_link, "_blank", "noreferrer");
      setData(initialData)
      handleClose(false)
    })
  }

  const handleChange = (name, value) => {
    setData({
      ...data,
      [name]: value
    })
  }

  return (
    <Modal
      onClose={() => handleClose(false)}
      open={showDetails}
      title={''}
      overrideStyle={{
        minHeight: '400px',
        height: "auto",
        width: '400px',
        borderRadius: '30px',
        padding: '0'
      }}
      overrideChildrenStyle={{
        padding: '0',
        height: '100% !important'
      }}
    >
      <div className='flex flex-col justify-between items-center h-full !w-full'>
        <div className="border-b border-[#2222221A] w-full px-5 pt-6 pb-5 text-xl font-medium">Select a funding option</div>
        <div className="w-[90%] !h-fit border border-dashed bg-[#0898A00D] my-5 rounded p-2 flex flex-col justify-between items-center gap-3  border-az-teal">
          <p className="w-[95%] text-sm m-auto text-center">
            Enter the amount you want to fund and select a your prefered payment gateway to proceed.
          </p>
          <form onSubmit={handleSubmit}>
            <Input
              label='Amount'
              name='amount'
              type='number'
              placeholder='Total Amount'
              value={data.amount}
              onChange={handleChange}
            />

            <Select
              data={["paystack", "paythru"]}
              label='Select Payment Gateway'
              name='paymentGateway'
              value={data.paymentGateway}
              onChange={handleChange}
            />

            <Button
              label="Procees"
              type='flat'
              className='!m-0 !rounded-full'
              rightIcon={<img src={rightArrow} />}
              btnActionType='submit'
            />
          </form>
        </div>
      </div>
    </Modal>
  )
}

export default PaymentOptions