import { useEffect, useState } from 'react'
import VoucherCard from './VoucherCard'
import { IVoucher, IVoucherList } from '../voucherSlice'
import TablePaginationFooter from '../../../components/TablePaginationFooter/TablePaginationFooter'
import NotFound from '../../../components/NotFound';
import useVerificationDone from '../../../hooks/useVerificationDone';

export default function VoucherListComponent({
  vouchers,
  pagination,
  getAction,
  getActionPayload,
  getActionEffect,
  notFoundText1,
  notFoundText2,
  notToundBtnLabel,
  notToundBtnAction,
  voucherClickAction,
  useEffectDeps = [],
  actionConditions = true,
  voucherIdType = "code"
}:
  {
    pagination?: IVoucherList | null;
    vouchers?: Array<IVoucher> | null;
    getAction?: (payload: Record<string, any>) => void;
    getActionPayload?: Record<string, any>;
    getActionEffect?: (resp?: any) => void;
    notFoundText1?: string | null;
    notFoundText2?: string | null;
    notToundBtnLabel?: string | null;
    notToundBtnAction?: () => void;
    voucherClickAction?: (voucherId: string | number) => void;
    useEffectDeps?: Array<any>
    actionConditions?: boolean
    voucherIdType?: "id" | "code"
  }
) {
  const { isVerificationDone } = useVerificationDone()

  useEffect(() => {
    if (isVerificationDone && actionConditions) {
      getAction && getAction({
        ...getActionPayload,
        page: 1,
        isSilent: getActionPayload?.isSilent ? getActionPayload.isSilent : false,
        handleDone: (resp) => {
          getActionEffect && getActionEffect(resp)
        }
      })
    }
    // @ts-ignore
  }, [isVerificationDone, actionConditions, ...useEffectDeps])

  const handlePagination = (payload) => {
    const page = payload.split('=')[1];
    getAction && getAction({ ...getActionPayload, page, isSilent: true });
  }

  return (
    <div className="w-full">
      <div className="w-[100%] bg-[white] p-[20px] rounded-[10px]"
      >
        {
          (!vouchers || vouchers?.length <= 0) && (
            <NotFound
              text1={notFoundText1 ? notFoundText1 : "Not Found!"}
              text2={notFoundText2 ? notFoundText2 : ""}
              btnLabel={notToundBtnLabel ? notToundBtnLabel : ""}
              onClick={() => { notToundBtnAction && notToundBtnAction() }}
              voucher={true}
            />
          )
        }
        <div className="w-full grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-3">
          {
            (vouchers && vouchers.length > 0) && vouchers?.map((voucher, index) => {
              const voucherId = voucherIdType === "id" ?
                voucher?.id.toString() as string :
                voucherIdType === "code" ?
                  voucher?.voucher_code as string :
                  "";
              return (<VoucherCard voucherData={voucher} key={voucher?.id || index} voucherClickAction={voucherClickAction} voucherId={voucherId} />)
            })
          }
        </div>
      </div>
      {
        pagination && (
          <TablePaginationFooter
            total={pagination?.total}
            from={pagination?.from}
            to={pagination?.to}
            nextUrl={pagination?.next_page_url}
            prevUrl={pagination?.prev_page_url}
            handlePagination={handlePagination}
          />
        )
      }
    </div>
  )
}
