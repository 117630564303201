import Button from '../../../components/Button'
import editIcon from "../../../assets/new/Edit.svg"
import { useEffect } from 'react'
import { getMerchantsForAVoucher } from '../../../actions/vouchersActions'
import { useAppSelector } from '../../../app/hooks'


function MerchantDetailsListComponent({ editable = false, onEditClick, voucherCode }: { editable?: boolean, onEditClick?: () => void, voucherCode: string }) {
  const { voucherMerchants } = useAppSelector(store => store.voucher)

  useEffect(() => {
    if (voucherCode) {
      getMerchantsForAVoucher({
        isSilent: true,
        voucher_code: voucherCode,
      })
    }
  }, [voucherCode])

  return (
    <div className='w-[100%] rounded-md bg-[#FFFFFF]'>
      <div className="w-full flex justify-between items-center p-[20px] bg-[#0898A00D] rounded-t-[inherit] rounded-b-none">
        <h4 className='text-[14px] font-[500]'>Merchant Details</h4>
        {
          editable &&
          (
            <Button
              label='Edit'
              type='contained'
              className='!rounded-full !m-0 items-center !py-0'
              rightIcon={<img src={editIcon} />}
              onClick={() => { onEditClick && onEditClick() }}
            />
          )
        }
      </div>

      <div className="w-full p-[20px]">
        {/* dektop */}
        <table className="hidden md:block w-fit min-w-[600px] rounded-md ">
          <thead className='!rounded-t-[inherit] bg-[#0898A00D] text-left'>
            <tr>
              <th className='text-[12px] text-nowrap font-[400] uppercase p-[15px] pr-[60px]'>STORE NAME</th>
              <th className='text-[12px] text-nowrap font-[400] uppercase p-[15px] pr-[60px]'>MERCHANT ID</th>
              <th className='text-[12px] text-nowrap font-[400] uppercase p-[15px] pr-[60px]'>STORE DESCRIPTION</th>
            </tr>
          </thead>
          <tbody>
            {
              voucherMerchants && voucherMerchants.length > 0 ? voucherMerchants?.map((merchant, index) => {
                return (
                  <tr key={index} className='border-b border-[#2222220D]'>
                    <td className='text-[12px] text-left text-nowrap font-[400] uppercase p-[15px] pr-[60px]'>{merchant?.business_name}</td>
                    <td className='text-[12px] text-left text-nowrap font-[400] uppercase p-[15px] pr-[60px]'>#M-{merchant?.id}</td>
                    <td className='text-[12px] text-left font-[400] uppercase p-[15px] pr-[60px]'>{merchant?.description}</td>
                  </tr>
                )
              })
                :
                <tr className='border-b border-[#2222220D]'>
                  <td colSpan={3} className='text-[12px] text-nowrap font-[400] uppercase p-[15px] pr-[60px] text-center'>No Merchants added</td>
                </tr>
            }
          </tbody>
        </table>

        {/* mobile */}
        <div className="flex md:hidden w-full flex-wrap gap-x-5 gap-y-10">
          {
            voucherMerchants && voucherMerchants.length > 0 ? voucherMerchants?.map((merchant, index) => {
              return (
                <div key={index} className="flex flex-wrap gap-5 border-b border-[#2222220F] py-2">
                  <div className='flex flex-col justify-center items-start gap-0 min-w-[45%] sm:min-w-[200px] w-fit'>
                    <h5 className='text-[12px] font-[400] uppercase'>MERCHANT ID</h5>
                    <div className='text-[13px] font-[500]'>#M-{merchant?.id}</div>
                  </div>
                  <div className='flex flex-col justify-center items-start gap-0 min-w-[45%] sm:min-w-[200px] w-fit'>
                    <h5 className='text-[12px] font-[400] uppercase'>STORE NAME</h5>
                    <div className='text-[13px] font-[500]'>{merchant?.business_name}</div>
                  </div>
                  <div className='flex flex-col justify-center items-start gap-0 min-w-[45%] sm:min-w-[200px] w-fit'>
                    <h5 className='text-[12px] font-[400] uppercase'>STORE DESCRIPTION</h5>
                    <div className='text-[13px] font-[500]'>{merchant?.description}</div>
                  </div>
                </div>
              )
            })
              :
              <tr>No Merchants added</tr>
          }
        </div>
      </div>
    </div>
  )
}

export default MerchantDetailsListComponent