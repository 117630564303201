import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { handleDeleteCustomer } from "../../actions/businessActions";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { ICustomer } from "../../pages/Business/businessSlice";
import closeIcon from "../../assets/close-icon.svg";
import editIcon from "../../assets/edit-icon.png";
import ConfirmModal from "../ConfirmModal/ConfirmModal";
import NotFound from "../NotFound";
import './customerList.scss';

const CustomerList = (props) => {
  const [toDelete, setToDelete] = useState<ICustomer | null>();
  const dispatch = useAppDispatch();
  const customerObj = useAppSelector((state) => state.business.customers);
  const location = useLocation() as any;
  const businessCode = location.pathname.split('/')[3];
  const customers = customerObj[businessCode];

  useEffect(() => {
    if (!customerObj[businessCode]) {
      props?.getCustomersCall();
    }
  }, [businessCode, customerObj]);

  const deleteCustomer = () => {
    handleDeleteCustomer(dispatch, toDelete?.id, businessCode, () => {
      setToDelete(null);
      props?.getCustomersCall();
    });
  }

  return (
    <div className="customer-wrapper bg-white flex-1 h-full min-h-[80vh] items-start justify-center lg:justify-start">
      {
        customers && customers.length > 0 ? (
          <>
            {
              customers && customers.map((customer) => (
                <CustomerCard
                  key={customer?.customer_name}
                  customer={customer}
                  setToDelete={setToDelete}
                  setToEdit={props.setToEdit}
                />
              ))
            }
          </>
        ) : (
          <NotFound
            text1="You don't have Customers Yet!"
            text2='Create your customers and send payment requests to them.'
            btnLabel="Create a Customer"
            onClick={() => props.handleCreate(true)}
          />
        )
      }
      <ConfirmModal
        isOpen={!!toDelete}
        text='Are you sure you want to delete this customer?'
        handleNo={() => setToDelete(null)}
        handleYes={() => deleteCustomer()}
        onClose={() => setToDelete(null)}
      />
    </div>
  );
};

export default CustomerList;

const CustomerCard = ({ customer, setToDelete, setToEdit }) => {
  console.log(customer);

  if (!customer) {
    return null;
  }
  return (
    <div className="customer-card flex flex-row justify-start items-center m-2 !px-3 rounded">
      <img src={editIcon} alt="" onClick={() => setToEdit(customer)} className="cursor-pointer !right-[40px]" />
      <img src={closeIcon} alt="" onClick={() => setToDelete(customer)} className="cursor-pointer" />
      <div className='border border-black h-16 !w-16 mr-4 rounded-[40px] flex justify-center items-center text-2xl'>
        {customer.customer_name[0]}
      </div>
      <div className="flex flex-col justify-start items-start flex-grow w-8/12">
        <h3 className="overflow-hidden w-full text-left">{customer.customer_name}</h3>
        <h5 className="overflow-hidden w-full text-left">{customer.customer_phone}</h5>
        <h5 className="overflow-hidden w-full text-left">{customer.customer_email}</h5>
      </div>
    </div>
  );
};
