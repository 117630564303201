import { useEffect, useState } from "react";
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import TransactionsListWrapper from "../Transactions/TransactionListWrapper";
import { useLocation } from "react-router-dom";
import { getMPOSTransactions } from "../../actions/businessActions";
import useGetBusinessCode from "../../hooks/useGetBusinessCode";
import Table from "../Table";
import { TableAction, TableStatus } from "../ExpenseList";
import { useAppSelector } from "../../app/hooks";
import TablePaginationFooter from "../TablePaginationFooter/TablePaginationFooter";
import { formatCurrency } from "../../utils/common";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

const processData = (data: any) => {
  if (!data) return [];
  const newData = data.map((itm: any) => {
    const newItm = {...itm};
    newItm.created_at = itm.data[0].created_at;
    newItm.paymentReference = itm.data[0].paymentReference;
    const initialValue = 0;
    const sumWithInitial = itm.data.reduce(
      (accumulator, currentValue) => accumulator + (currentValue.residualAmount || 0),
      initialValue,
    );
    newItm.residualAmount = sumWithInitial 
    return newItm;
  });
  return newData;
}

interface StateType {
  order?: any; // Replace `any` with the actual type of `order`
}

const TransactionListTab = ({handleCreate, handleMore}) => {
  const { state } = useLocation();
  const [code] = useGetBusinessCode();
  const mposTransactions = useAppSelector((state) => state.business.transactionsMPOS);
  const [data, setData] = useState([]);
  const mposData = processData(mposTransactions[code as string]);
  
  const getTransactions = () => {
    getMPOSTransactions(code, 1, (resp) => {
      setData(resp?.data?.original?.data)
    });
  }
  useEffect(() => {
    const stateObj = state as StateType;
    if (stateObj && stateObj?.order) {
      handleCreate();
    }
    code && getTransactions();
  }, [code, state]);

  const header = [
    {field: 'email', headerName:  'Customer\'s Email'},
    {field: 'transaction_amount', headerName:  'Total', cellRenderer: ({data}: any) => `${formatCurrency({num: (data.transaction_amount || data.total_Amount) })}`},
    {field: 'amount', headerName:  'Paid', cellRenderer: ({data}: any) => `${formatCurrency({num: data.residualAmount || 0})}`},
    {field: 'created_at', headerName: 'Created At', cellRenderer: ({data}: any) => dayjs(data.created_at || data.latest_created_at).fromNow()},
    {
      field: 'Status',
      headerName:  'Status',
      cellRenderer: ({data}: any) =>  <TableStatus amountPaid={data.residualAmount} target={data.transaction_amount || data.total_Amount || 0}  />
    },
    {
      field: 'action',
      headerName:  'Action',
      cellRenderer: ({data}: any) =>  <TableAction handleMore={() => handleMore(data)} />
    }
  ];

  const mobileConfig = {
    logo: {
      value: '',
      renderer: (data: any) => (
        <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="22.5" cy="22.5" r="22.5" fill="#FFE6E4"/>
          <path d="M22.7256 15.25V30.25" stroke="#B40D00" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M16.701 21.2998L22.725 15.2498L28.75 21.2998" stroke="#B40D00" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      )
    },
    topLeft: {
      // field: 'email',
      renderer: (data) => <p className="overflow-hidden w-[60%]">{data.email}</p>
    },
    topRight: {
      renderer: (data: any) =>  <div className=' flex justify-center items-center'> {parseInt(data?.amount) >= parseInt(data?.transaction_amount) ? (<p className="px-2 flex justify-center items-center bg-green-500 text-white rounded !w-fit h-8">Paid</p>) : (<p className="px-2 bg-orange-400 rounded text-white !w-fit h-8 flex justify-center items-center">Pending</p>)}</div>
    },
    bottomLeft:  {
      field: '',
      renderer: (data: any) => formatCurrency({num: (data.transaction_amount || data.total_Amount) })
    },
    more: {
      clickHandler: (data:any) => {
        handleMore(data);
      }
    }
  }

  const handlePagination = (payload) => {
    const page = payload.split('=')[1];
    getMPOSTransactions(code, page, (resp) => {
      setData(resp?.data?.original?.data)
    })
  }

  return (
    <TransactionsListWrapper>
      <div className="h-[65vh] w-full text-left">
        <Table
          data={mposData || []}
          header={header}
          fitStrategy="fitGridWidth"
          mobileConfig={mobileConfig}
        />
        {
          mposData?.data && (
          <TablePaginationFooter 
              total={mposData?.total}
              from={mposData?.from}
              to={mposData?.to}
              nextUrl={mposData?.next_page_url}
              prevUrl={mposData?.prev_page_url}
              handlePagination={handlePagination}
          />
          )
        }
      </div>
    </TransactionsListWrapper>
  );
}

export default TransactionListTab;
