import { FormEvent, useEffect, useRef, useState } from 'react';
import Input, { InputLabel, Select } from '../../../components/Input';
import Button from '../../../components/Button';
import { useOnClickOtside } from '../../../hooks/useClickOutside';
import closeimg from '../../../assets/new/close.png';
import { useAppSelector } from '../../../app/hooks';
import useUserData from '../../../hooks/useUserData';
import {
	createVoucher,
	getMerchantBusinessesByState,
	updateVoucher,
} from '../../../actions/vouchersActions';
import { states } from '../../../utils/data';
import GeneralStatusModal from '../../../components/Modal/GeneralStatusModal';

function CreateVoucher({
	editMode = false,
	editData = null,
	editStep = 1,
	handleClose,
	successEffectAction,
}: {
	editMode?: boolean;
	editData?: Record<string, any> | null;
	editStep?: 1 | 2;
	handleClose?: () => void;
	successEffectAction?: () => void;
}) {
  // step
	const [step, setStep] = useState<1 | 2>(() => {
		if (editMode && editStep) {
			return editStep;
		} else {
			return 1;
		}
	});

  // data
	const [data, setData] = useState(() => {
		if (editMode && editData) {
			return editData;
		} else {
			return {
				purpose: '',
				voucher_amount: '',
				amount_per_code: '',
				expiry_date: '',
				code_generation_method: '',
				type: '',
				state: '',
				limit: '',
			};
		}
	});

  // all merchants
	const [allMerchants, setAllMerchants] = useState<Array<
		Record<string, any>
	> | null>(null);

  // selected businesses
	const [selectedBusinesses, setSelectedBusinesses] = useState<
		Array<Record<string, any>>
	>(() => {
		if (editMode && editData) {
			return editData?.selectedBusinesses;
		} else {
			return [];
		}
	});

  // search business
	const [searchBusiness, setSearchBusiness] = useState('');

  // search res open
	const [searchResOpen, setSearchResOpen] = useState(false);

  // create success
	const [createSuccess, setCreateSuccess] = useState(false);
  
  // input con ref
	const inputConRef = useRef<HTMLDivElement | null>(null);

  // user details
	const { userDetails } = useUserData();
	const { merchantBusinesses } = useAppSelector((store) => store.voucher);

	// @ts-ignore
	useOnClickOtside(inputConRef, () => setSearchResOpen(false));

  // get businesses when location is selected
	useEffect(() => {
		if (data.state) {
			getMerchantBusinessesByState({
				state: data.state,
				isSilent: false,
				handleDone: (resp) => {
					setAllMerchants(resp);
				},
			});
		}
	}, [data.state]);

  // get businesses when search is empty
	useEffect(() => {
		if (merchantBusinesses && searchBusiness === '') {
			setAllMerchants(
				merchantBusinesses?.filter(
					(el) => !selectedBusinesses.includes(el)
				)
			);
		}
	}, [searchBusiness, merchantBusinesses, selectedBusinesses]);

  // handle change
	const handleChange = (name, value) => {
		setData((prev) => ({
			...prev,
			[name]: value,
		}));
	};

  // handle submit
	const handleSubmit = (e: FormEvent, move?: 1 | 2) => {
		e.preventDefault();
		if (move) {
			setStep(move);
			return;
		}
		delete data.selectedBusinesses;

		const reqData = {
			...data,
			amount_per_code: parseFloat(data.amount_per_code),
			voucher_amount: parseFloat(data.voucher_amount),
			business_id: selectedBusinesses.map((el) => el.id),
			limit: parseInt(data.limit),
			sponsor_id: userDetails?.id.toString(),
		};

		if (data.type === 'one_time') {
			// @ts-ignore
			delete reqData.limit;
		}

		if (editMode) {
			updateVoucher(editData?.id, reqData, (resp) => {
				setData({
					purpose: '',
					voucher_amount: '',
					amount_per_code: '',
					expiry_date: '',
					code_generation_method: '',
					type: '',
					state: '',
					limit: '',
				});
				handleClose && handleClose();
				successEffectAction && successEffectAction();
			});
			return;
		}

		createVoucher(reqData, (resp) => {
			setData({
				purpose: '',
				voucher_amount: '',
				amount_per_code: '',
				expiry_date: '',
				code_generation_method: '',
				type: '',
				state: '',
				limit: '',
			});
			handleClose && handleClose();
			setCreateSuccess(true);
			successEffectAction && successEffectAction();
		});
	};

	return (
		<>
			<div className='w-full flex !flex-col'>
				<div className='mb-5'>
					<span className='px-5 py-2 bg-[#0898A01A] rounded-full text-[12px] font-[400]'>
						Step {step}/2
					</span>
				</div>
				{step === 1 && (
					<>
						<h1 className='text-2xl mb-3'>
							{editMode ? 'Edit' : 'Create'} Voucher
						</h1>
						<p className='text-[14px] font-[400]'>
							Enter the following information about the voucher
							you’re about to create.
						</p>

						<form
							className='w-full my-5'
							onSubmit={(event: FormEvent) => {
								handleSubmit(event, 2);
							}}>
							<Input
								label='Voucher Purpose'
								name='purpose'
								value={data.purpose}
								type='text'
								onChange={handleChange}
								required
							/>
							<Input
								label='Voucher Amount'
								name='voucher_amount'
								value={data.voucher_amount}
								type='number'
								onChange={handleChange}
								required
							/>
							<Input
								label='Amount (per person)'
								name='amount_per_code'
								value={data.amount_per_code}
								type='number'
								onChange={handleChange}
								required
							/>
							<Input
								label='Expiry Date'
								name='expiry_date'
								value={data.expiry_date}
								type='date'
								onChange={handleChange}
								required
							/>
							<Select
								data={['one_time', 'multiple_time']}
								label='Type Of Voucher'
								name='type'
								value={data.type}
								onChange={handleChange}
								required
							/>
							{data.type === 'multiple_time' && (
								<Input
									label='Limit'
									name='limit'
									value={data.limit}
									type='number'
									onChange={handleChange}
									required
								/>
							)}

							<Select
								data={['sms', 'qr_code']}
								label='Voucher generation method'
								name='code_generation_method'
								value={data.code_generation_method}
								onChange={handleChange}
								required
							/>
							<Button
								type='flat'
								btnActionType='submit'
								label='Next'
								className='!rounded-full !mt-5'
							/>
						</form>
					</>
				)}

				{step === 2 && (
					<>
						<h1 className='text-2xl mb-3'>
							{editMode ? 'Edit' : 'Add'} Merchants
						</h1>

						{!editMode && (
							<p className='text-[14px] font-[400]'>
								You’re almost done, kindly add location and the
								merchants where the voucher will be redeemed.
							</p>
						)}

						<form
							className='w-full my-5'
							onSubmit={(event: FormEvent) => {
								handleSubmit(event);
							}}>
							<Select
								data={[...states]}
								label='State'
								name='state'
								value={data.state}
								onChange={handleChange}
							/>

							{data.state && (
								<>
									<div className='w-full'>
										<InputLabel
											// @ts-ignore
											props={{
												label: 'Add merchants',
												name: 'business_id',
											}}
										/>
										<div className='w-full flex flex-wrap gap-2 max-h-[250px] overflow-y-auto'>
											{selectedBusinesses.map(
												(singleBus) => (
													<span
														key={singleBus.id}
														className='p-2 rounded-md bg-[#2222220D] border flex justify-center items-center gap-2 text-[12px] font-[500]'>
														{
															singleBus?.business_name
														}{' '}
														#M-
														{singleBus?.id}
														<img
															src={closeimg}
															alt='close'
															className='w-[12px] h-[12px] cursor-pointer'
															onClick={() => {
																setSelectedBusinesses(
																	selectedBusinesses.filter(
																		(i) =>
																			i?.id !==
																			singleBus?.id
																	)
																);
																setAllMerchants(
																	[
																		...(allMerchants as Array<
																			Record<
																				string,
																				any
																			>
																		>),
																		singleBus,
																	]
																);
															}}
														/>
													</span>
												)
											)}
										</div>
									</div>

									<div
										ref={inputConRef}
										onClick={() => setSearchResOpen(true)}
										className='relative w-full '>
										<input
											value={searchBusiness}
											name='business_id'
											className='w-full border border-[#b4b4b5] h-[45px] px-[10px] bg-[#FCFDFE] text-[#66676d] text-[14px] rounded-[4px] box-border mt-2'
											placeholder='Search for your preferred merchants'
											onChange={(e) => {
												setSearchBusiness(
													e.target.value
												);
												if (!allMerchants) {
													return;
												}

												const newArr = [
													...(merchantBusinesses as Array<
														Record<string, any>
													>),
												];
												const filteredArr =
													newArr.filter(
														(i) =>
															i?.business_name
																.toLowerCase()
																.includes(
																	e.target.value.toLowerCase()
																) &&
															!selectedBusinesses.includes(
																i
															)
													);

												if (filteredArr.length > 0) {
													setAllMerchants(
														filteredArr
													);
												}
											}}
										/>
										{searchResOpen && (
											<ul className='w-full bg-white absolute z-10 top-[100%] shadow-sm max-h-[250px] overflow-y-auto'>
												{allMerchants &&
												allMerchants.length > 0 ? (
													allMerchants.map(
														(merchant) => (
															<li
																key={
																	merchant.id
																}
																className={`p-2 mb-1 cursor-pointer hover:bg-slate-100`}
																onClick={() => {
																	setSelectedBusinesses(
																		[
																			...selectedBusinesses,
																			merchant,
																		]
																	);
																	setAllMerchants(
																		allMerchants.filter(
																			(
																				i
																			) =>
																				i.id !==
																				merchant.id
																		)
																	);
																	setSearchBusiness(
																		''
																	);
																	setSearchResOpen(
																		false
																	);
																}}>
																{
																	merchant?.business_name
																}{' '}
																#M-
																{merchant?.id}
															</li>
														)
													)
												) : (
													<li className='p-2 mb-1'>
														No merchant found
													</li>
												)}
											</ul>
										)}
									</div>
								</>
							)}
							<Button
								type='flat'
								btnActionType='submit'
								label={
									editMode
										? 'Update Changes'
										: '+ Create Voucher'
								}
								className='!rounded-full !mt-5'
							/>
							{editMode && editStep !== 2 && (
								<Button
									type='contained'
									btnActionType='button'
									label='Previous'
									className='!rounded-full !m-0 !mt-5 w-full'
									onClick={() => setStep(1)}
								/>
							)}
						</form>
					</>
				)}
			</div>
			<GeneralStatusModal
				open={createSuccess}
				setOpen={setCreateSuccess}
				status='success'
				description={'Created Voucher successsfully'}
			/>
		</>
	);
}

export default CreateVoucher;
