import Button from "../Button";
import Input from "../Input";
import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { createCustomer, handleUpdateCustomer } from "../../actions/businessActions";
import { useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import { ICustomer } from "../../pages/Business/businessSlice";

interface Props {
  getCustomersCall?: () => void;
  isOpen: boolean;
  handleClose: () => void;
  name?: string;
  handleDone?: (data: any) => void;
  editMode?: boolean;
  editData?: ICustomer | null;
}

const CreateCustomer = ({ getCustomersCall, handleClose, name, handleDone, editMode = false, editData }: Props) => {
  const { isLoading } = useAppSelector(state => state.loader);
  const location = useLocation() as any;
  const businessCode = location.pathname.split('/')[3];
  const dispatch = useAppDispatch();
  const [data, setData] = useState({
    name: '',
    email: '',
    phone: '',
  });

  useEffect(() => {
    if (editMode && editData) {
      setData({ name: editData?.customer_name, email: editData?.customer_email, phone: editData?.customer_phone });
    }
  }, [editMode, editData]);

  useEffect(() => {
    if (name) {
      setData((prevState) => ({
        ...prevState,
        name,
      }))
    }
  }, [name]);

  const handleAddFormSubmit = () => {
    if (!data.name || !data.email || !data.phone) {
      toast('Enter all form details to continue!')
      return;
    }
    if (editMode) {
      handleUpdateCustomer(
        {
          customer_name: data.name,
          customer_phone: data.phone,
        },
        dispatch,
        editData?.id,
        businessCode,
        (resp) => {
          handleDone && handleDone(data.email);
          getCustomersCall && getCustomersCall();
          if (resp) {
            setData({
              name: '',
              email: '',
              phone: '',
            });
          }
        })
     
      return;
    }
    createCustomer({
      customer_name: data.name,
      customer_email: data.email,
      customer_phone: data.phone,
    }, dispatch, businessCode, (resp) => {
      handleDone && handleDone(data.email);
      if (resp) {
        setData({
          name: '',
          email: '',
          phone: '',
        });
      }
      getCustomersCall && getCustomersCall();
    });

  }

  const handleChange = useCallback((name: string, value: string) => {
    setData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  }, []);

  return (
    <form onSubmit={handleAddFormSubmit} style={{ width: '100%', padding: '30px 0' }}>
      <h1 className="text-3xl text-left w-full mb-10">{editMode ? "Update Customer" : "Add Customer"}</h1>
      <Input
        label="Name"
        placeholder="Firstname Lastname"
        name="name"
        required
        value={data.name}
        onChange={handleChange}
      />
      <Input
        label="email"
        placeholder=""
        name="email"
        type="email"
        required
        value={data.email}
        onChange={handleChange}
        disabled={editMode}
      />
      <Input
        type="tel"
        label="Phone Number"
        placeholder=""
        name="phone"
        required
        value={data.phone}
        onChange={handleChange}
      />
      <div className="flex flex-row justify-between items-center w-full">
        <Button
          label="Cancel"
          type="contained"
          btnActionType="button"
          onClick={handleClose}
          overrideStyle={{
            width: '45%'
          }}
        />
        <Button
          label={editMode ? "Update" : "Add"}
          type="flat"
          btnActionType="submit"
          disabled={isLoading}
          onClick={handleAddFormSubmit}
          overrideStyle={{
            width: '45%'
          }}
        />
      </div>
    </form>
  )
};

export default CreateCustomer;
