import { useEffect, useState } from "react";
import DashboardComponentWrapper from "../../../components/DashboardComponentWrapper"
import TabComponent from "../../../components/TabComponent"
import SidePane from "../../../components/SidePane";
import AdminUserDetails from "../../../components/AdminUserDetails";
import UsersKYCList from "../../../components/AdminUsersList/UserKYCList";
import { getAllUsersWithKYC } from "../../../actions/adminActions";
import { useAppSelector } from "../../../app/hooks";

function KYCManagement() {
  const [user, setUser] = useState<Record<string, any> | null>(null);
  const { usersWithKYC } = useAppSelector((state) => state.admin)

  useEffect(() => {    
    getAllUsersWithKYC(1)
  }, [])

  return (
    <DashboardComponentWrapper
      subTitle="KYC Management"
      pageDetails="Here's an overview of all submitted KYC on AzatMe."
    >
      <>
        <TabComponent
          components={{
            "Submitted KYCs": () => <UsersKYCList getAllUsers={getAllUsersWithKYC} setUser={(user) => setUser(user)}
              data={usersWithKYC} />
          }}
        />
        <SidePane onClose={() => setUser(null)} open={!!user} title={""}>
          <AdminUserDetails user={user} setUser={(user) => setUser(user)} withKYC={true} />
        </SidePane>
      </>
    </DashboardComponentWrapper>
  )
}

export default KYCManagement