import { useEffect, useState } from 'react'
import VoucherDashboardComponentWrapper from '../../voucher-layouts/VoucherDashboardComponentWrapper'
import VoucherTopComponents from '../../voucher-layouts/VoucherTopComponents'
import Button from '../../../../components/Button'
import scanImg from "../../voucher-assets/icons/Scan.svg"
import valueVoucherImg from "../../../../assets/new/business/merchant-voucher.png"
import volumeVoucherImg from "../../../../assets/new/business/sponsor-voucher.png"
import { formatCurrency } from '../../../../utils/common'
import { Link, useNavigate } from 'react-router-dom'
import useUserData from '../../../../hooks/useUserData'
import VoucherListComponent from '../../voucher-components/VoucherListComponent'
import RedeemVoucherModal from '../../voucher-components/modals/RedeemVoucherModal'
import { getRedeemableVouchersForMerchant, getVoucherSummary } from '../../../../actions/vouchersActions'
import { useAppSelector } from '../../../../app/hooks'
import { IVoucher, IVoucherList } from '../../voucherSlice'
import useVerificationDone from '../../../../hooks/useVerificationDone'

function MerchantHomePage() {
  const [summary, setSummary] = useState({
    total_voucher_redeemed_volume: 0,
    total_voucher_used_value: 0
  })
  const [openRedeemVoucherModal, setOpenRedeemVoucherModal] = useState(false)
  const { vouchers } = useAppSelector(state => state.voucher)
  const { userDetails } = useUserData()
  const { kycActionWrapper, isVerificationDone } = useVerificationDone()
  const navigate = useNavigate()

  useEffect(() => {
    if (isVerificationDone) {
      getVoucherSummary((resp) => {
        setSummary(resp?.data)
      })
    }
  }, [isVerificationDone, vouchers])

  return (
    <VoucherDashboardComponentWrapper
      title="Welcome to AzatMe Voucher 🥳"
      subTitle="Here is an overview of your account"
    >
      <>
        <VoucherTopComponents
          filled={false}
          leftComponents={[<h2 className="text-[15px] font-[500]">Dashboard</h2>]}
          rightComponents={
            [
              <Button
                label="Redeem Voucher"
                type="flat"
                className="w-full !rounded-[25px] !py-[20px] !px-[20px] !m-0"
                leftIcon={<img src={scanImg} alt="scan" />}
                onClick={() => kycActionWrapper(() => setOpenRedeemVoucherModal(true))}
              />
            ]
          }
        />

        <div className="w-[95%] bg-white p-5 rounded-[10px] border border-[#F5F5F5] flex flex-wrap gap-3 justify-center md:justify-start">
          <div className="w-full min-w-[350px] items-start max-w-[485px] h-[133px] p-5 flex flex-col gap-5 border border-[#2222221A] rounded-[10px] bg-white">
            <div className="flex justify-start items-center gap-3 ">
              <img src={valueVoucherImg} className='w-[40px] h-[40px]' alt="voucher" />
              <h4 className='text-[14px] font-[400]'>Total Vouchers Redeemed in Value</h4>
            </div>
            <p className="text-2xl font-[500]">
              {formatCurrency({ num: summary?.total_voucher_used_value })}
            </p>
          </div>
          <div className="w-full min-w-[350px] items-start max-w-[485px] h-[133px] p-5 flex flex-col gap-5 border border-[#2222221A] rounded-[10px] bg-white">
            <div className="flex justify-start items-center gap-3 ">
              <img src={volumeVoucherImg} className='w-[40px] h-[40px]' alt="voucher" />
              <h4 className='text-[14px] font-[400]'>Total Vouchers Redeemed in Volume</h4>
            </div>
            <p className="text-2xl font-[500]">
              {summary?.total_voucher_redeemed_volume}
            </p>
          </div>
        </div>

        <div className="w-[95%] bg-white p-5 rounded-[10px]">
          <div className="flex justify-between items-center">
            <h4 className="text-[14px] font-[400]">Here are vouchers you can redeem</h4>
            {userDetails?.business_code ? (<Link className='text-base text-[#0898A0] underline opacity-90 hover:opacity-100' to={`/merchant/${userDetails?.business_code}/vouchers`}>View all</Link>) : (<span className='invisible' />)}
          </div>
          <VoucherListComponent
            // pagination={{ ...vouchers, data: null } as IVoucherList}
            vouchers={vouchers?.data}
            // @ts-ignore
            getAction={getRedeemableVouchersForMerchant}
            getActionPayload={{ merchantId: userDetails?.id.toString() }}
            notFoundText1={"You have not redeemed any voucher yet"}
            notToundBtnLabel={"Redeem Voucher"}
            notToundBtnAction={() => kycActionWrapper(() => setOpenRedeemVoucherModal(true))}
            useEffectDeps={[userDetails?.id]}
            actionConditions={!!(userDetails && !!userDetails.id)}
            voucherIdType='code'
            voucherClickAction={(voucherCode) => { navigate(`/merchant/${userDetails?.business_code}/vouchers/${voucherCode}`) }}
          />
        </div>
        <RedeemVoucherModal openModal={openRedeemVoucherModal} handleClose={() => setOpenRedeemVoucherModal(false)} />
      </>
    </VoucherDashboardComponentWrapper>
  )
}

export default MerchantHomePage