import { FormEvent, useEffect, useState } from 'react'
import DashboardComponentWrapper from '../../../components/DashboardComponentWrapper'
import TabComponent from '../../../components/TabComponent'
import { formatCurrency } from '../../../utils/common'
import DashboardNavItem from '../../../components/DashboardNavItem/DashboardNavItem'
import SidePane from '../../../components/SidePane'
import Input from '../../../components/Input'
import Button from '../../../components/Button'
import { getAllSponsorPaymentWithWallet, getSponsorLimits, setSponsorLimits } from '../../../actions/adminActions'
import Table from '../../../components/Table'
import { TableAction } from '../../../components/ExpenseList'
import Modal from '../../../components/Modal'

function SponsorManagement() {
  const [tab, setTab] = useState('');
  const [limitOpen, setLimitOpen] = useState(false)

  const [limits, setLimits] = useState({
    fund_limit: "",
    voucher_limit: ""
  })

  const fetchDataFunc = () => {
    getSponsorLimits((resp) => {
      setLimits({
        fund_limit: resp?.data[0]?.fund_limit,
        voucher_limit: resp?.data[0]?.voucher_limit
      })
    })
  }

  useEffect(() => {
    fetchDataFunc()
  }, [])

  const businessNavItemsObj = [
    {
      label: 'Set Sponsor Limit',
      action: () => { setLimitOpen(true) },
      bgColor: '#0898A0',
    }
  ];

  const renderNavItems = () => {
    return (
      <DashboardNavItem
        navItems={[]}
        tab={tab}
        navItemsObj={businessNavItemsObj}
        setTab={setTab}
        dontShowWithdraw
      />
    )
  }

  const handleSubmitLimit = (e: FormEvent) => {
    e.preventDefault()
    setSponsorLimits(limits, () => {
      setLimitOpen(false)
      fetchDataFunc()
    })
  }

  const handleChange = (name, value) => {
    setLimits((prev) => ({
      ...prev,
      [name]: value
    }))
  }

  return (
    <DashboardComponentWrapper
      subTitle="Sponsor Management"
      pageDetails="Here's an overview of sponsor limits on AzatMe Voucher."
      renderNavItems={renderNavItems}
    >
      <>
        <div className="w-full">
          <div className="px-5 flex items-center gap-2 flex-wrap">
            <div className="w-fit text-start p-5 border border-az-teal rounded-[10px]">
              <h4 className='text-[16px] font-[500]'>Current Sponsor Fund Limit</h4>
              <p className='text-[14px] font-[400]'>{formatCurrency({ num: limits?.fund_limit })}</p>
            </div>

            <div className="w-fit text-start p-5 border border-az-teal rounded-[10px]">
              <h4 className='text-[16px] font-[500]'>Current Sponsor Voucher Limit</h4>
              <p className='text-[14px] font-[400]'>{formatCurrency({ num: limits?.voucher_limit })}</p>
            </div>
          </div>

          <TabComponent
            components={{
              "Sponsor Transactions": SponsorTransactions
            }}
          />

        </div>
        <SidePane title='Set New Sponsor Limits' open={limitOpen} onClose={() => setLimitOpen(false)}>
          <div className="w-full">
            <p className='text-[14px] font-[400]'>
              This limit is the limit of money in naira a sponsor can func their account with
            </p>
            <form onSubmit={handleSubmitLimit} >
              <Input
                label='Sponsor Fund Limit'
                name='fund_limit'
                value={limits.fund_limit}
                onChange={handleChange}
                required
              />
              <Input
                label='Sponsor Voucher Limit'
                name='voucher_limit'
                value={limits.voucher_limit}
                onChange={handleChange}
                required
              />
              <Button
                label='Submit'
                btnActionType='submit'
                type='flat'
              />
            </form>
          </div>
        </SidePane>
      </>
    </DashboardComponentWrapper>
  )
}

export default SponsorManagement

const SponsorTransactions = () => {
  const [data, setData] = useState([])
  const [showTransactions, setShowTransactions] = useState(false)
  const [trasactions, setTransactions] = useState<Array<Record<string, any>> | null>(null)

  useEffect(() => {
    getAllSponsorPaymentWithWallet((resp) => {
      setData(resp?.data?.data)
    })
  }, [])

  const header = [
    { field: 'user_id', headerName: 'User ID', },
    { field: 'wallet_balance', headerName: 'Wallet Balance', cellRenderer: ({ data }: any) => formatCurrency({ num: data?.wallet_balance }) },
    {
      field: 'action',
      headerName: 'Action',
      cellRenderer: ({ data }: any) => <TableAction handleMore={() => {
        setShowTransactions(true)
        setTransactions(data);
      }} />
    }
  ]

  const transactionsHeaders = [
    { field: 'sponsor_id', headerName: 'Sponsor ID', },
    { field: 'providedEmail', headerName: 'Email' },
    { field: 'paymentMethod', headerName: 'Payment Method' },
    { field: 'amount', headerName: 'Amount', cellRenderer: ({ data }: any) => formatCurrency({ num: data?.amount }) },
    { field: 'paymentReference', headerName: 'Payment Ref' },
  ]

  return (
    <>
      <div className="w-full h-[50vh] ">
        <Table
          data={data}
          header={header}
          fitStrategy="fitCellContents"
        />
      </div>
      <Modal
        open={showTransactions}
        onClose={() => setShowTransactions(false)}
        title='Sponsor Transactions'
        overrideStyle={{
          minWidth: "70vw",
          height: "80vh"
        }}
      >
        <div className="w-full h-[60vh]">
          <Table
            // @ts-ignore
            data={trasactions?.payments}
            header={transactionsHeaders}
            fitStrategy="fitGridWidth"
          />
        </div>
      </Modal>
    </>
  )
}