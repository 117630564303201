
import Button from '../../../../components/Button'
import TabComponent from '../../../../components/TabComponent'
import useUserType from '../../../../hooks/useUserType'
import useVerificationDone from '../../../../hooks/useVerificationDone'
import GoBackBtn from '../../voucher-components/GoBackBtn'
import VoucherDetails from '../../voucher-components/VoucherDetails'
import VoucherDashboardComponentWrapper from '../../voucher-layouts/VoucherDashboardComponentWrapper'
import VoucherTopComponents from '../../voucher-layouts/VoucherTopComponents'
import scanImg from "../../voucher-assets/icons/Scan.svg"
import { useCallback, useEffect, useState } from 'react'
import RedeemVoucherModal from '../../voucher-components/modals/RedeemVoucherModal'
import { useParams } from 'react-router-dom'
import { claimVoucher } from '../../../../actions/vouchersActions'


export default function VoucherDetailsPage() {
  const [openRedeemVoucherModal, setOpenRedeemVoucherModal] = useState(false)

  const { isMerchant, isIndividual } = useUserType()
  const { kycActionWrapper } = useVerificationDone()
  const params = useParams()

  const voucherCode = params?.voucherCode

  useEffect(() => {
    if (isIndividual) {
      claimVoucher(voucherCode, (resp) => {
        console.log(resp);
      })
    }
  }, [isIndividual, voucherCode])

  return (
    <VoucherDashboardComponentWrapper
      title="AzatMe Vouchers"
    // subTitle="Here are an overview of all your vouchers"
    >
      <>
        <VoucherTopComponents
          filled={true}
          leftComponents={[<GoBackBtn />]}
          middleComponents={
            [
              <h4 className='flex sm:hidden'>Voucher Details</h4>
            ]
          }
          rightComponents={
            [
              <span className=''>
                {isMerchant && (
                  <>
                    <Button
                      label="Redeem Voucher"
                      type="flat"
                      className="!w-fit !rounded-full !p-[20px] !m-0 !hidden sm:!flex"
                      leftIcon={<img src={scanImg} alt="scan" />}
                      onClick={() => kycActionWrapper(() => {
                        setOpenRedeemVoucherModal(true)
                      })}
                    />
                    <span className="!w-fit !rounded-full !p-[20px] !m-0 flex justify-center items-center bg-az-teal sm:hidden"
                      onClick={() => kycActionWrapper(() => {
                        setOpenRedeemVoucherModal(true)
                      })}
                    >
                      <img src={scanImg} alt="scan" />
                    </span>
                  </>
                )}
              </span>
            ]
          }
        />
        <TabComponent
          components={
            {
              "Voucher Information": useCallback(() => <VoucherDetails />, []),
              // "Transactions": () => <div>Transactions</div>,
            }
          }
          isVoucherTab={true}
        />
        <RedeemVoucherModal currentCode={voucherCode} openModal={openRedeemVoucherModal} handleClose={() => setOpenRedeemVoucherModal(false)} />
      </>
    </VoucherDashboardComponentWrapper>
  )
}
