
import NotFound from "../NotFound";
import TablePaginationFooter from "../TablePaginationFooter/TablePaginationFooter";
import Table from "../Table";
import { TableAction, TableNormalStatus } from "../ExpenseList";


const UsersKYCList = (props: { getAllUsers: (arg: number) => void, setUser: (arg: Record<string, any> | null) => void, setHideNav?: (arg: boolean) => void, data: Record<string, any> | null }) => {
  const header = [
    { field: 'first_name', headerName: 'Firstname', cellRenderer: ({ data }: any) => (data.first_name || data.name) },
    { field: 'last_name', headerName: 'Lastname' },
    { field: 'phone', headerName: 'Phone Number' },
    { field: 'email', headerName: 'Email' },
    { field: 'usertype', headerName: 'User Type' },
    { field: 'kyc', headerName: 'KYC Status', cellRenderer: ({ data }: any) => <div className=" h-full flex justify-center items-center"><TableNormalStatus status={data?.kyc?.status} /></div> },
    {
      field: 'action',
      headerName: 'Action',
      cellRenderer: ({ data }: any) => <TableAction handleMore={() => props.setUser(data)} />
    }
  ];

  const mobileConfig = {
    logo: {
      value: '',
      renderer: (data: any) => (
        <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="22.5" cy="22.5" r="22.5" fill="#0898A0" />
          <path d="M28.7537 26.6592V31.2662H33.3606L31.6223 29.5279L31.6271 29.5263C33.3 27.3848 34.2066 24.7443 34.2019 22.0269C34.1972 19.3095 33.2815 16.6721 31.6012 14.5365C29.9209 12.4009 27.573 10.8903 24.933 10.2464C22.293 9.60245 19.5133 9.8623 17.0384 10.9844L17.7888 12.4836C19.9076 11.5375 22.2806 11.3219 24.5352 11.8706C26.7898 12.4194 28.7983 13.7013 30.2454 15.5153C31.6925 17.3292 32.4961 19.5724 32.5301 21.8926C32.5642 24.2128 31.8266 26.4786 30.4334 28.3342L28.7537 26.6592ZM15.3491 17.4437V12.8367H10.7422L12.4789 14.5734H12.4741C10.8007 16.7149 9.89384 19.3557 9.89846 22.0735C9.90307 24.7912 10.8189 27.429 12.4996 29.5648C14.1802 31.7006 16.5285 33.2111 19.169 33.8548C21.8094 34.4984 24.5895 34.2381 27.0645 33.1153L26.3156 31.616C24.1971 32.5609 21.8248 32.7757 19.5711 32.2266C17.3173 31.6776 15.3096 30.3958 13.8631 28.5824C12.4165 26.7691 11.6129 24.5267 11.5785 22.2073C11.544 19.8879 12.2805 17.6227 13.6726 15.7671L15.3491 17.4437Z" fill="white" />
          <path d="M29.7008 22.499C29.701 23.4445 29.515 24.3808 29.1534 25.2544C28.7918 26.1281 28.2616 26.9219 27.5932 27.5907C26.9248 28.2594 26.1312 28.79 25.2578 29.152C24.3843 29.5141 23.4481 29.7005 22.5026 29.7008C21.5568 29.701 20.6202 29.5149 19.7463 29.1531C18.8724 28.7912 18.0784 28.2607 17.4096 27.592C16.7408 26.9232 16.2103 26.1291 15.8485 25.2553C15.4867 24.3814 15.3005 23.4448 15.3008 22.499C15.301 21.5534 15.4875 20.6172 15.8495 19.7438C16.2116 18.8703 16.7421 18.0767 17.4109 17.4083C18.0796 16.7399 18.8735 16.2098 19.7471 15.8482C20.6208 15.4865 21.5571 15.3005 22.5026 15.3008C24.4115 15.3013 26.2421 16.0598 27.5919 17.4096C28.9418 18.7594 29.7003 20.59 29.7008 22.499Z" fill="white" />
          <path d="M20.866 25V23.201H20.32V22.753H20.866V22.179H20.32V21.731H20.866V20.002H21.594L22.259 21.731H23.085V20.002H23.638V21.731H24.184V22.179H23.638V22.753H24.184V23.201H23.638V25H22.903L22.238 23.201H21.412V25H20.866ZM21.412 22.753H22.07L21.86 22.179H21.398L21.412 22.753ZM23.085 24.013H23.113L23.092 23.201H22.798L23.085 24.013ZM21.398 21.731H21.692L21.398 20.863H21.37L21.398 21.731ZM22.63 22.753H23.099L23.085 22.179H22.42L22.63 22.753Z" fill="#0898A0" />
        </svg>
      )
    },
    topLeft: {
      field: 'name',
    },
    bottomLeft: {
      field: 'email',
    },
    topRight: {
      field: "phone"
    },
    more: {
      clickHandler: (data: any) => props.setUser(data)
    }
  }

  const handlePagination = (payload) => {
    const page = payload.split('=')[1];
    props.getAllUsers(page);
  }

  return (
    <div className="w-full">
      <div className="w-full">
        <div className="min-h-[50vh]">
          {(!props.data || props.data?.data?.users.length === 0) ?
            (
              <NotFound
                text1="No Inquiry in the system yet!"
                text2='Manage all users sent in here.'
                btnLabel=""
                onClick={() => { }}
              />
            ) :
            (
              <div className="!h-[500px] overflow-auto w-full !px-8 !py-0 text-left">
                <Table
                  data={props.data?.data?.users || []}
                  header={header}
                  fitStrategy="fitCellContents"
                  mobileConfig={mobileConfig}
                />
              </div>
            )
          }
        </div>
        {
          props.data?.data?.users.length !== 0 && (
            <TablePaginationFooter
              total={props.data?.data?.pagination?.total}
              from={props.data?.data?.pagination?.from}
              to={props.data?.data?.pagination?.to}
              nextUrl={props.data?.data?.pagination?.next_page_url}
              prevUrl={props.data?.data?.pagination?.prev_page_url}
              handlePagination={handlePagination}
            />
          )
        }
      </div>
    </div>
  );
};

export default UsersKYCList;