import { useLocation } from 'react-router-dom';
import { WhatsappIcon } from 'react-share';
import { openWhatsapp } from '../App';

export const WhatsappIconExpandable = () => {
	const { pathname } = useLocation();

	const expandable =
		pathname.split('/')[1] === 'dashboard' ||
		pathname.split('/')[1] === 'business' ||
		pathname.split('/')[1] === 'merchant' ||
		pathname.split('/')[1] === 'sponsor' ||
		pathname.split('/')[1] === 'admin' ||
		pathname.split('/')[1] === 'voucher'
			? true
			: false;

	return (
		<div
			className={`flex flex-row justify-between items-center rounded-full fixed right-2 bottom-2 w-auto h-10 bg-[#25d366]  cursor-pointer z-[999999] animate-pulse 
       ${expandable && 'group hover:animate-expand'} `}
			onClick={openWhatsapp}>
			<WhatsappIcon
				className='rounded-full w-10 h-10 cursor-pointer'
				onClick={openWhatsapp}
			/>
			<p
				className={`text-sm bold text-white mr-3 
        ${
			expandable &&
			'hidden w-0 overflow-hidden opacity-0  group-hover:flex group-hover:visible group-hover:w-auto group-hover:animate-fadeIn'
		}  text-nowrap`}>
				Chat On WhatsApp
			</p>
		</div>
	);
};
