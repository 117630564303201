import { Outlet } from 'react-router-dom';
import "./dashboard.scss";
import { useEffect } from "react";
import DashboardSideBar from '../../components/DashboardSideBar';
import DashboardSVG from '../../components/SVG/Dashboard';
import useVerificationDone from '../../hooks/useVerificationDone';
import VoucherSVG from '../../components/SVG/Voucher';
import KycSVG from '../../components/SVG/KYCSvg';
import SettingsSVG from '../../components/SVG/Settings';

function SponsorDashboard() {
  const { isVerificationDone } = useVerificationDone();

  useEffect(() => {
    if (isVerificationDone) {
    }
  }, [isVerificationDone]);


  const navItems = [
    {
      name: "dashboard",
      path: `/sponsor/`,
      title: "Dashboard",
      Icon: DashboardSVG,
      isFirst: true
    },
    {
      name: "vouchers",
      path: `/sponsor/vouchers`,
      title: "Vouchers",
      Icon: VoucherSVG
    },
    {
      name: 'kyc',
      path: `/sponsor/kyc`,
      title: "KYC",
      Icon: KycSVG
    },
  ];

  const downNavItems = [
    {
      name: "settings",
      path: `/sponsor/settings`,
      title: "Settings",
      Icon: SettingsSVG,
    },
  ]

  return (
    <div className="voucherDashboard flex flex-row justify-between items-start !relative">
      <DashboardSideBar
        userType="Sponsor"
        firstItem="Overview"
        items={navItems}
        showVerificationStatus={false}
        showKYCVerificationStatus={false}
        voucherSideBar={true}
        downItemes={downNavItems}
      />
      <div className="outlet max-x-[100%] lg:max-w-[calc(100%-250px)] xl:max-w-[calc(100%-300px)]">
        <Outlet />
      </div>
    </div>
  );
}

export default SponsorDashboard;
