import Modal from '../../../../components/Modal'
import Input from '../../../../components/Input'
import Button from '../../../../components/Button'
import { FormEvent, useState } from 'react'

function FilterVouchersModal({ openModal, handleClose, handleFilter, resetAction }: {
  openModal: boolean, handleClose: (open: boolean) => void, handleFilter?: (start: string, end: string) => void,
  resetAction?: () => void
}) {
  const [data, setData] = useState({
    startDate: "",
    endDate: ""
  })

  const handleChange = (name, value) => {
    setData((prev) => {
      return {
        ...prev,
        [name]: value
      }
    })
  }

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    handleFilter && handleFilter(data.startDate, data.endDate)
    handleClose(false)
  }

  const handleReset = () => {
    setData({
      startDate: "",
      endDate: ""
    })
    handleClose(false)
    resetAction && resetAction()
  }
  return (
    <Modal
      onClose={() => handleClose(false)}
      open={openModal}
      title={''}
      overrideStyle={{
        height: 'auto',
        width: "95%",
        maxWidth: '500px',
        borderRadius: '30px',
        padding: '0',
        top: "50%",
      }}
      overrideChildrenStyle={{
        padding: '0',
        height: '100% !important'
      }}
    >
      <div className='flex flex-col justify-between items-center h-full !w-full'>
        <div className="border-b border-[#2222221A] w-full px-5 pt-6 pb-5 text-xl font-medium">Filter by</div>
        <div className="w-full p-5">
          <form className='w-full h-auto !p-0' onSubmit={handleSubmit}>
            <div className="w-full flex gap-2 !m-0">
              <Input
                value={data.startDate}
                type='date'
                name='startDate'
                label='Start Date'
                placeholder='Start Date'
                className='!m-0'
                inputClassName='!m-0'
                onChange={handleChange}
                required
              />
              <Input
                value={data.endDate}
                type='date'
                name='endDate'
                label='End Date'
                placeholder='End Date'
                className='!m-0'
                inputClassName='!m-0'
                onChange={handleChange}
                required
              />
            </div>
            <div className='flex flex-row justify-end items-center w-full px-2 gap-5 py-6'>
              <Button
                btnActionType='reset'
                type='contained'
                label='Reset'
                className='!rounded-[25px] !w-fit !m-0 1flex !justify-center !items-center'
                overrideStyle={{ padding: "10px 30px" }}
                onClick={() => handleReset()}
              />
              <Button
                btnActionType='submit'
                type='flat'
                label='Apply Filter'
                className='!rounded-[25px] !w-fit !m-0 1flex !justify-center !items-center'
                overrideStyle={{ padding: "10px 30px" }}
              />
            </div>
          </form>
        </div>
      </div>
    </Modal>
  )
}

export default FilterVouchersModal