import { useState } from 'react';
import CreateIcon from '../../assets/new/createIcon.png';
import CustomerList from '../../components/CustomerList';
import DashboardComponentWrapper from '../../components/DashboardComponentWrapper';
import SidePane from '../../components/SidePane';
import SwitchBusiness from '../../components/SwitchBusiness';
import CreateCustomer from '../../components/CustomerList/CreateCustomer';
import DashboardNavItem from '../../components/DashboardNavItem/DashboardNavItem';
import { ICustomer } from '../Business/businessSlice';
import { useAppDispatch } from '../../app/hooks';
import { useLocation } from 'react-router-dom';
import { getCustomers } from '../../actions/businessActions';

const Customers = () => {
	const [openCreate, setOpenCreate] = useState(false);
	const [toEdit, setToEdit] = useState<ICustomer | null>(null);
	const expenseNavItemsObj = [
		{
			label: 'New Customer',
			action: () => setOpenCreate(true),
			bgColor: '#0898A0',
			img: CreateIcon,
		},
	];
	const dispatch = useAppDispatch();

	const location = useLocation() as any;
	const businessCode = location.pathname.split('/')[3];

	const getCustomersAction = () => {
		getCustomers(dispatch, businessCode);
	};

	const renderNavItems = () => {
		return (
			<DashboardNavItem
				dontShowWithdraw
				navItems={[]}
				navItemsObj={expenseNavItemsObj}
				tab={''}
				setTab={() => { }}
			/>
		);
	};

	return (
		<DashboardComponentWrapper
			overrideName={() => <SwitchBusiness />}
			renderNavItems={renderNavItems}
			title='Customers'>
			<>
				<CustomerList
					getCustomersCall={getCustomersAction}
					handleCreate={setOpenCreate}
					setToEdit={setToEdit}
				/>
				<SidePane
					onClose={() => {
						setOpenCreate(false);
						setToEdit(null);
					}}
					open={openCreate || !!toEdit}
					title={''}>
					<CreateCustomer
						getCustomersCall={getCustomersAction}
						handleClose={() => {
							setOpenCreate(false);
							setToEdit(null);
						}}
						handleDone={() => {
							setOpenCreate(false);
							setToEdit(null);
						}}
						isOpen={true}
						editMode={!!toEdit}
						editData={toEdit}
					/>
				</SidePane>
			</>
		</DashboardComponentWrapper>
	);
};

export default Customers;
