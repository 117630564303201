import Button from '../../../components/Button'
import ExportIcon from "../voucher-assets/icons/export-file.svg"


function ExportBtn({ className }: { className?: string }) {
  return (
    <>
      <Button
        type='flat'
        label='Export'
        className={`${className} !flex !text-[13px] !rounded-full !m-0 !w-auto !py-5 !px-[25px]`}
        leftIcon={<img src={ExportIcon} />}
      />
      {/* <div className="flex md:hidden justify-center items-center !text-[#0898A0] !text-[13px] !bg-[#0898A01A] !rounded-lg !m-0 !w-auto !p-[15px]">
        <img src={ExportIcon} />
      </div> */}
    </>
  )
}

export default ExportBtn