import DashboardSideBar from '../../components/DashboardSideBar';
import BusinessIcon from '../../assets/business1.png';
import HomeSVG from '../../components/SVG/Home';
import BankSVG from '../../components/SVG/Bank';
import './business.scss';
import { Outlet } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { useEffect } from 'react';
import { getBusinesses, getNQRMerchants } from '../../actions/businessActions';
import useVerificationDone from '../../hooks/useVerificationDone';
import KycSVG from '../../components/SVG/KYCSvg';
import ProfileSVG from '../../components/SVG/Profile';

const Business = () => {
  const dispatch = useAppDispatch();
  const auth = useAppSelector((state) => state.auth);
  const business = useAppSelector((state) => state.business);
  const { isVerificationDone } = useVerificationDone();

  useEffect(() => {
    if (isVerificationDone) {
      !business.businesses && getBusinesses(dispatch, 1, auth?.user?.id);
      !business.nqrs && getNQRMerchants();
    }
  }, [auth?.user?.id, business.businesses, isVerificationDone]);

  const navItems = [
    {
      name: "businesses",
      path: `/business/`,
      title: "Businesses",
      img: BusinessIcon,
      Icon: HomeSVG,
      isFirst: true
    },
    {
      name: "bank",
      path: `/business/bank`,
      title: "Bank",
      img: '',
      Icon: BankSVG,
    },
    {
      name: "kyc",
      path: `/business/kyc`,
      title: "KYC",
      img: "",
      Icon: KycSVG
    },
    {
      name: "profile",
      path: `/business/profile`,
      title: "Profile",
      img: "",
      Icon: ProfileSVG
    },
  ];

  return (
    <div className="dashboard">
      <DashboardSideBar userType='Merchant' firstItem="Businesses" items={navItems} showVerificationStatus={false} showKYCVerificationStatus={true} />
      <div className="outlet">
        <Outlet />
      </div>
    </div>
  );
};

export default Business;
