import { useEffect, useState } from 'react'
import { useAppSelector } from '../app/hooks';
import useUserType from './useUserType';
import { useLocation } from 'react-router-dom';
import { getAbbr } from '../utils/common';

function useUserData() {
  const [userDetails, setUserDetails] = useState<Record<string, string | number> | null>(null)
  const profile = useAppSelector((state) => state.auth.user);
  const { isIndividual, isMerchant, isSponsor, userType } = useUserType()

  const { businesses } = useAppSelector((state) => state.business);
  const { pathname } = useLocation() as Record<string, any> || {};
  const business = businesses?.find((busi) => busi.business_code === pathname.split('/')[2]);

  useEffect(() => {
    if ((isIndividual || isSponsor) && profile) {
      setUserDetails({
        id: profile?.id,
        firstname: profile?.first_name,
        lastname: profile?.last_name,
        email: profile?.email,
      })
    }
    if (isMerchant && business) {
      setUserDetails({
        id: business?.id,
        name: business?.business_name,
        email: business?.business_email,
        business_code: business?.business_code
      })
    }
  }, [userType, business])

  const name = userDetails && isIndividual ? `${userDetails?.firstname} ${userDetails?.lastname}` : userDetails && isMerchant ? userDetails?.name : null

  return { abbr: getAbbr(name ? name as string : userDetails?.email ? userDetails?.email as string : ""), name, userDetails }
}

export default useUserData