export default function VoucherSVG({ color }: { color?: string }) {
  return (
    <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className="stroked" d="M11.5417 1.54187V3.55854" stroke={color ? color : "#222222"} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path className="stroked" d="M11.5417 12.8V14.4867" stroke={color ? color : "#222222"} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path className="stroked" d="M11.5417 9.93712V5.91962" stroke={color ? color : "#222222"} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path className="stroked" fill-rule="evenodd" clip-rule="evenodd" d="M15.5864 14.6666C17.1048 14.6666 18.3346 13.4521 18.3346 11.9526V9.79218C17.3299 9.79218 16.5207 8.99307 16.5207 8.00084C16.5207 7.00861 17.3299 6.20864 18.3346 6.20864L18.3338 4.04736C18.3338 2.54785 17.1031 1.33331 15.5855 1.33331H4.41707C2.89954 1.33331 1.66884 2.54785 1.66884 4.04736L1.66797 6.27902C2.6727 6.27902 3.48187 7.00861 3.48187 8.00084C3.48187 8.99307 2.6727 9.79218 1.66797 9.79218V11.9526C1.66797 13.4521 2.8978 14.6666 4.4162 14.6666H15.5864Z" stroke={color ? color : "#222222"} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

  )
}