import VoucherDashboardComponentWrapper from '../../voucher-layouts/VoucherDashboardComponentWrapper'
import VoucherTopComponents from '../../voucher-layouts/VoucherTopComponents'
import SearchVoucherInput from '../../voucher-components/SearchVoucherInput'
import FilterVouchersBtn from '../../voucher-components/FilterVouchersBtn'
import VoucherListComponent from '../../voucher-components/VoucherListComponent'
import useVerificationDone from '../../../../hooks/useVerificationDone'
import { useState } from 'react'
import FilterVouchersModal from '../../voucher-components/modals/FilterVouchersModal'
import RedeemVoucherModal from '../../voucher-components/modals/RedeemVoucherModal'
import Button from '../../../../components/Button'
import scanImg from "../../voucher-assets/icons/Scan.svg"
import { getAllVouchers, getFlteredVoucherByPeriod } from '../../../../actions/vouchersActions'
import { useAppSelector } from '../../../../app/hooks'
import { IVoucherList } from '../../voucherSlice'
import { useNavigate } from 'react-router-dom'
import useUserData from '../../../../hooks/useUserData'


function MerchantRedeemedVouchersPage() {
  const [openRedeemVoucherModal, setOpenRedeemVoucherModal] = useState(false)
  const [showFilterModal, setFilterModal] = useState(false)

  const { vouchers } = useAppSelector((state) => state.voucher)
  const { kycActionWrapper } = useVerificationDone()
  const { userDetails } = useUserData()

  const navigate = useNavigate()

  const handleFilter = (start, end) => {
    const reqBody = {
      start_date: start,
      end_date: end
    }
    getFlteredVoucherByPeriod(reqBody)
  }

  const resetFilterAction = () => {
    getAllVouchers({
      status: "approved",
      page: 1,
      isSilent: true,
    })
  }

  return (
    <VoucherDashboardComponentWrapper
      title="All Vouchers"
      subTitle=""
    >
      <>
        <div className="w-[95%] ">
          <VoucherTopComponents
            className="!p-0 !my-5"
            leftComponents={[
              <SearchVoucherInput />
            ]}
            rightComponents={[
              <FilterVouchersBtn onClick={() => kycActionWrapper(() => setFilterModal(true))} />,
              <Button
                label="Redeem Voucher"
                type="flat"
                className="!w-fit !rounded-full !p-[20px] !m-0"
                leftIcon={<img src={scanImg} alt="scan" />}
                onClick={() => kycActionWrapper(() => setOpenRedeemVoucherModal(true))}
              />
            ]}
          />
          <VoucherListComponent
            pagination={{ ...vouchers, data: null } as IVoucherList}
            vouchers={vouchers?.data}
            getAction={getAllVouchers}
            getActionPayload={{ status: "approved" }}
            notFoundText1={"You have not redeemed any voucher yet"}
            notToundBtnLabel={"Redeem Voucher"}
            notToundBtnAction={() => kycActionWrapper(() => setOpenRedeemVoucherModal(true))}
            voucherIdType='code'
            voucherClickAction={(voucherCode) => { navigate(`/merchant/${userDetails?.business_code}/vouchers/${voucherCode}`) }}
          />
        </div>
        <FilterVouchersModal
          openModal={showFilterModal}
          handleClose={() => setFilterModal(false)}
          handleFilter={handleFilter}
          resetAction={resetFilterAction}
        />
        <RedeemVoucherModal openModal={openRedeemVoucherModal} handleClose={() => setOpenRedeemVoucherModal(false)} />
      </>
    </VoucherDashboardComponentWrapper>
  )
}

export default MerchantRedeemedVouchersPage