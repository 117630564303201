import { Outlet, useParams } from "react-router-dom";
import "./MerchantDashboard.scss"
import DashboardSideBar from "../../../../../components/DashboardSideBar";
import DashboardSVG from "../../../../../components/SVG/Dashboard";
import ReportsSVG from "../../../../../components/SVG/ReportsSVG";
import SettingsSVG from "../../../../../components/SVG/Settings";
import TransactionsSVG from "../../../../../components/SVG/TransactionsSVG";
import VoucherSVG from "../../../../../components/SVG/Voucher";
import { useEffect } from "react";
import useVerificationDone from "../../../../../hooks/useVerificationDone";
import { useAppDispatch, useAppSelector } from "../../../../../app/hooks";
import { getBusinesses } from "../../../../../actions/businessActions";
import HomeSVG from "../../../../../components/SVG/Home";

export default function MerchantDashboard() {
  const { isVerificationDone } = useVerificationDone();
  const business = useAppSelector((state) => state.business);
  const auth = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const params = useParams();
  const businessId = params.id;


  useEffect(() => {
    if (isVerificationDone) {
      !business.businesses && getBusinesses(dispatch, 1, auth?.user?.id);
    }
  }, [auth?.user?.id, business.businesses, isVerificationDone]);

  const navItems = [
    {
      name: "business",
      path: `/business/dashboard/${businessId}/`,
      title: "Business Overview",
      Icon: HomeSVG,
    },
    {
      name: "dashboard",
      path: `/merchant/${businessId}`,
      title: "Voucher Dashboard",
      Icon: DashboardSVG,
      isFirst: true
    },
    {
      name: "vouchers",
      path: `/merchant/${businessId}/vouchers`,
      title: "All Vouchers",
      Icon: VoucherSVG,
    },
    // {
    //   name: "reports",
    //   path: `/merchant/${businessId}/reports/`,
    //   title: "Reports",
    //   Icon: ReportsSVG,
    // },

  ]
  // const downNavItems = [
  //   {
  //     name: "settings",
  //     path: `#`,
  //     title: "Settings",
  //     Icon: SettingsSVG,
  //   },
  // ]

  return (
    <div className="voucherDashboard flex flex-row justify-between items-start !relative">
      <DashboardSideBar
        userType="Merchant"
        items={navItems}
        firstItem={"Dashboard"}
        voucherSideBar={true}
        // downItemes={downNavItems}
        showBusinessSwitch={false}
        showVerificationStatus={false}
      />
      <div className="outlet flex flex-auto flex-grow">
        <Outlet />
      </div>
    </div>
  )
}