
export default function VoucherStatusTag({ status, type = "filled", withDot = true, statusText }: { status: "active" | "pending" | "revoked", type?: "filled" | "text", withDot?: boolean, statusText?: string }) {
  return (
    <span className={`!w-fit !h-[30px] flex gap-[5px] text-[12px] justify-center items-center px-[10px] rounded-md capitalize
      ${status === "active" ? "bg-[#00B05B0D] text-[#00B05B]" :
        status === "pending" ? "bg-[#FB7A0112] text-[#FB7A01]" :
          status === "revoked" ? "bg-[#FFF2F2] text-[#F55F64]"
            : null
      }
      ${type === "text" && "!bg-transparent !p-0"}
    `}>
      {
        withDot && (
          <span
            className={`w-[7px] h-[7px] rounded-full
      ${status === "active" ? "bg-[#00B05B]" :
                status === "pending" ? "bg-[#FB7A01]" :
                  status === "revoked" ? "bg-[#F55F64]"
                    : null
              }`}></span>
        )
      }

      {statusText ? statusText : status}
    </span>
  )
}
