import { useNavigate } from 'react-router-dom'
import dummyVoucherImg from "../voucher-assets/images/dummy-voucher.jpg"
import VoucherStatusTag from './VoucherStatusTag'
import Button from '../../../components/Button'
import { formatCurrency } from '../../../utils/common'
import { IVoucher } from '../voucherSlice'
import dayjs from 'dayjs'
import { isVoucherExpired } from '../../../utils/voucherUtilFuncs'


export default function VoucherCard({ voucherData, voucherClickAction, voucherId }: { voucherData: IVoucher, voucherClickAction?: (voucherId: string | number) => void, voucherId: string }) {
  return (
    <div className="w-[100%] flex flex-col shadow-sm rounded-[10px] bg-[#2222220D]">
      <div className="w-[100%] flex items-center py-[10px] px-[20px] gap-2 bg-[#0898A00D] rounded-t-[inherit] rounded-b-none">
        {/* <img src={dummyVoucherImg} alt="voucher" className="w-[40px] h-[40px] rounded-full object-cover object-center" /> */}
        <div className="flex flex-col gap-1 w-[full] text-left">
          <h4 className="text-[14px] font-[500]">{voucherData?.purpose}</h4>
          <div className="flex gap-1">
            <VoucherStatusTag status={isVoucherExpired(voucherData) ? "revoked" : "active"} type="filled" statusText={isVoucherExpired(voucherData) ? "voucher expired" : "voucher active"} />
            <VoucherStatusTag status={voucherData.voucher_status === "used" ? "revoked" : voucherData.voucher_status === "unused" ? "active" : "pending"} type="text" statusText={voucherData.voucher_status} withDot={false} />
          </div>
        </div>
      </div>
      <div className="w-[100%] py-[20px] px-[15px] flex flex-col gap-[20px]">
        <div className="w-[100%] flex justify-between">
          <div className="text-left">
            <h5 className="text-[12px] font-[400] text-[#222222CC] uppercase">Voucher amount</h5>
            <h5 className="text-[14px] font-[500]">{formatCurrency({ num: voucherData?.voucher_amount || 0 })}</h5>
          </div>
          <div className="text-right">
            <h5 className="text-[12px] font-[400] text-[#222222CC] uppercase">amount per code</h5>
            <h5 className="text-[14px] font-[500]">{formatCurrency({ num: voucherData?.amount_per_code || 0 })}</h5>
          </div>
        </div>

        <div className="w-[100%] flex justify-between">
          <div className="text-left">
            <h5 className="text-[12px] font-[400] text-[#222222CC] uppercase">EXPIRY DATE</h5>
            <h5 className="text-[14px] font-[500]">{dayjs(voucherData?.expiry_date).format("DD MMM, YYYY")}</h5>
          </div>
          <div className="text-right">
            <h5 className="text-[12px] font-[400] text-[#222222CC] uppercase">LOCATION</h5>
            <h5 className="text-[14px] font-[500]">{voucherData?.location}</h5>
          </div>
        </div>

        <Button type="contained" label="View Details" className="w-[100%] p-[10px] !m-0 !rounded-[20px]"
          onClick={() => voucherClickAction && voucherClickAction(voucherId)} />
      </div>
    </div>
  )
}
