import { useAppSelector } from "../../app/hooks";

const NotFound = ({
  text1,
  text2,
  btnLabel,
  onClick, 
  voucher
}: {
  text1: string,
  text2: string,
  btnLabel?: string,
  onClick?: () => void, 
  voucher?: boolean
}) => {
  const {user} = useAppSelector((state) => state.auth);
  const isAdmin = user?.usertype === 'admin';
  return (
    <div className='title not-found !py-32' style={{minWidth: '300px'}}>
      <svg xmlns="http://www.w3.org/2000/svg" width="78" height="65" viewBox="0 0 78 65" fill="none" className="mb-10">
        <path d="M23.9235 38.1528C24.2849 37.7871 24.2849 37.1776 23.9235 36.8119L21.0331 33.8863L24.7666 30.1074C25.1279 29.7417 25.1279 29.1322 24.7666 28.7665C24.4053 28.4008 23.8031 28.4008 23.4418 28.7665L19.7083 32.5454L16.336 29.1322C15.9747 28.7665 15.3725 28.7665 15.0112 29.1322C14.6499 29.4979 14.6499 30.1074 15.0112 30.4731L18.3835 33.8863L15.0112 37.2995C14.6499 37.6652 14.6499 38.2747 15.0112 38.6404C15.3725 39.0061 15.9747 39.0061 16.336 38.6404L19.7083 35.2272L22.5987 38.1528C23.0805 38.5185 23.5622 38.5185 23.9235 38.1528ZM23.5622 50.2209C23.2009 50.5866 23.2009 51.1961 23.6827 51.5618C24.044 51.9275 24.6462 51.9275 25.0075 51.4399C30.9089 44.8573 41.0256 44.9792 46.8066 51.0742C47.1679 51.4399 47.7701 51.4399 48.1314 51.0742C48.4927 50.7085 48.4927 50.099 48.1314 49.7333C41.5073 42.9069 30.1863 42.785 23.5622 50.2209Z" fill="#0898A0"/>
        <path d="M68.6055 57.537L71.7369 25.2335C71.8573 23.8926 71.3756 22.5517 70.5325 21.5765C69.6895 20.6013 68.3646 19.9918 67.0398 19.9918V11.5807C67.0398 8.41125 64.6311 5.85135 61.4997 5.72945L10.7958 4.99805C7.544 4.99805 5.13526 7.55795 5.01483 10.6055V12.7997C3.56959 13.1654 2.48565 14.1406 1.88347 15.6034C1.16085 16.4567 0.679099 17.5538 0.438225 18.4071C-0.163959 20.6013 -0.0435223 22.9174 0.197351 25.2335C1.16085 35.9607 2.12434 46.566 2.9674 57.2932C3.32871 61.9254 4.53308 63.8758 11.1571 64.2415C30.0657 65.2167 48.9743 65.0948 67.8829 64.7291C68.6055 64.7291 69.4486 64.7291 70.0508 64.3634C70.7734 63.9977 71.2551 63.2663 71.1347 62.5349C70.7734 60.9502 68.1238 61.194 66.5581 61.0721C67.642 60.3407 68.3646 59.1217 68.6055 57.537ZM10.7958 6.94845L61.4997 7.67985C63.5472 7.67985 65.3537 9.50835 65.2333 11.5807V20.1137H47.5291C46.2043 20.1137 44.8794 19.5042 43.916 18.529L41.1459 15.3596C39.7007 13.7749 37.6532 12.7997 35.4854 12.7997H6.82138V10.8493C6.82138 8.53315 8.62794 6.82655 10.7958 6.94845ZM63.9085 60.0969H9.59143C8.14619 60.0969 6.82138 58.9998 6.70094 57.4151L3.20827 17.6757C3.08784 15.9691 4.41264 14.5063 6.09876 14.5063H35.4854C37.1715 14.5063 38.7372 15.2377 39.8211 16.4567L42.5911 19.6261C43.7955 21.0889 45.6021 21.9422 47.5291 21.9422H67.0398C67.8829 21.9422 68.6055 22.3079 69.2077 22.9174C69.8099 23.5269 70.0508 24.3802 69.9303 25.1116L66.799 57.4151C66.6785 58.8779 65.3537 60.0969 63.9085 60.0969Z" fill="#0898A0"/>
        <path d="M51.2616 32.3035L53.9112 29.6217C54.2725 29.256 54.2725 28.6465 53.9112 28.2808C53.5499 27.9151 52.9477 27.9151 52.5864 28.2808L49.9368 30.9626L46.5645 27.5494C46.2032 27.1837 45.601 27.1837 45.2397 27.5494C44.8784 27.9151 44.8784 28.5246 45.2397 28.8903L48.612 32.3035L45.4806 35.4729C45.1193 35.8386 45.1193 36.4481 45.4806 36.8138C45.8419 37.1795 46.4441 37.1795 46.8054 36.8138L49.9368 33.6444L53.309 37.0576C53.6703 37.4233 54.2725 37.4233 54.6338 37.0576C54.9951 36.6919 54.9951 36.0824 54.6338 35.7167L51.2616 32.3035ZM67.641 0.3657C66.5571 1.8285 65.714 3.2913 65.1118 4.9979C64.9914 5.4855 65.2323 5.97311 65.714 6.09501C66.1958 6.21691 66.6775 5.97311 66.7979 5.48551C67.2797 4.0227 68.0023 2.5599 68.9658 1.3409C69.3271 0.9752 69.2067 0.3657 68.8454 0C68.4841 0 68.0023 -3.63291e-07 67.641 0.3657ZM75.349 3.9008C75.1081 3.5351 74.5059 3.2913 74.1446 3.657L68.4841 7.31401C68.1227 7.55781 68.0023 8.16731 68.2432 8.53301C68.4841 8.89871 69.0862 9.14251 69.4475 8.77681L75.1081 5.1198C75.4694 4.876 75.5898 4.2665 75.349 3.9008ZM77.0351 10.4834L70.6519 10.971C70.1702 10.971 69.8089 11.4586 69.8089 11.9462C69.8089 12.4338 70.2906 12.7995 70.7724 12.7995L77.1555 12.3119C77.6373 12.3119 77.9986 11.8243 77.9986 11.3367C77.8781 10.8491 77.5168 10.4834 77.0351 10.4834Z" fill="#0898A0"/>
      </svg>
      {
        !isAdmin ? (
          <>
            {text1}
            <p>{text2}</p>
            {
              btnLabel && onClick && (
                <button onClick={onClick} className={`text-white bg-az-teal px-5 py-1 h-10 mb-10 ${voucher && "!rounded-full"}`}>
                  {btnLabel}
                </button>
              )
            }
          </>
        ) : (
          <>
            <p>No Data</p>
          </>
        )
      }
    </div>
  )
};

export default NotFound;
