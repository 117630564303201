import VoucherDashboardComponentWrapper from "../Voucher/voucher-layouts/VoucherDashboardComponentWrapper";
import VoucherTopComponents from "../Voucher/voucher-layouts/VoucherTopComponents";
import Button from "../../components/Button";
import FilterVouchersBtn from "../Voucher/voucher-components/FilterVouchersBtn";
import SearchVoucherInput from "../Voucher/voucher-components/SearchVoucherInput";
import SidePane from "../../components/SidePane";
import CreateVoucher from "./Components/CreateVoucher";
import { useState } from "react";
import { useAppSelector } from "../../app/hooks";
import VoucherListComponent from "../Voucher/voucher-components/VoucherListComponent";
import { getAllVouchers, getAllVouchersBySponsor, getDateFilterVouchersBySponsor } from "../../actions/vouchersActions";
import { IVoucherList } from "../Voucher/voucherSlice";
import FilterVouchersModal from "../Voucher/voucher-components/modals/FilterVouchersModal";
import useVerificationDone from "../../hooks/useVerificationDone";
import { useNavigate } from "react-router-dom";

const SponsorVouchers = () => {
  const [createVoucherOpen, setCreatVoucherOpen] = useState(false)
  const [showFilterModal, setFilterModal] = useState(false)
  const { vouchers } = useAppSelector((state) => state.voucher)
  const { kycActionWrapper } = useVerificationDone()

  const navigate = useNavigate()

  const handleFilter = (startDate: string, endDate: string) => {
    getDateFilterVouchersBySponsor({
      startDate, endDate, isSilent: false,
      handleDone: (resp) => {
        setFilterModal(false)
      },
    })
  }

  const resetFilterAction = () => {
    getAllVouchersBySponsor({ page: 1, isSilent: true })
  }

  return (
    <VoucherDashboardComponentWrapper
      title="Vouchers"
      subTitle=""
    >
      <>
        <div className="w-[95%] ">
          <VoucherTopComponents
            className="!p-0 !my-5"
            leftComponents={[
              <SearchVoucherInput />
            ]}
            rightComponents={[
              <FilterVouchersBtn onClick={() => kycActionWrapper(() => setFilterModal(true))} />,
              <Button
                onClick={() => {
                  kycActionWrapper(() => setCreatVoucherOpen(true))
                }}
                label="+ Create Voucher"
                type='flat'
                className="!rounded-full !m-0 !w-fit !p-5 !py-5"
              />
            ]}
          />
          <VoucherListComponent
            // @ts-ignore
            vouchers={vouchers?.data ? vouchers?.data : vouchers}
            pagination={{ ...vouchers, data: null } as IVoucherList}
            getAction={getAllVouchers}
            getActionPayload={{ status: "approved" }}
            notFoundText1={"No Voucher Recorded"}
            notToundBtnLabel={"+ Create Voucher"}
            notToundBtnAction={() => { setCreatVoucherOpen(true) }}
            voucherClickAction={(voucherId) => { navigate(`/sponsor/vouchers/${voucherId}`) }}
          />
        </div>
        <SidePane open={createVoucherOpen} title="" onClose={() => kycActionWrapper(() => setCreatVoucherOpen(false))}>
          <CreateVoucher
            handleClose={() => setCreatVoucherOpen(false)}
            successEffectAction={() => {
              getAllVouchers({ isSilent: true, status: "approved" });
            }} />
        </SidePane>
        <FilterVouchersModal openModal={showFilterModal} handleClose={() => setFilterModal(false)} handleFilter={handleFilter} resetAction={resetFilterAction} />
      </>
    </VoucherDashboardComponentWrapper>
  )
};

export default SponsorVouchers;
